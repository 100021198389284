import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { catchError, map, Subscription, throwError } from 'rxjs';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { CertificationManagement } from 'src/app/shared/enum/certificationManagement';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';

enum certificationListStatus {
  systemCheckSuccess = 'System Check Success',
  certified = 'Certified',
  systemCheckFailed = 'System Check Failed',
  reviewRequested = 'Review Requested',
  rejected = 'Rejected',
  reviewInProgress = 'Review In-Progress',
  certificationRequested = 'Certification Requested'
}

@Component({
  selector: 'app-certification-details',
  templateUrl: './certification-details.component.html',
  styleUrls: ['./certification-details.component.scss']
})
export class CertificationDetailsComponent implements OnInit, OnDestroy {

  @Input() extDetailsData: any;
  @Output() certificationDetails: EventEmitter<any> = new EventEmitter();
  certificationDetailsData: any;
  submitForSolutionCertificationSubscription!: Subscription;
  certificationsServiceSubscription!: Subscription;
  drawerSubscription!: Subscription;
  downloadFilesActive: Boolean = false;
  intiateCertificationActive: Boolean = false;
  certDetails: any;
  isInitiateAutomaticCertificationPermission: any;
  isInitiateManualCertification: any;
  isExceptionalApproval: any;
  previousPackage:any;
  linkageAndQueueForUpgradeFeature: any = false;
  // Make a variable reference to certificationListStatus Enum
  certificationStatus = certificationListStatus;

  certificationEnum= CertificationManagement;
  isInitiateReview: any;
  isRequestorWithdraw: boolean | undefined;
  hasGlobalDataAccess: any;
  canApproveCertification:any;
  canViewCertification:any;
  constructor(
    private certificationsService: CertificationsService,
    private notificationService: NotificationService,
    private blobService: AzureBlobStorageService,
    private permissionService: PermissionsService,
    private extensionservice: ExtensionService,
    private dialogService: DialogService
    ) { }

  ngOnInit(): void {
    let featuresTobeDisabled: any = localStorage.getItem('featuresTobeDisabled');
    this.linkageAndQueueForUpgradeFeature = featuresTobeDisabled?.includes('TenantUpgradeDisableLinkage')? false: true;
    this.isInitiateReview = this.permissionService.getPermission("InitiateReview");
    this.isInitiateAutomaticCertificationPermission = this.permissionService.getPermission("InitiateAutomaticCertification");
    this.isInitiateManualCertification = this.permissionService.getPermission("InitiateManualCertification");
    this.isExceptionalApproval = this.permissionService.getPermission("InitiateManualCertification-ExceptionApproval");
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.canApproveCertification = this.permissionService.getPermission('ApproveCertification');
    this.canViewCertification = this.permissionService.getPermission('ViewCertification');
    this.certificationsServiceSubscription = this.certificationsService.onCertificationDetails<any>().subscribe(
      data => {
        if (data != null && data != "") {
          this.certificationsService.getCertificationDetails(data.id).subscribe(response => {
            if(localStorage.getItem('email') == response.requesterEmail){
              this.isRequestorWithdraw = true;
            }
            else{
              this.isRequestorWithdraw = false;
            }
            this.certificationDetailsData = response;
            if(this.linkageAndQueueForUpgradeFeature){
              this.linkedPackage(this.certificationDetailsData?.solutionId)
            }
          })
        }
      }
    )
    this.drawerSubscription = this.certificationsService.intiateCertificationToggleSubject.subscribe(data => {
      if(data) {
        this.downloadFilesActive = false;
        this.intiateCertificationActive = false;
      }
    });
  }

  // Initiate Manual Certification
  initiateManualCertification(element: any) {
    this.certDetails = element;
    if(element.status == "System Check Success") {
      this.certificationsService.initiateManualCertification(element).pipe(
          catchError(err => {
            if(err.status != 200) {
              this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationFailed_body_p1+element.packageName+this.certificationEnum.certification_notify_manualCertificationFailed_body_p2, 'error', this.certificationEnum.certification_notify_manualCertificationFailed_heading);
            }
            return throwError(err);
          })
      ).subscribe(response => {
        this.notificationService.showNotification(this.certificationEnum.certification_notify_manualCertificationRequested_body_p1+element.packageName+this.certificationEnum.certification_notify_manualCertificationRequested_body_p2, 'success', this.certificationEnum.certification_notify_manualCertificationRequested_heading);
        this.certificationsService.certificationList("redirectToCertificationListFromDetails");
        this.intiateCertificationActive = false;
      });
    } else if (element.status == "System Check Failed") {
      this.intiateCertificationActive = true;
    }
  }

  // Cancel Button
  onCancel() {
    this.certificationDetails.emit(false);
  }

  downloadCertificationFiles(element: any) {
    if(!this.downloadFilesActive) {
      this.downloadFilesActive = true;
    }
  }

   // Download Package Download
   downloadSingleFile(fileName: any, uniqueIdentifier: any, moduleName?: any, parentPackage?:boolean) {
    try {
      var FileSaver = require('file-saver');
      this.certificationsService
        .certificationFileDownload(parentPackage ? this.previousPackage.id : this.certificationDetailsData, fileName, moduleName, parentPackage)
        .pipe(
          map((data: any) => {
            this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
              if (response._response != undefined && response._response.status == "200") {
                response.blobBody.then(
                  (onres: any) => {
                    FileSaver.saveAs(onres, fileName);
                    if(moduleName == 'veracodeReportFile'){
                      this.notificationService.showNotification(this.certificationEnum.certification_details_veracodeDownload_body, 'success', this.certificationEnum.certification_details_veracodeDownload_heading);
                    }
                    else{
                      this.notificationService.showNotification(this.certificationEnum.certification_notify_packageDownload_body, 'success', this.certificationEnum.certification_notify_packageDownload_heading);
                    }
                   
                  })
              } else if(moduleName != 'veracodeReportFile' ){
                this.notificationService.showNotification(this.certificationEnum.certification_notify_packageFailedDownload_body, 'error',this.certificationEnum.certification_notify_packageFailedDownload_heading);
              }
              else{
                this.notificationService.showNotification(this.certificationEnum.certification_details_veracodeDownload_failed_body, 'error',this.certificationEnum.certification_details_veracodeDownload_failed_heading);
              }
            });
          }), catchError((err) => {
            console.log(err);
            return err;
          })
        ).subscribe();
    } catch (err) {
      console.log(err);
    }
  }

  ngOnDestroy() {
    if(this.submitForSolutionCertificationSubscription) {
      this.submitForSolutionCertificationSubscription.unsubscribe();
    }
    if(this.certificationsServiceSubscription) {
      this.certificationsServiceSubscription.unsubscribe();
    }
    if(this.drawerSubscription) {
      this.drawerSubscription.unsubscribe();
    }
  }

  withdrawRequest(element: any){
    try {
    this.dialogService
        .confirmDialogComments({
          title: "Confirm Request Withdrawal",
          module: 'withdraw-certification',
          message: 'To withdraw your certification request for package <b>'+element.packageName+'</b>, click <b>Confirm</b>',
          confirmText: 'Confirm',
          cancelText: 'Cancel',
        })
        .subscribe((res: any) => {
            if(res){

              let JSON = {
                id: element.id,
                requesterNote: res,
              };
              this.certificationsService.WithdrawManualCertification(JSON).subscribe((resp: any) => {                                 
                  this.notificationService.showNotification(
                    'Your certification request is withdrawn. Request status is changed to System Check Success.',
                    'success',
                    'Success'
                  );
                  this.ngOnInit();               
              });
            }
          });
        } catch (e) {
          console.log(e);
        }
  }

  linkedPackage(id:any){
    try{
      this.extensionservice.getSolutionPackageHierarchyById(id).subscribe((resp:any)=>{
       if(resp?.parentSolutionPackage){
          this.previousPackage = resp.parentSolutionPackage;
       }
      });      
    } catch(error){
      console.log(error)
    }
    
  }
  resubmitForVulnerabilityScan(id:any){
    try{
      this.certificationsService.ReTriggerVeracodeScan(id).subscribe((res:any)=>{
          this.notificationService.showNotification(
            this.certificationEnum. certification_details_veracodeResubmit_success_header,
            'success',
            this.certificationEnum.certification_details_veracodeResubmit_success_body
          );
          this.certificationsService.getCertificationDetails(this.certificationDetailsData?.id).subscribe((response:any)=>{
            this.certificationDetailsData = response;
          });
      });
        
    }catch(exception:any){
      this.notificationService.showNotification(
        exception,
        'error',
        this.certificationEnum.certification_details_veracodeResubmit_error_body
      );
    } 
  }

  refreshStatus(){
    this.certificationsService.getCertificationDetails(this.certificationDetailsData?.id).subscribe((response:any)=>{
      this.certificationDetailsData = response;
    });
  }
}
