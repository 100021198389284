
    <mat-card class="idn-acc-list-card" (click)="filterCustomer(custData?.title)">
        <mat-card-content id="{{custData?.id}}">
          <div class="card-content">
            <div class="total-customers" >{{custData?.title}}</div>   
            <div class="num-customers">{{custData?.count}}</div> 
          </div>
          <div class="card-icon">
            <mat-icon>{{custData?.icon}}</mat-icon> 
          </div>
        </mat-card-content>
    </mat-card>

