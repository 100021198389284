import { DatePipe, formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { lastValueFrom } from 'rxjs';
import { EnvService } from 'src/app/core/services/env.service';
import { FormsModule } from '@angular/forms';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { MessageEnum } from 'src/app/shared/enum/MessageEnum';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { UserService } from 'src/app/core/services/user-management/user.service';

const userCategoryID: { [key: string]: number } = {
  Icertis: 1,
  Partner: 2,
  Customer: 3
};

@Component({
  selector: 'app-create-instance',
  templateUrl: './create-instance.component.html',
  styleUrls: ['./create-instance.component.scss'],
})
export class CreateInstanceComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  customers: any = [];
  partners:any = [];
  columnFilterCtrlCo = new FormControl(''); 
  columnFilterCtrlPartner = new FormControl(''); 
  instanceType: any;
  typeOfInstance: any;
  deploymentRegions: any;
  @ViewChild('customername')
  mySelect!: MatSelect;
  fileAttr = 'Choose File';
  listofUploadedFiles: any[] = [];
  fileArray: any[] = [];
  file: any;
  fileSizeLimit = this.env.fileSizeLimitInstance;
  eventObject: any = {};
  sas: any;
  uniqueIdentifier: any;
  IsExtenTypeSelected: boolean = false;
  azUrl: any;
  today: any = new Date();
  minDate: any = new Date();
  todaysDate: any;
  extensionArray: any = ['pdf', 'doc', 'docx', 'txt', 'xlsx', 'xls'];
  cnameSuffix = '.icertis.com';
  submitInstanceForApproval: any = false;
  breadCrumbItems: any = [
    { label: 'Home', path: 'resources' },
    { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
    { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
    { label: 'Create', path: 'create-instance' },
  ];
  CustomerDisplayList: any[] = [];
  currentInstance: any;
  dateInput: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd HH:mm');
  todayDate: Date = new Date(this.dateInput);
  inputCnamevalue = '';
  globalRegions: any = [];
  partnerId: any;
  partnerAccess: any;
  users: any[] = [];
  isSubmitInstance: boolean = false;
  public regex = InputPattern;
  regexCname: any = new RegExp(this.regex.cnamePattern);
  firstCharSmallErrorMessage:any = this.regex.firstCharSmallErrorMessage;
  errorDescriptionCname:any;
  public StateEnum = MessageEnum;
  public instanceEnum = InstanceManagement;
  userCategory:any;
  customerUserCustomerId:number=0;
  instanceVersions:any =
  [{name: this.instanceEnum.instance_view_approval_current_podVersion, value: this.instanceEnum.instance_approval_current_podVersion},
   {name: this.instanceEnum.instance_view_approval_previous_podVersion,value: this.instanceEnum.instance_approval_previous_podVersion}];

  frmCreateInstance: FormGroup = new FormGroup({
    typeOfInstanceId: new FormControl('', Validators.required),
    instanceTypeId: new FormControl('', Validators.required),
    customerId: new FormControl('', Validators.required),
    cname: new FormControl('', [
      Validators.required,
      Validators.pattern(this.regexCname),
    ]),
    userId: new FormControl('', Validators.required),
    deploymentRegionId: new FormControl([], Validators.required),
    requiredBy: new FormControl('', Validators.required),
    InstanceVersion:new FormControl('', Validators.required),
    uniqueIdentifier: new FormControl(null),
    files: new FormControl([]),
    partnerId: new FormControl([]),
  });
  PartnerDisplayList: any;
  columnFilterRegion = new FormControl('');
  selectRegion: any = [];
  constructor(
    private instanceService: InstanceService,
    private userService: UserService,
    private notificationService: NotificationService,
    private router: Router,
    private datePipe: DatePipe,
    private extensionService: ExtensionService,
    private sdkService: SdkServiceService,
    private blobService: AzureBlobStorageService,
    private env: EnvService,
    private permissionService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.minDate.setDate(this.today.getDate());
    this.partnerAccess = this.permissionService.hasGlobalDataAccess();

   this.userCategory=this.permissionService.getUsercategoryId();
   
    this.partnerId = this.permissionService.getPartnerDetails().id;
    this.isSubmitInstance =
      this.permissionService.getPermission('SubmitInstance');

    this.getReferenceData();
    this.columnFilterCtrlCo.valueChanges.subscribe((value) => {
      this.filterColumnControl(value);
    });
    this.columnFilterCtrlPartner.valueChanges.subscribe((value) => {
      this.filterColumnControlForPartner(value);
    });
    this.columnFilterRegion.valueChanges.subscribe((value:any) => {
      this.columnFilterCtrlRegion(value);
    });
  this.GetCustomersWithoutPartner();
    
  }
  columnFilterCtrlRegion(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.selectRegion = this.deploymentRegions?.filter((x: any) =>
      x.displayName.toLowerCase().includes(valueLowerCase),
    );
  }
  valueChange(event: any) {
   if (event != undefined && event != null && !this.regexCname.test(event[0])) {
      this.errorDescriptionCname = this.firstCharSmallErrorMessage;
    }
    else {
      this.errorDescriptionCname = this.instanceEnum.instance_error_message_allowed_character;
    }
  }

  filterColumnControl(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.customers = this.CustomerDisplayList.filter((x: any) =>
      x.customerName.toLowerCase().includes(valueLowerCase)
    );
  }

  filterColumnControlForPartner(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.partners = this.PartnerDisplayList.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase)
    );
  }

  GetCustomersWithoutPartner(){    
    try {
      this.userService
        .GetCustomerListBasedOnPartner()
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            this.customers = resp.body;
            this.CustomerDisplayList = this.customers;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetCustomersByPartnerId(partnerId: number){
    this.partnerId=partnerId;
    try {
      this.instanceService
        .GetCustomersByPartnerId(partnerId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            this.customers = resp.body;
            this.CustomerDisplayList = this.customers;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetInstanceTypeByCustomerId(customerId: number) {
    try {
      this.instanceService
        .GetInstanceTypeByCustomerId(customerId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            this.instanceType = resp?.body;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getUsersByCustomerId(customerId: any) {
    try {
      this.instanceService
        .GetUsersByCustomerId(customerId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            this.users = resp.body;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  //get region list
  getReferenceData() {
    let inputParam = 'Create';
    try {
      this.instanceService.getReferenceData(inputParam).subscribe((resp: any) => {
        if (resp != null) {         
          this.typeOfInstance = resp.typeOfInstances;          
          this.frmCreateInstance.controls.typeOfInstanceId.setValue(1);
          this.frmCreateInstance.controls.typeOfInstanceId.disable();
          if(this.userCategory ==userCategoryID.Customer){
            this.customers =resp.customers[0];
            this.customerUserCustomerId=this.customers.id 
            this.frmCreateInstance.controls.customerId.setValue(this.customers.id);
            this.frmCreateInstance.controls.customerId.disable();
            this.GetInstanceTypeByCustomerId(this.customerUserCustomerId);
            this.getUsersByCustomerId(this.customerUserCustomerId);

          }
          this.deploymentRegions = resp.deploymentRegions;
          this.globalRegions = resp.deploymentRegions;
          this.selectRegion = this.deploymentRegions;
          if(this.partnerAccess){
            this.partners = resp.partners;
            this.PartnerDisplayList = this.partners;
          }else{
            this.customers = resp.customers;
            this.CustomerDisplayList = this.customers;
          }
          
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  getSelectedType(event: any) {
    this.deploymentRegions = [];
    if (event.value == 6) {
      this.deploymentRegions = this.globalRegions.filter((obj: any) => {
        return obj.name == 'Central US'        
      });
      this.selectRegion = this.deploymentRegions;
    } else {
      this.deploymentRegions = this.globalRegions;
      this.selectRegion = this.deploymentRegions;
    }
  }

  //File upload event
  uploadFileEvt(File: any) {
    let fileExtension = File.target.files[0].name.split('.').pop();
    if (this.extensionArray.indexOf(fileExtension) <= -1) {
      this.notificationService.showNotification(
        this.instanceEnum.instance_notify_inValidFileType_body,
        'warning',
        this.instanceEnum.instance_notify_inValidFileType_heading
      );
      return;
    }
    if (File.target.files && File.target.files[0]) {
      this.file = File.target.files[0];
      var filesize = Number((this.file.size / 1024 / 1024).toFixed(4));
      if (this.file.size == 0) {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_emptyFile_body,
          'error',
          this.instanceEnum.instance_notify_emptyFile_heading
        );
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return;
      }
      if (filesize > this.fileSizeLimit) {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_fileSizeExceed_body,
          'error',
          this.instanceEnum.instance_notify_fileSizeExceed_heading
        );
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return;
      }
      this.fileAttr = '';
      this.fileAttr = File.target.files[0].name;
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  //To remove added file tags.
  removeFileTag(item: any) {
    const index = this.listofUploadedFiles.indexOf(item);
    if (index >= 0) {
      this.listofUploadedFiles.splice(index, 1);
      this.fileArray.splice(index, 1);
    }
  }

  //To add file on click plus sign.
  fileAdd() {
    if (
      this.fileAttr != null &&
      this.fileAttr != '' &&
      this.fileAttr != undefined &&
      this.fileAttr != 'Choose File'
    ) {
      let isDuplicate = this.checkDuplicateFile(this.fileAttr);
      if (isDuplicate) {
        this.listofUploadedFiles.push(this.fileAttr);
        this.fileArray.push({
          fileContent: this.file,
          fileName: this.file.name,
          isUploaded: false,
        });
        this.fileAttr = 'Choose File';
        this.file = '';
      } else {
        this.fileAttr = 'Choose File';
      }
    } else if (this.fileAttr == 'Choose File') {
      this.notificationService.showNotification(
        this.instanceEnum.instance_notify_noFileChoosen_body,
        'warning',
        this.instanceEnum.instance_notify_noFileChoosen_heading
      );
    }
  }

  resetFormFields(){
    if(this.frmCreateInstance){
      this.users=[];
      this.instanceType=[];
      this.frmCreateInstance.get("userId")?.reset()
      this.frmCreateInstance.get("instanceTypeId")?.reset()
    }
  }

  //To check duplicate file.
  checkDuplicateFile(filename: string): boolean {
    if (this.listofUploadedFiles.includes(filename)) {
      this.notificationService.showNotification(
        this.instanceEnum.instance_notify_fileAlreadyAdded_body,
        'warning',
        this.instanceEnum.instance_notify_duplicateFile_heading
      );
      return false;
    } else {
      return true;
    }
  }

  saveDraft() {
    this.submitInstanceForApproval = false;
    this.Save();
  }

  submitForApproval() {
    this.submitInstanceForApproval = true;
    this.Save();
  }

  // To Create and Save Instance
  async Save() {
    try {
      if (!this.frmCreateInstance.valid) {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_instanceNotCreated_body,
          'warning',
          this.instanceEnum.instance_notify_instanceRequestNotCreated_heading
        );
        return;
      }
      let JSON = this.frmCreateInstance.getRawValue();
      JSON.requiredBy = this.datePipe.transform(
        JSON.requiredBy,
        'yyyy-MM-ddTHH:mm:ss'
      );
      JSON.cname =
        this.frmCreateInstance.controls['cname'].value + this.cnameSuffix;
      JSON.partnerId = this.partnerId;
      JSON.statusId = 0;
      JSON.iciVersionId = null;
      JSON.InstanceDefaultUser =
        this.frmCreateInstance.controls['userId'].value;
      let isexist = await this.checkIfInstanceExsists(JSON.cname);
      if (isexist != undefined && !isexist) {
        this.eventObject['showProgressBar'] = 'true';
        this.eventObject['cname'] =
          this.frmCreateInstance.controls['cname'].value + this.cnameSuffix;
        this.sdkService.emit<any>(this.eventObject);
        this.instanceService
          .postDataUrl('api/Instance/CreateInstanceRequest', {}, JSON)
          .subscribe(
            async (result: any) => {
              if (result.status == 200) {
                let insId = result.body;
                if (insId == 0) {
                  this.notificationService.showNotification(
                    this.instanceEnum
                      .instance_notify_instanceRequestNotCreated_body,
                    'error',
                    this.instanceEnum
                      .instance_notify_instanceRequestNotCreated_heading
                  );
                  return;
                }
                this.router.navigate(['home/instanceoperations/instance-requests']);
                this.instanceService
                  .getInstanceByID(insId)
                  .subscribe((res: any) => {
                    this.currentInstance = res.body;
                  });
                if (this.listofUploadedFiles.length > 0) {
                  let resToken = await this.setToken(insId);
                  if (resToken != null && resToken != undefined) {
                    this.sas = resToken.sasUrl;
                    this.uniqueIdentifier = resToken.container;
                    this.frmCreateInstance.controls[
                      'uniqueIdentifier'
                    ].setValue(this.uniqueIdentifier);
                  }

                  if (this.fileArray.length > 0) {
                    await this.uploadFileAsynchronously().then(
                      async (result: any) => {
                        if (result == 'success') {
                          let x = await this.updateFileName(
                            this.fileArray,
                            insId
                          );
                          if (x.status == 200) {
                            if (x) {
                              if (this.submitInstanceForApproval) {
                                this.instanceService
                                  .submitForApproval(insId)
                                  .subscribe((resp: any) => {
                                    if (resp.status == 200) {
                                      this.notificationService.showNotification(
                                        this.instanceEnum
                                          .instance_notify_instanceRequestSubmitted_body,
                                        'success',
                                        this.instanceEnum
                                          .instance_notify_instanceRequestSubmitted_heading
                                      );
                                      this.sdkService.emit<any>(
                                        this.eventObject
                                      );
                                    }
                                  });
                              } else {
                                this.notificationService.showNotification(
                                  this.instanceEnum
                                    .instance_notify_draftCreated_body,
                                  'success',
                                  this.instanceEnum
                                    .instance_notify_draftCreated_heading
                                );
                              }
                            }
                          }
                        } else {
                          this.eventObject['showProgressBar'] = 'false';
                          this.sdkService.emit<any>(this.eventObject);
                          this.notificationService.showNotification(
                            this.instanceEnum.instance_notify_uploadFailed_body,
                            'error',
                            this.instanceEnum
                              .instance_notify_uploadFailed_heading
                          );
                        }
                      }
                    );
                  }
                } else {
                  if (this.submitInstanceForApproval) {
                    this.instanceService
                      .submitForApproval(insId)
                      .subscribe((resp: any) => {
                        if (resp.status == 200) {
                          this.eventObject['showProgressBar'] = 'false';
                          this.sdkService.emit<any>(this.eventObject);
                          this.notificationService.showNotification(
                            this.instanceEnum
                              .instance_notify_instanceRequestSubmitted_body,
                            'success',
                            this.instanceEnum
                              .instance_notify_instanceRequestSubmitted_heading
                          );
                        }
                      });
                  } else {
                    this.notificationService.showNotification(
                      this.instanceEnum
                        .instance_notify_instanceReqstInDraft_body,
                      'success',
                      this.instanceEnum
                        .instance_notify_instanceReqstInDraft_heading
                    );
                  }
                  this.eventObject['showProgressBar'] = 'false';
                }
                this.frmCreateInstance.reset();
                this.listofUploadedFiles = [];
                this.IsExtenTypeSelected = false;
              } else {
                this.notificationService.showNotification(
                  this.instanceEnum.instance_notify_instanceNotCreated_body,
                  'warning',
                  this.instanceEnum.instance_notify_instanceNotCreated_heading
                );
              }
            },
            (error: HttpErrorResponse) => {
              let message: any = error;
              this.notificationService.showNotification(
                message,
                'warning',
                this.instanceEnum.instance_notify_instanceNotCreated_heading
              );
            }
          );
      } else {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_cNameExist_body,
          'error',
          this.instanceEnum.instance_notify_cNameExist_heading
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  async checkIfInstanceExsists(cname: any) {
    return await lastValueFrom(
      this.instanceService.checkDuplicateInstance(cname)
    );
  }

  //To Set SAS Token
  async setToken(insId: any): Promise<any> {
    try {
      return this.instanceService.getBlobUploadConnection(insId).toPromise();
    } catch (execption) {
      console.log(execption);
    }
  }

  // To Upload Multiple Files Async
  uploadFileAsynchronously() {
    return new Promise((resolve) => {
      try {
        let counter = 0;
        this.fileArray.forEach((x: any) => {
          this.blobService.uploadFiles(
            this.sas,
            this.uniqueIdentifier,
            x.fileContent,
            x.fileName,
            (response: any) => {
              counter += 1;
              if (response._response != undefined) {
                this.azUrl = response._response.request.url;
                x['azUrl'] = this.azUrl;
                x.isUploaded = true;
              } else {
                resolve('Fail');
              }
              if (counter == this.fileArray.length) {
                this.eventObject['showProgressBar'] = 'false';
                this.extensionService.emit<any>(this.eventObject);
                resolve('success');
              }
            }
          );
        });
      } catch (execption) {
        console.log(execption);
      }
    });
  }

  //To Update File name which is successfully uploaded
  async updateFileName(array: any[], extId: any): Promise<any> {
    try {
      let fileNames: any[] = [];
      array.filter((x) => {
        if (x.isUploaded == true) {
          fileNames.push(x.fileName);
        }
      });

      this.frmCreateInstance.patchValue({
        files: fileNames,
        uniqueIdentifier: this.uniqueIdentifier,
      });
      let JSON = this.frmCreateInstance.getRawValue();
      JSON.requiredBy = this.datePipe.transform(
        JSON.requiredBy,
        'yyyy-MM-ddTHH:mm:ss'
      );
      JSON.cname =
        this.frmCreateInstance.controls['cname'].value + this.cnameSuffix;
      JSON.partnerId = this.partnerId;
      JSON.status = 'success';
      JSON.statusId = 0;
      JSON.customerName = 'string';
      JSON.id = extId;
      JSON.iciVersionId = null;
      JSON.timestamp = this.currentInstance.timestamp;
      return await this.instanceService
        .putDataUrl('api/Instance/UpdateInstanceRequest', {}, JSON)
        .toPromise();
    } catch (exception) {
      console.log(exception);
    }
  }

  cancel() {
    this.frmCreateInstance.reset();
    this.router.navigate(['home/instanceoperations/instance-requests']);
  }
}
