<div class="alert-wrapper">
  <mat-icon class="alert-icon" class="{{ data.errorClass }}" *ngIf="data.errorClass === 'success'">task_alt</mat-icon>
  <mat-icon class="alert-icon" class="{{ data.errorClass }}" *ngIf="data.errorClass === 'error'">report_gmailerrorred</mat-icon>
  <mat-icon class="alert-icon" class="{{ data.errorClass }}" *ngIf="data.errorClass === 'warning'">warning_amber</mat-icon>
  <mat-icon class="alert-icon" class="{{ data.errorClass }}" *ngIf="data.errorClass === 'information'">error_outline</mat-icon>
  <div class="alert-content">
    <h3 class="alert-heading" class="{{ data.errorClass }}">{{ data.alertType }}</h3>
    <p class="alert-message">{{ data.message }}</p>
  </div>
  <button  mat-icon-button  class="pss-alert-button" aria-label="Close Alert" (click)="snackBarRef.dismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>
