import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './idn-card/card/card.component';
import { MaterialModule } from 'src/app/material.module';
import { IdnCheckboxListComponent } from './idn-checkbox-list/idn-checkbox-list.component';
import { FormsModule } from '@angular/forms';
import { IdnSearchBoxComponent } from './idn-search-box/idn-search-box.component';



@NgModule({
  declarations: [
    CardComponent,
    IdnCheckboxListComponent,
    IdnSearchBoxComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule
  ],
  exports: [
    CardComponent,
    IdnCheckboxListComponent,
    IdnSearchBoxComponent,
  ]
})
export class IdnSharedcomponentsModule { }
