import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ForgetPasswordComponent } from './pages/forget-password/forget-password.component';
import { LoginComponent } from './pages/login/login.component';
import { MAT_LEGACY_FORM_FIELD_DEFAULT_OPTIONS as MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/legacy-form-field';
import { LayoutComponent } from './modules/dashboard/layout/layout.component';
import { NotificationComponent } from './shared/notification/notification-component/notification.component';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from '@angular/common/http';
import { LoaderInterceptor } from './core/interceptors/loader/loader.interceptor';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog/confirm-dialog-component/confirm-dialog.component';
import { CreateRoleComponent } from './modules/role-management/create-role/create-role.component';
import { EditRoleComponent } from './modules/role-management/edit-role/edit-role.component';
import { MapRoleComponent } from './modules/role-management/map-role/map-role.component';
import { RoleListComponent } from './modules/role-management/role-list/role-list.component';
import { ActivityNotificationComponent } from './modules/activity-notification/activity-notification.component';
import {
  FormsModule,
  ReactiveFormsModule
} from '@angular/forms';
import { CategoryByIdPipe } from './core/Pipes/role-management/edit-role/category-by-id.pipe';
import { UploadSdkComponent } from './modules/sdk-management/upload-sdk/upload-sdk.component';
import { SdkListComponent } from './modules/sdk-management/sdk-list/sdk-list.component';
import { ViewRoleComponent } from './modules/role-management/view-role/view-role.component';
import { CreateExtensionComponent } from './modules/extension-management/create-extension/create-extension.component';
import { EditExtensionComponent } from './modules/extension-management/edit-extension/edit-extension.component';
import { ExtensionListComponent } from './modules/extension-management/extension-list/extension-list.component';
import { ViewExtensionComponent } from './modules/extension-management/view-extension/view-extension.component';
import { TagInputModule } from 'ngx-chips';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { EditSdkComponent } from './modules/sdk-management/edit-sdk/edit-sdk.component';
import {
  MsalModule,
  MsalService,
  MSAL_INSTANCE
} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication
} from '@azure/msal-browser';
import { ExtensionTagFilterPipe } from './core/Pipes/extension-management/extension-tag-filter/extension-tag-filter.pipe';
import { ExtensionDetailsComponent } from './modules/extension-management/view-extension/extension-details/extension-details.component';
import { PackagesDetailsComponent } from './modules/extension-management/view-extension/packages/packages-details/packages-details.component';
import { PackagesListingComponent } from './modules/extension-management/view-extension/packages/packages-listing/packages-listing.component';
import { InstanceListComponent } from './modules/instance-management/instance-list/instance-list.component';
import { CreateInstanceComponent } from './modules/instance-management/create-instance/create-instance.component';
import { EditInstanceComponent } from './modules/instance-management/edit-instance/edit-instance.component';
import { ViewInstanceComponent } from './modules/instance-management/view-instance/view-instance.component';
import { ViewSdkComponent } from './modules/sdk-management/view-sdk/view-sdk/view-sdk.component';
import { QuicklinkModule } from 'ngx-quicklink';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DatePipe } from '@angular/common';
import { ResourcesComponent } from './modules/resources/resources.component';
import { ExtensionManagementModule } from './modules/extension-management/extension-management.module';
import { CertificationsModule } from './modules/certifications-management/certifications.module';
import { InstanceManagementModule } from './modules/instance-management/instance-management.module';
import { EnvServiceProvider } from './core/services/env.service.provider';
import { PartnerManagementComponent } from './modules/partner-management/partner-management.component';
import { PartnerManagementModule } from './modules/partner-management/partner-management.module';
import { PageHeaderModule } from './modules/pageheader/pageheader.module';
import { ExtensiontagcountfilterPipe } from './core/Pipes/extension-management/extension-tag-count-filter/extensiontagcountfilter.pipe';
import { CertificationGuardService } from './core/Guards/certificationGuard/certification-guard.service';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { AuditLogComponent } from './modules/audit-log/audit-log.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { HomeComponent } from './pages/landing/home/home.component';
import {
  TranslateModule,
  TranslateLoader
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MaterialModule } from './material.module';
import { MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { UserGuardService } from './core/Guards/userGuard/user-guard.service';
import { RoleGuardService } from './core/Guards/roleGuard/role-guard.service';
import { PartnerGuardService } from './core/Guards/partnerGuard/partner-guard.service';
import { InstanceGuardService } from './core/Guards/instanceGuard/instance-guard.service';
import { MtpodGuardService } from './core/Guards/mtpodGuard/mtpod-guard.service';
import { InstanceRequestGuardService } from './core/Guards/instanceRequestsGuard/instancerequest-guard.service';
import { ExtensionGuardService } from './core/Guards/extensionGuard/extension-guard.service';
import { CustomerGuardService } from './core/Guards/customerGuard/customer-guard.service';
import { WorkbenchGuardService } from './core/Guards/workbenchGuard/workbench-guard.service';
import { SkuGuardService } from './core/Guards/skuGuard/sku-guard.service';
import { PageNotFoundComponent } from './pages/error/page-notfound/page-not-found/page-not-found.component';
import { CamelCasePipe } from './core/Pipes/camelCase/camel-case.pipe';
import { SortPipe } from './core/Pipes/sort/sort.pipe';
import { DateFilterPipe } from './core/Pipes/dateFIlter/date-filter.pipe';
import { HttpErrorInterceptorService } from './core/interceptors/error/httperror-interceptor.service';
import { DeploymentModule } from './modules/deployments/deployment.module';
import { DeploymentGuardService } from './core/Guards/deploymentGuard/deployment-guard.service';
import { IdnGuardService } from './core/Guards/idnGuard/idn-guard.service';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { AESEncryptDecryptService } from './core/services/common/encryption/aesencrypt-decrypt.service';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { environment } from 'src/environments/environment';
import { ValidateUserInterceptor } from './core/interceptors/validate-user-interceptor/validate-user.interceptor';
import { TermsAndConditionComponent } from './pages/terms-and-condition/terms-and-condition.component';
import { HeaderComponent } from './pages/landing/header/header.component';
import { FooterComponent } from './pages/landing/footer/footer.component';
import { AcceptTermsOfUseComponent } from './modules/accept-terms/accept-terms-of-use/accept-terms-of-use.component';
import { BnNgIdleService } from 'bn-ng-idle';
import { TermsDialogComponent } from './modules/accept-terms/terms/terms-dialog.component';
import { AuditGuardService } from './core/Guards/auditGuard/audit-guard.service';
import { SkuDeploymentGuardService } from './core/Guards/skuDeployment/sku-deployment.service';
import { CreateInstanceGuard } from './core/Guards/createinstanceGuard/create-instance.guard';
import { EditInstanceGuard } from './core/Guards/editinstanceGuard/edit-instance.guard';
import { EditRoleGuardService } from './core/Guards/editRoleGuard/edit-role-guard.service';
import { SkuUpdateGuardService } from './core/Guards/skuUpdateGuard/sku-update-guard.service';
import { MultiTenantPodsModule } from './modules/multi-tenant-pods/multi-tenant-pods.module';
import { CreateWebAppComponent } from './modules/web-app-management/create-web-app/create-web-app.component';
import { WebAppModule } from './modules/web-app-management/web-app.module';
import { ICIAcademyCertificationErrorComponent } from './pages/ici-academy-certification-error/ici-academy-certification-error.component';
import { ConfigDrawerComponent } from './modules/instance-management/config-drawer/config-drawer.component';
import { AboutComponent } from './pages/about/about.component';
import { WebAppGuardService } from './core/Guards/webAppGuard/webApp-guard.service';
import { InitiateDeploymentComponent } from './modules/instance-management/initiate-deployment/initiate-deployment.component';
import { CreateIciDeploymentComponent } from './modules/ici-component-deployment/create-ici-deployment/create-ici-deployment.component';
import { ListIciDeploymentComponent } from './modules/ici-component-deployment/list-ici-deployment/list-ici-deployment.component';
import { ViewIciDeploymentComponent } from './modules/ici-component-deployment/view-ici-deployment/view-ici-deployment.component';
import { IciComponentDeploymentGuard } from './core/Guards/iciComponentDeployment/ici-component-deployment.guard';
import { ProvisionUsersComponent } from './modules/instance-management/provision-users/provision-users.component';
import { PodUpgradeModule } from './modules/pod-upgrade/pod-upgrade.module';
import { QuillModule } from 'ngx-quill';
import { IciWorkertaskConfigurationComponent } from './modules/instance-management/ici-workertask-configuration/ici-workertask-configuration.component'
import { IciWorkertaskConfigurationModule } from './modules/ici-workertask-configuration-management/ici-workertask-configuration.module';
import { IciWorkerTaskConfigurationGuardService } from './core/Guards/ici-workertask-configuration/ici-workertask-configuration.guard.service';
import { InstanceToolsComponent } from './modules/instance-management/instance-tools/instance-tools/instance-tools.component';
import { InstanceApprovalComponent } from './modules/instance-management/instance-approval/instance-approval.component';
import { ConfirmDialogCommentsComponent } from './shared/dialog/confirm-dialog/confirm-dialog-comments/confirm-dialog-comments.component';
import { LandingPageComponent } from './modules/instance-management/instance-tools/landing-page/landing-page.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ViewEsignComponent } from './modules/instance-management/instance-tools/view-esign/view-esign.component';
import { ViewDblogsComponent } from './modules/instance-management/instance-tools/view-dblogs/view-dblogs.component';
import { IdnSharedcomponentsModule } from './shared/idn-sharedcomponents/idn-sharedcomponents.module';
import { ViewExportLogsComponent } from './shared/idn-sharedcomponents/view-export-logs/view-export-logs.component';
import { CardComponent } from './shared/sharedComponents/card/card.component';
import { IdnTableComponent } from './shared/sharedComponents/idn-table/idn-table.component';
import  {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { ApprovalManagementComponent } from './modules/approval-management/approval-management.component';
import { ApprovalListComponent } from './modules/approval-management/approval-list/approval-list.component';
import { ViewApprovalComponent } from './modules/approval-management/view-approval/view-approval.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function HttpLoaderFactory(http: HttpClient){
  return new TranslateHttpLoader(http);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: 'https://login.microsoftonline.com/' + environment.tenantId,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    }
  })

}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  let envmainuri = environment.appGatewayUrl;
  protectedResourceMap.set(envmainuri + 'api/', [`api://${environment.audienceId}/idnapiallow`]);
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ForgetPasswordComponent,
    LoginComponent,
    LayoutComponent,
    NotificationComponent,
    ConfirmDialogComponent,
    CreateRoleComponent,
    EditRoleComponent,
    MapRoleComponent,
    RoleListComponent,
    ActivityNotificationComponent,
    CategoryByIdPipe,
    UploadSdkComponent,
    SdkListComponent,
    ViewRoleComponent,
    ExtensionListComponent,
    CreateExtensionComponent,
    EditExtensionComponent,
    ViewExtensionComponent,
    EditSdkComponent,
    ExtensionTagFilterPipe,
    ExtensionDetailsComponent,
    PackagesDetailsComponent,
    PackagesListingComponent,
    InstanceListComponent,
    CreateInstanceComponent,
    EditInstanceComponent,
    ViewInstanceComponent,
    ViewSdkComponent,
    ResourcesComponent,
    PartnerManagementComponent,
    AuditLogComponent,
    ExtensiontagcountfilterPipe,
    DashboardComponent,
    HomeComponent,
    TermsAndConditionComponent,
    HeaderComponent,
    FooterComponent,
    AcceptTermsOfUseComponent,
    AccessDeniedComponent,
    UnauthorizedComponent,
    PageNotFoundComponent,
    CamelCasePipe,
    SortPipe,
    DateFilterPipe,
    TermsDialogComponent,
    CreateWebAppComponent,
    ICIAcademyCertificationErrorComponent,
    ConfigDrawerComponent,
    AboutComponent,
    InitiateDeploymentComponent,
    CreateIciDeploymentComponent,
    ListIciDeploymentComponent,
    ViewIciDeploymentComponent,
    ProvisionUsersComponent,
    IciWorkertaskConfigurationComponent,
    InstanceToolsComponent,
    InstanceApprovalComponent,
    ConfirmDialogCommentsComponent,
    LandingPageComponent,
    CardComponent,
    ViewEsignComponent,
    IdnTableComponent,
    ViewDblogsComponent,
    ViewExportLogsComponent,
    ApprovalManagementComponent,
    ApprovalListComponent,
    ViewApprovalComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    QuicklinkModule,
    FormsModule,
    ReactiveFormsModule,
    TagInputModule,
    MatSelectModule ,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    MsalModule,
    NgMultiSelectDropDownModule.forRoot(),
    PartnerManagementModule,
    ExtensionManagementModule,
    CertificationsModule,
    InstanceManagementModule,
    PageHeaderModule,
    DeploymentModule,
    MultiTenantPodsModule,
    WebAppModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PodUpgradeModule,
    QuillModule.forRoot(),
    IciWorkertaskConfigurationModule,
    NgxJsonViewerModule,
    NgxMatTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    IdnSharedcomponentsModule
  ],
  exports: [
    QuicklinkModule
  ],
  providers: [
    Title,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500,
        hideDelay: 500,
        touchendHideDelay: 500
      }
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ValidateUserInterceptor,
      multi: true
    },
    MsalService,
    EnvServiceProvider,
    MsalGuard,
    MsalBroadcastService,
    CertificationGuardService,
    UserGuardService,
    RoleGuardService,
    PartnerGuardService,
    InstanceGuardService,
    MtpodGuardService,
    InstanceRequestGuardService,
    ExtensionGuardService,
    CustomerGuardService,
    WorkbenchGuardService,
    SkuGuardService,
    SkuUpdateGuardService,
    DeploymentGuardService,
    IdnGuardService,
    AuditGuardService,
    AESEncryptDecryptService,
    SkuDeploymentGuardService,
    BnNgIdleService,
    CreateInstanceGuard,
    EditInstanceGuard,
    EditRoleGuardService,
    WebAppGuardService,
    IciComponentDeploymentGuard,
    IciWorkerTaskConfigurationGuardService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
  constructor() {}
}
