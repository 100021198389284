<app-pageheader *ngIf="extDetails" [pageTitle]="'Extenstion Management > View'" [auditLog]="false" [editExtension]="(showEditButton || editIndex == 0) && (extDetails.status == 2 ? false : true) && (isUpdateExtensionPermission == true ? true : false)" [editViewExtData]="extDetails" [extTabSelection]="extensionTabIndex" [breadCrumbs]="breadCrumbItems"></app-pageheader>
<div class="page-container" *ngIf="extDetails">
  <div class="idn-view-wrapper">
    <div class="idn-view-header">
      <div class="data-left">
        <div class="idn-view-header-label">Extension :</div>
        <div class="idn-view-header-data">
          <h3>{{extDetails.name}}
            <span class="idn-tag tag-warning">{{extDetails.extensionType}}</span>
          </h3>
          <em><b>Created on: {{extDetails.createdAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}  by {{extDetails.createdByDisplayName}}</b></em>
        </div>
      </div>
      <div class="data-right">
        <button mat-button class="idn-primary-button" (click)="addPackage(extDetails)" *ngIf="(extDetails.status == 2 ? false : true) && showAddPackageButton && isCreateSolutionPackage">
          <mat-icon>add_circle_outline</mat-icon>
          <span>{{extensionEnum.package_Create_addNewPackage_btn}}</span>
        </button>
      </div>
    </div>
  </div>
  <div class="form-wrapper">
    <div class="pss-steps-container">
      <mat-tab-group mat-align-tabs="left" animationDuration="0ms" (animationDone)="myTabAnimationDone()" [(selectedIndex)]="extensionTabIndex" (selectedTabChange)="onTabChanged($event)">
        <mat-tab *ngIf="isViewExtension" label="Extension Details">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">article</mat-icon>
            {{extensionEnum.extension_header_view}}
          </ng-template>
          <app-extension-details [extDetailsData]="extDetails"></app-extension-details>
        </mat-tab>
        <mat-tab *ngIf="isViewSolutionPackagePermission" label="Packages">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">inventory_2</mat-icon>
            {{extensionEnum.package_header_create}}
          </ng-template>
          <app-packages-listing [extDetailsData]="extDetails" *ngIf="showTab == 'false'" (ParentComponet)="ParentComponent($event)"></app-packages-listing>
          <app-packages-details [extDetailsData]="extDetails" *ngIf="showTab == 'true'" (ParentComponet)="ParentComponent($event)"></app-packages-details>
        </mat-tab>
        <mat-tab *ngIf="isViewCertificationPermission" label="Certifications">
          <ng-template mat-tab-label>
            <mat-icon class="example-tab-icon">verified_user</mat-icon>
            {{extensionEnum.certification_header_create}}
          </ng-template>
          <div class="certification-table-in-extension">
            <app-certifications-listing [extDetailsData]="extDetails" *ngIf="displayCertificationDetails == false" (certificationDetails)="certificationDetails($event)"></app-certifications-listing>
            <app-certification-details [extDetailsData]="extDetails" *ngIf="displayCertificationDetails == true" (certificationDetails)="certificationDetails($event)"></app-certification-details>
          </div>
        </mat-tab>
      </mat-tab-group>
      
    </div>
  </div>
  <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
    <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
  </button>
</div>
