import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, } from '@angular/core';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressSpinnerMode as ProgressSpinnerMode } from '@angular/material/legacy-progress-spinner';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { COMMA, E, ENTER } from '@angular/cdk/keycodes';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { Tag } from 'src/app/modules/sku-management/release-request/release-request.component';
import { FormControl, FormGroup } from '@angular/forms';
import { SkuManagementService } from 'src/app/core/services/sku-management/sku-management.service';
import { MatSort, Sort } from '@angular/material/sort';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { DatePipe } from '@angular/common';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
const SKU_ICON = `
<svg xmlns="http://www.w3.org/2000/svg" width="16.788" height="20.678" viewBox="0 0 16.788 20.678">
  <path id="Union_2" data-name="Union 2" d="M0,17.106V9.85l7.771,3.162v7.666Zm9.08-4.095L16.788,9.8v7.211L9.08,20.632ZM1.225,8.8,6.082,6.915v3.23h4.646V6.915L15.542,8.8,8.426,11.761Zm6.124-.091V3H6.208L8.383,0l2.154,3H9.355v5.71Z"/>
</svg>
`;
const UPLOAD_FAILED = `
<svg xmlns="http://www.w3.org/2000/svg" width="211.9" height="211.9" viewBox="0 0 211.9 211.9">
  <g id="Group_22" data-name="Group 22" transform="translate(-1212.165 -1104.297)">
    <g id="remove_circle_outline_black_24dp" transform="translate(1212.165 1104.297)">
      <path id="Path_9" data-name="Path 9" d="M0,0H211.9V211.9H0Z" fill="none"/>
      <path id="Path_10" data-name="Path 10" d="M42.45,74.81V90.99h80.9V74.81ZM82.9,2a80.9,80.9,0,1,0,80.9,80.9A80.929,80.929,0,0,0,82.9,2Zm0,145.62A64.72,64.72,0,1,1,147.62,82.9,64.806,64.806,0,0,1,82.9,147.62Z" transform="translate(23.05 23.05)" fill="#b70101"/>
    </g>
  </g>
</svg>
`;
const BUILD_SUCCESS = `<svg xmlns="http://www.w3.org/2000/svg" width="220.722" height="220.934" viewBox="0 0 220.722 220.934">
<g id="Group_20" data-name="Group 20" transform="translate(-962.411 -1178.369)">
  <g id="done_all_black_24dp_1_" data-name="done_all_black_24dp (1)" transform="translate(962.411 1178.37)">
    <path id="Path_12" data-name="Path 12" d="M0,0H220.722V220.722H0Z" fill="none"/>
  </g>
  <g id="verified_black_24dp" transform="translate(966.05 1186.725)">
    <g id="Group_16" data-name="Group 16" transform="translate(0 0)">
      <rect id="Rectangle_13" data-name="Rectangle 13" width="213" height="213" transform="translate(-0.456 -0.422)" fill="none"/>
    </g>
    <g id="Group_17" data-name="Group 17" transform="translate(9.435 13.839)">
      <path id="Path_15" data-name="Path 15" d="M194.77,93.981,173.279,69.407l2.995-32.5-31.8-7.222L127.831,1.5,97.885,14.359,67.939,1.5,51.292,29.6,19.5,36.731l2.995,32.588L1,93.981l21.491,24.574L19.5,151.143l31.8,7.222,16.647,28.1,29.946-12.947,29.946,12.859,16.647-28.1,31.8-7.222-2.995-32.5ZM81.062,135.553,47.593,102,60.628,88.961l20.434,20.522,51.525-51.7,13.035,13.035Z" transform="translate(-1 -1.5)" fill="#018f36"/>
    </g>
  </g>
</g>
</svg>
`;
const BUILD_PROGRESS = `<svg xmlns="http://www.w3.org/2000/svg" width="232" height="232" viewBox="0 0 232 232">
<g id="Group_18" data-name="Group 18" transform="translate(-566 -784)">
  <path id="Path_2" data-name="Path 2" d="M47.03-317.344l13.795-5.344v13.795L72.01-312.87v-13.67L88.415-333v49.586L47.03-267.633Zm-5.344,49.462L0-283.416V-333l41.686,15.783v49.338h0Zm-30.7-19.263,11.061,4.225v-6.09L10.99-292.986ZM0-338.6,44.048-355,88.29-338.6l-16.653,6.338-16.4-7.457-11.185,4.971,16.529,6.587-16.529,6.214Z" transform="translate(637.396 1211.402)" fill="#a76700"/>
  <g id="Group_13" data-name="Group 13" transform="translate(566 784)">
    <rect id="Rectangle_11" data-name="Rectangle 11" width="232" height="232" fill="none"/>
  </g>
  <g id="Group_14" data-name="Group 14">
    <path id="Path_3" data-name="Path 3" d="M71.212,25.281A105.785,105.785,0,0,0,13,1.05v19.4A86.185,86.185,0,0,1,57.5,38.989Z" transform="translate(678.498 793.086)" fill="#a76700"/>
    <path id="Path_4" data-name="Path 4" d="M63.182,20.454V1.05A105.089,105.089,0,0,0,4.97,25.281L18.678,38.989A86.185,86.185,0,0,1,63.182,20.454Z" transform="translate(609.009 793.086)" fill="#a76700"/>
    <path id="Path_5" data-name="Path 5" d="M38.989,18.678,25.281,4.97A105.785,105.785,0,0,0,1.05,63.182h19.4A86.185,86.185,0,0,1,38.989,18.678Z" transform="translate(575.086 827.009)" fill="#a76700"/>
    <path id="Path_6" data-name="Path 6" d="M37.555,63.182h19.4A105.785,105.785,0,0,0,32.728,4.97L19.02,18.678A86.185,86.185,0,0,1,37.555,63.182Z" transform="translate(730.594 827.009)" fill="#a76700"/>
    <path id="Path_8" data-name="Path 8" d="M107.191,73.009A86.5,86.5,0,0,1,35.174,34.394H58.922V15.087H1V73.009H20.307V46.944a105.824,105.824,0,0,0,86.883,45.372A106.1,106.1,0,0,0,207.976,19.334L189.054,14.99A86.915,86.915,0,0,1,107.191,73.009Z" transform="translate(574.654 913.719)" fill="#a76700"/>
  </g>
</g>
</svg>
`;
const BUILD_FAILED = `<svg xmlns="http://www.w3.org/2000/svg" width="188.66" height="188.66" viewBox="0 0 188.66 188.66">
<g id="Group_21" data-name="Group 21" transform="translate(-593.273 -1173.824)">
  <g id="remove_circle_outline_black_24dp" transform="translate(593.273 1173.824)">
    <path id="Path_9" data-name="Path 9" d="M0,0H188.66V188.66H0Z" fill="none"/>
    <path id="Path_10" data-name="Path 10" d="M41.3,72.747V88.469h78.608V72.747ZM80.608,2a78.608,78.608,0,1,0,78.608,78.608A78.637,78.637,0,0,0,80.608,2Zm0,141.495a62.887,62.887,0,1,1,62.886-62.886A62.97,62.97,0,0,1,80.608,143.495Z" transform="translate(13.722 13.722)" fill="#b70101"/>
  </g>
</g>
</svg>

`;
const CERTIFY_PROGRESS = `<svg xmlns="http://www.w3.org/2000/svg" width="211.899" height="211.899" viewBox="0 0 211.899 211.899">
<g id="Group_19" data-name="Group 19" transform="translate(-956.345 -784)">
  <g id="Group_15" data-name="Group 15" transform="translate(380.691 -10.136)">
    <path id="Path_3" data-name="Path 3" d="M71.212,25.281A105.785,105.785,0,0,0,13,1.05v19.4A86.185,86.185,0,0,1,57.5,38.989Z" transform="translate(678.498 793.086)" fill="#a76700"/>
    <path id="Path_4" data-name="Path 4" d="M63.182,20.454V1.05A105.089,105.089,0,0,0,4.97,25.281L18.678,38.989A86.185,86.185,0,0,1,63.182,20.454Z" transform="translate(609.009 793.086)" fill="#a76700"/>
    <path id="Path_5" data-name="Path 5" d="M38.989,18.678,25.281,4.97A105.785,105.785,0,0,0,1.05,63.182h19.4A86.185,86.185,0,0,1,38.989,18.678Z" transform="translate(575.086 827.009)" fill="#a76700"/>
    <path id="Path_6" data-name="Path 6" d="M37.555,63.182h19.4A105.785,105.785,0,0,0,32.728,4.97L19.02,18.678A86.185,86.185,0,0,1,37.555,63.182Z" transform="translate(730.594 827.009)" fill="#a76700"/>
    <path id="Path_8" data-name="Path 8" d="M107.191,73.009A86.5,86.5,0,0,1,35.174,34.394H58.922V15.087H1V73.009H20.307V46.944a105.824,105.824,0,0,0,86.883,45.372A106.1,106.1,0,0,0,207.976,19.334L189.054,14.99A86.915,86.915,0,0,1,107.191,73.009Z" transform="translate(574.654 913.719)" fill="#a76700"/>
  </g>
  <g id="privacy_tip_black_24dp" transform="translate(993.59 820.592)">
    <rect id="Rectangle_12" data-name="Rectangle 12" width="137" height="137" transform="translate(0.41 0.408)" fill="none"/>
    <path id="Path_14" data-name="Path 14" d="M54.878,13.624l40.35,17.927V58.643c0,26.054-17.177,50.091-40.35,57.239-23.172-7.148-40.35-31.185-40.35-57.239V31.551l40.35-17.927M54.878,1,3,24.057V58.643c0,31.992,22.135,61.908,51.878,69.171,29.744-7.263,51.878-37.179,51.878-69.171V24.057L54.878,1ZM49.114,35.586H60.643V47.114H49.114Zm0,23.057H60.643V93.228H49.114Z" transform="translate(14.293 4.678)" fill="#a76700"/>
  </g>
</g>
</svg>
`;
const QUEUEFORUPGRADE =`<svg xmlns="http://www.w3.org/2000/svg" width="153.042" height="151.544" viewBox="0 0 153.042 151.544">
<g id="Group_872" data-name="Group 872" transform="translate(-1562.956 -745.979)">
  <path id="Subtraction_35" data-name="Subtraction 35" d="M-6024.087-2370h-6.544a13.046,13.046,0,0,0,.131-1.837,12.943,12.943,0,0,0-13-12.856,12.984,12.984,0,0,0-12.579,9.6,13.076,13.076,0,0,0-3.634-7.476,19.587,19.587,0,0,1,2.423-2.909,19.564,19.564,0,0,1,4.524-3.343,15.116,15.116,0,0,1-1.242-1.1,14.519,14.519,0,0,1-4.351-10.389,14.515,14.515,0,0,1,4.351-10.391,14.847,14.847,0,0,1,10.507-4.3,14.847,14.847,0,0,1,10.505,4.3,14.517,14.517,0,0,1,4.351,10.391,14.521,14.521,0,0,1-4.351,10.389,15.05,15.05,0,0,1-1.242,1.1,19.6,19.6,0,0,1,4.524,3.343,19.056,19.056,0,0,1,5.712,13.637c0,.615-.029,1.233-.087,1.836Zm-19.413-39.49a9.246,9.246,0,0,0-9.287,9.184,9.245,9.245,0,0,0,9.287,9.182,9.244,9.244,0,0,0,9.285-9.182A9.245,9.245,0,0,0-6043.5-2409.49Zm-12.868,39.49h-.228c.057-.174.112-.354.162-.535.018.171.039.351.066.534Z" transform="translate(7696.404 3195.978)" />
  <path id="Subtraction_43" data-name="Subtraction 43" d="M-6057.229-2365h-1.034c.058-.615.087-1.245.087-1.875a19.434,19.434,0,0,0-2.249-9.079,19.092,19.092,0,0,1,2.681-3.332,19.115,19.115,0,0,1,3.557-2.782,18.444,18.444,0,0,1-2.886-2.333,17.747,17.747,0,0,1-5.289-12.677,17.738,17.738,0,0,1,5.289-12.674A18.019,18.019,0,0,1-6044.3-2415a18.01,18.01,0,0,1,12.771,5.248,17.731,17.731,0,0,1,5.291,12.674,17.742,17.742,0,0,1-5.291,12.677,18.148,18.148,0,0,1-2.886,2.333,19.117,19.117,0,0,1,3.557,2.782,18.669,18.669,0,0,1,5.57,13.342c0,.309-.008.626-.023.943h-6.061c.022-.3.035-.616.035-.944a12.928,12.928,0,0,0-12.963-12.864,12.929,12.929,0,0,0-12.964,12.864c0,.3.011.621.035.944Zm12.929-44.027a12.008,12.008,0,0,0-12.041,11.948,12.01,12.01,0,0,0,12.041,11.951,12.01,12.01,0,0,0,12.042-11.951A12.009,12.009,0,0,0-6044.3-2409.025Z" transform="translate(7734.766 3189.978)" />
  <path id="Union_24" data-name="Union 24" d="M22.806,39A9.063,9.063,0,1,0,6.194,39H.455A14.658,14.658,0,0,1,0,35.371,14.42,14.42,0,0,1,4.247,25.11,14.592,14.592,0,0,1,7,22.944a12.9,12.9,0,0,1-1.474-1.268,12.7,12.7,0,0,1,0-17.957A12.688,12.688,0,0,1,27.188,12.7a12.618,12.618,0,0,1-3.716,8.978A12.85,12.85,0,0,1,22,22.944a14.6,14.6,0,0,1,2.755,2.167A14.42,14.42,0,0,1,29,35.371,14.566,14.566,0,0,1,28.545,39ZM6.344,12.7A8.157,8.157,0,1,0,14.5,4.535,8.169,8.169,0,0,0,6.344,12.7Z" transform="translate(1607.479 787.978)" />
  <path id="Subtraction_52" data-name="Subtraction 52" d="M-5966.157-2301.455a5.193,5.193,0,0,1-3.454-1.263,4.047,4.047,0,0,1-1.434-3.055,4.036,4.036,0,0,1,1.434-3.041l17.136-15.124h-106.7v.328a38.147,38.147,0,0,1-7.745-2.118,36.313,36.313,0,0,1-11.491-7.168,33.316,33.316,0,0,1-7.748-10.633,31.043,31.043,0,0,1-2.843-13.02,31.043,31.043,0,0,1,2.843-13.02,33.283,33.283,0,0,1,7.748-10.632,36.268,36.268,0,0,1,11.491-7.169,38.418,38.418,0,0,1,14.071-2.628,39.122,39.122,0,0,1,8.135.845v.318A14.329,14.329,0,0,0-6048-2379.9a27.823,27.823,0,0,0-4.851-.425c-14.171,0-25.7,10.667-25.7,23.779,0,10.489,7.595,19.873,18.47,22.82v-.241h106.009l-15.542-13.713a4.042,4.042,0,0,1-1.434-3.051,4.034,4.034,0,0,1,1.434-3.044,5.193,5.193,0,0,1,3.454-1.265,5.2,5.2,0,0,1,3.456,1.265l25.312,22.33a4.057,4.057,0,0,1,1.428,3.2,4.039,4.039,0,0,1-1.428,3.194l-25.312,22.337A5.208,5.208,0,0,1-5966.157-2301.455Zm18.155-27.161h0v.731l.414-.366-.414-.366Z" transform="translate(7651.957 3198.979)" />
  <path id="Path_477" data-name="Path 477" d="M0,1,102,0V6H0Z" transform="translate(1607.479 827.978)" />
  <path id="Subtraction_66" data-name="Subtraction 66" d="M-6087-2390v0l-7-12v-13h14v13l-7,12Zm-2-19v5h5v-5Z" transform="translate(7777.479 3160.979)" />
</g>
</svg>`
@Component({
  selector: 'app-packages-listing',
  templateUrl: './packages-listing.component.html',
  styleUrls: ['./packages-listing.component.scss']
})
export class PackagesListingComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['name', 'description', 'iciVersion', 'status', 'action'];
  dataSource: any;
  showTab: any;
  @Input() extDetailsData: any;
  @Output() ParentComponet: EventEmitter<any> = new EventEmitter()
  uploadPackageData: any = { 'showProgressBar': 'false' };
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'indeterminate';
  value = 50;

  // Drawer
  isRequestDrawer: boolean = false;
  isapprovalDrawer: boolean = false;
  panelOpenState = false;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  tags: Tag[] = [];
  drawerPackageDetails: any;
  startDate: any;
  noContent: Boolean = false;
  currentUserId:any;

  initiateReleaseVersionForm = new FormGroup({
    productName: new FormControl(''),
    major: new FormControl(''),
    minor: new FormControl(''),
    patch: new FormControl(''),
    build: new FormControl(''),
    productReleaseDate: new FormControl(''),
    productReleaseNotes: new FormControl(''),
  });

  isViewSolutionPackagePermission: any;
  isCreateSolutionPackage: any;
  isEditSOlutionPackagePermission: any;
  isLockSOlutionPackagePermission: any;
  isUnLockSOlutionPackagePermission: any;
  isInitiateAutomaticCertificationPermission: any;
  isSubmitReleaseVersionPermission: any;
  isCreateRole:any;
  //Filter
  sortBy: any = '';
  pageNumber = 1;
  pageSize = 10;
  currentPage = 0;
  pageLength: any = 0;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public extensionEnum = ExtensionManagement;
  linkageAndQueueForUpgradeFeature: boolean = false;

  constructor(private extensionservice: ExtensionService,
    private blobService: AzureBlobStorageService,
    private notificationService: NotificationService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private skuManagementService: SkuManagementService,
    private certificationsService: CertificationsService,
    private permissionService: PermissionsService,
    private datePipe: DatePipe,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private dialogService: DialogService,) {
    iconRegistry.addSvgIconLiteral('idn-sku', sanitizer.bypassSecurityTrustHtml(SKU_ICON));
    iconRegistry.addSvgIconLiteral('upload_failed', sanitizer.bypassSecurityTrustHtml(UPLOAD_FAILED));
    iconRegistry.addSvgIconLiteral('build_success', sanitizer.bypassSecurityTrustHtml(BUILD_SUCCESS));
    iconRegistry.addSvgIconLiteral('build_progress', sanitizer.bypassSecurityTrustHtml(BUILD_PROGRESS));
    iconRegistry.addSvgIconLiteral('build_failed', sanitizer.bypassSecurityTrustHtml(BUILD_FAILED));
    iconRegistry.addSvgIconLiteral('certify_progress', sanitizer.bypassSecurityTrustHtml(CERTIFY_PROGRESS));
    iconRegistry.addSvgIconLiteral('queue_for_upgrade', sanitizer.bypassSecurityTrustHtml(QUEUEFORUPGRADE));

  }
  ngOnDestroy(): void {
    this.extensionservice.extSubject.next(null)
    this.extDetailsData = undefined;
  }

  ngOnInit(): void {
    const dateObj = new Date();
    this.startDate = new Date(dateObj.setUTCDate(dateObj.getDate() + 1)) // getUTCDate()
    this.isViewSolutionPackagePermission = this.permissionService.getPermission("ViewSolutionPackage");
    this.isCreateSolutionPackage = this.permissionService.getPermission("CreateSolutionPackage");
    this.isEditSOlutionPackagePermission = this.permissionService.getPermission("EditSolutionPackage");
    this.isLockSOlutionPackagePermission = this.permissionService.getPermission("LockSolutionPackage");
    this.isUnLockSOlutionPackagePermission = this.permissionService.getPermission("UnlockSolutionPackage");
    this.isInitiateAutomaticCertificationPermission = this.permissionService.getPermission("InitiateAutomaticCertification");
    this.isSubmitReleaseVersionPermission = this.permissionService.getPermission("SubmitReleaseVersion");
    let featuresTobeDisabled: any = localStorage.getItem('featuresTobeDisabled');
    this.linkageAndQueueForUpgradeFeature = featuresTobeDisabled?.includes('TenantUpgradeDisableLinkage')? false: true;
    if(this.linkageAndQueueForUpgradeFeature){
      this.displayedColumns = ['name', 'description', 'iciVersion','createdBy', 'status', 'action'];
    }
    this.currentUserId = this.permissionService.getUniqueUserid();
    this.isCreateRole = this.permissionService.getPermission("CreateRole");
    if (this.isViewSolutionPackagePermission) {
      this.extensionservice.on<any>().pipe(take(1)).subscribe(
        data => {
          if (data != null && data != "") {
            this.uploadPackageData = data;
            if (data.hasOwnProperty('extid') || data.hasOwnProperty('savePackageAndClose')) {
              this.getExtensionPackageList(data.extid);
            }
          }
        }
      );
      if (this.extDetailsData.id != undefined && this.extDetailsData.id != null && this.extDetailsData.id > 0 && this.isViewSolutionPackagePermission) {
        this.getExtensionPackageList(this.extDetailsData.id);
      }
    }

  }

  // Submit Solution Certification
  submitSolutionCertification(element: any) {
    const reviewerId = this.permissionService.getUniqueUserid();
    const partnerId = this.permissionService.getPartnerDetails().id;
    let payload = {
      "solutionId": element.id,
      "requestorId": reviewerId,
      "requestedOn": `${new Date().toISOString()}`,
      "iciVersion": element.iciVersion,
      "partnerId": partnerId
    }
    this.certificationsService.submitForSolutionCertification(payload).pipe(
      catchError(err => {
        if (err.status != 200) {
          this.notificationService.showNotification(this.extensionEnum.certification_notify_certificationRequestFailed_body_p1 + element.name + this.extensionEnum.certification_notify_certificationRequestFailed_body_p2, 'error', this.extensionEnum.certification_notify_certificationRequestFailed_heading);
        }
        return throwError(err);
      })
    ).subscribe(response => {
      if (response == null) {
        this.notificationService.showNotification(this.extensionEnum.certification_notify_certificationRequestFailed_body_p1 + element.name + this.extensionEnum.certification_notify_certificationRequestFailed_body_p2, 'error', this.extensionEnum.certification_notify_certificationRequestFailed_heading);
      } else {
        this.notificationService.showNotification(this.extensionEnum.certification_notify_certificationRequestSubmitted_body_p1 + element.name + this.extensionEnum.certification_notify_certificationRequestSubmitted_body_p2, 'success', this.extensionEnum.certification_notify_certificationRequestSubmitted_heading);
        setTimeout(() => this.getExtensionPackageList(this.extDetailsData.id), 1000);
        this.certificationsService.certificationList("redirectToCertificationList");
        this.certificationsService.packageStatusUpdate(element.id).subscribe({});
      }
    });
  }

  //To Edit sdk
  edit(navLink: any, element: any) {
    if (element.locked == true || this.extDetailsData.locked == true) {
      this.notificationService.showNotification(this.extensionEnum.package_notify_editPackageLocked_body, 'warning', this.extensionEnum.package_notify_editPackageLocked_heading);
    } else {
      element["fromPackageList"] = true;
      localStorage.setItem("packageId", element.id);
      localStorage.setItem("extId", element.extensionId);
      localStorage.setItem("frmPackageToEditExt", "true");
      navLink = 'home/developmentandutilities/' + navLink;
      this.router.navigate([navLink]);
    }
  }

  //To get Extension Package List
  getExtensionPackageList(id: any) {
    try {
      this.extensionservice.getExtensionPackageList(id, this.sortBy, this.pageNumber, this.pageSize).subscribe((resp: any) => {
        if (resp != undefined && resp != null && resp != "") {
          this.noContent = false;
          this.dataSource = new MatTableDataSource(resp.records);
          this.paginator.pageIndex = this.currentPage;
          this.paginator.length = resp.matchingCount;
          this.paginator._intl.itemsPerPageLabel = 'Records per page';
          this.cd.detectChanges();
        } else {
          this.noContent = true;
        }
      });
    } catch (exception) {
      console.log(exception);
    }
  }

  refresh(event:any) {
    event.preventDefault();
    event.stopPropagation();
    this.getExtensionPackageList(this.extDetailsData.id);
  }

  pageChanged(event: PageEvent) {
    this.pageSize = event.pageSize;
    if (event.pageIndex > this.currentPage) {
      if (Math.floor(event.length / event.pageSize) == event.pageIndex) {
        this.pageNumber = event.pageIndex + 1
        this.currentPage = event.pageIndex
      }
      else {
        this.pageNumber++;
        this.currentPage++;
      }
    } else {
      if (event.pageIndex == 0) {
        this.pageNumber = 1;
        this.currentPage = 0;
      }
      else {
        this.pageNumber--;
        this.currentPage--;
      }
    }
    this.getExtensionPackageList(this.extDetailsData.id);
  }

  // ** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    this.sortBy = sortState.active + (sortState.direction == "asc" ? '' : '_' + sortState.direction);
    this.getExtensionPackageList(this.extDetailsData.id);
  }

  //  To show & hide Tab
  viewPackage(navLink: any, element: any) {
    element["fromPackageList"] = true;
    localStorage.setItem("packageId", element.id);
    localStorage.setItem("extId", element.extensionId);
    localStorage.setItem("frmPackageToEditExt", "true");
    this.extensionservice.emitPackageDetails(element);
    localStorage.setItem('fromViewPackage', JSON.stringify(element));
    this.ParentComponet.emit({ "showTab": true, "showPackageButton": false, "showEditButton": ((this.isEditSOlutionPackagePermission == true) && (!element.locked) && (element.status != 'Build failed') && (element.status != 'Virus scan failed') && (element.status != 'Upload failed')) ? true : false });
  }

  uploadLinkedPackage(navLink:any, element:any){
      this.extDetailsData["fromPackageList"] = true;
      this.extDetailsData["fromPackageListUpload"] = true;
      localStorage.setItem("extIdFromPckgList", JSON.stringify(this.extDetailsData));
      localStorage.setItem("fromPackageList", "true");
      localStorage.setItem("frmPackageToCreateExt", "true");
      localStorage.setItem("packageId", element.id);
      localStorage.setItem("extId", element.extensionId);
      this.extensionservice.emitPackageDetails(this.extDetailsData);
      navLink = 'home/developmentandutilities/' + navLink;
      this.router.navigate([navLink]);
  }

  queueForUpgrade(element:any){
    try{
      this.extensionservice.queueForUpgrade(element.id).subscribe((resp:any)=>{
        this.notificationService.showNotification(this.extensionEnum.package_notify_packageLocked_body_p1 + ' ' + element.name + ' ' + this.extensionEnum.package_notify_package_mark_queuedForUpgrade_body_p2, 'success', this.extensionEnum.package_notify_package_queuedForUpgrade_heading);
        this.getExtensionPackageList(this.extDetailsData.id);
      })
    } catch(error){
      console.log(error)
    }
  }

  linkedPackage(element:any){
    try{
      this.extensionservice.getSolutionPackageHierarchyById(element.id).subscribe((resp:any)=>{
        if(resp){
          if(resp?.parentSolutionPackage){
            resp.parentSolutionPackage["extensionStatus"] = this.extDetailsData.status;
          }
          if(resp?.childSolutionPackages){
            resp?.childSolutionPackages?.map((data:any)=>{
              data["extensionStatus"] = this.extDetailsData.status;
            })
          }
          this.dialogService.confirmDialog({
            title: "Linked Packages",
            module: 'package-details',
            message: JSON.stringify(resp),
            confirmText: '',
            cancelText: 'Close'
          }).subscribe((res: any) => {
            if(res?.action == 'view'){
              this.viewPackage('package-details', res.data)
            } else if(res?.action == 'download'){
              this.downloadSingleFile(res?.data?.id, [res?.data?.fileName], ['Package'])
            }
          });
        }
      })
    } catch(error){
      console.log(error)
    }
    
  }
  // To Download Single FIle
  downloadSingleFile(id: any, fileNames: any, fileHeading: any) {
    for (let [index, fileName] of fileNames.entries()) {
      if (fileName) {
        try {
          var FileSaver = require('file-saver');
          this.extensionservice
            .getBlobDownloadConnectionForSolution(id, fileName)
            .pipe(
              map((data: any) => {
                this.blobService.downloadImageExt(data.sasUrl, data.container, data.blob, (response: any) => {
                  if (response._response != undefined && response._response.status == "200") {
                    response.blobBody.then(
                      (onres: any) => {
                        FileSaver.saveAs(onres, fileName);
                        this.notificationService.showNotification(fileHeading[index].toString() + ' ' + this.extensionEnum.package_notify_view_downloadfile_body_p1, 'success', fileHeading[index].toString() + ' ' + this.extensionEnum.package_notify_view_downloadfile_heading);
                      })
                  } else {
                    this.notificationService.showNotification(this.extensionEnum.package_notify_view_failedDownloadfile_body_p1 + ' ' + fileHeading[index].toString(), 'error', fileHeading[index].toString() + ' ' + this.extensionEnum.package_notify_view_failedDownloadfile_heading);
                  }
                });
              }), catchError((err) => {
                console.log(err);
                return err;
              })
            ).subscribe();
        } catch (err) {
          console.log(err);
        }
      }
    }
  }

  lock(id: number, element: any) {
    try {
      this.extensionservice.isPackageLocked(id).subscribe((res: any) => {
        this.notificationService.showNotification(this.extensionEnum.package_notify_packageLocked_body_p1 + ' ' + element.name + ' ' + this.extensionEnum.package_notify_packageLocked_body_p2, 'success', this.extensionEnum.package_notify_packageLocked_heading);
        this.getExtensionPackageList(this.extDetailsData.id);
      })
    } catch (e) {
      console.log(e);
    }
  }

  unlock(id: number, element: any) {
    try {
      this.extensionservice.isPackageUnlocked(id).subscribe((res: any) => {
        this.notificationService.showNotification(this.extensionEnum.package_notify_packageUnLocked_body_p1 + ' ' + element.name + ' ' + this.extensionEnum.package_notify_packageUnLocked_body_p2, 'success', this.extensionEnum.package_notify_packageUnLocked_heading);
        this.getExtensionPackageList(this.extDetailsData.id);
      })
    } catch (e) {
      console.log(e);
    }
  }

  // Initiate Release Version
  toggleApprovalDrawer() {
    this.isapprovalDrawer = !this.isapprovalDrawer;
  }

  toggleRequestDrawer(element: any) {
    this.initiateReleaseVersionForm.reset();
    this.tags = [];
    this.isRequestDrawer = !this.isRequestDrawer;
    if (element != close) {
      this.drawerPackageDetails = element;
    } else {
      this.drawerPackageDetails = '';
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.tags.push({ name: value });
    }
    event.chipInput!.clear();
  }

  remove(tag: Tag): void {
    const index = this.tags.indexOf(tag);
    if (index >= 0) {
      this.tags.splice(index, 1);
    }
  }

  incerement(type: any) {
    const incrementVal = Number(this.initiateReleaseVersionForm.controls[type].value) + 1;
    this.initiateReleaseVersionForm.controls[type].setValue(incrementVal);
  }

  decrement(type: any) {
    const decrementVal = Number(this.initiateReleaseVersionForm.controls[type].value) - 1;
    this.initiateReleaseVersionForm.controls[type].setValue(decrementVal);
  }

  onSubmitInitiateRelease() {
    let productReleaseDateValue = this.datePipe.transform(this.initiateReleaseVersionForm.controls['productReleaseDate'].value, 'yyyy-MM-ddTHH:mm:ss');
    const tagNames: any = []
    this.tags.forEach(item => tagNames.push(item.name));
    const payload = {
      "productName": this.initiateReleaseVersionForm.controls['productName'].value,
      "solutionPackageId": this.drawerPackageDetails.id,
      "partnerId": this.drawerPackageDetails.partnerId,
      "iciVersion": this.drawerPackageDetails.iciVersion,
      "releaseVersion": this.drawerPackageDetails.major + '.' + this.drawerPackageDetails.minor + '.' + this.drawerPackageDetails.patch + '.' + this.drawerPackageDetails.build,
      "productFamily": this.drawerPackageDetails.extensionType,
      "productReleaseDate": productReleaseDateValue,
      "productRelaseNote": this.initiateReleaseVersionForm.controls['productReleaseNotes'].value,
      "major": this.initiateReleaseVersionForm.controls['major'].value,
      "minor": this.initiateReleaseVersionForm.controls['minor'].value,
      "patch": this.initiateReleaseVersionForm.controls['patch'].value,
      "build": this.initiateReleaseVersionForm.controls['build'].value,
      "description": this.drawerPackageDetails.description,
      "tags": tagNames,
      "customerId": this.drawerPackageDetails.customerId
    }
    this.skuManagementService.initiateReleaseVersion(payload).pipe(
      catchError(err => {
        if (err != "") {
          this.notificationService.showNotification(`${err}`, 'error', this.extensionEnum.certification_notify_initiateReleaseVersionFailed_heading);
        }
        else {
          this.notificationService.showNotification(this.extensionEnum.certification_notify_initiateReleaseVersionFailed_body, 'error', this.extensionEnum.certification_notify_initiateReleaseVersionFailed_heading);
        }
        return throwError(err);
      })
    ).subscribe(response => {
      this.notificationService.showNotification(this.extensionEnum.certification_notify_initiateReleaseVersionApprovalSuccess_body, 'success', this.extensionEnum.certification_notify_initiateReleaseVersionApprovalSuccess_heading);
      // this.router.navigate(['/home/sku-management/release-request']);
      localStorage.setItem("isToggleMenuContainer", "true");
      window.location.href = 'home/sku-management/release-request'
    });
  }

}
