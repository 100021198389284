<div  *ngIf="certificationDetailsData; else noContent">
    <div *ngIf="(certificationDetailsData.status != certificationStatus.certified) && (certificationDetailsData.status != certificationStatus.rejected)">
        <div class="certification-details-container">
            <div class="certification-details">
                <div class="certification-details-list">

                    <ul>
                        <li>
                            <div class="package-info"><span>{{certificationEnum.certification_Details_Field_packageName}}</span><span class="package-name-details" matTooltip="{{certificationDetailsData.packageName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ certificationDetailsData.packageName }}</span> </div>
                        </li>
                        <li>
                            <div class="package-info"><span>{{certificationEnum.certification_Details_Field_projectVersion}}</span> {{ certificationDetailsData.packageVersion }} </div>
                        </li>
                        <li>
                            <div class="package-info"><span>{{certificationEnum.certification_Details_Field_customerName}}</span> {{ certificationDetailsData.customerName }}</div>
                        </li>
                        <li>
                            <div class="package-info" [ngClass]="{'certified': certificationDetailsData.status == certificationStatus.certified, 'rejected': certificationDetailsData.status == certificationStatus.rejected, 'system-check-failed': certificationDetailsData.status == certificationStatus.systemCheckFailed, 'review-requested': certificationDetailsData.status == certificationStatus.reviewRequested, 'system-check-success': certificationDetailsData.status == certificationStatus.systemCheckSuccess, 'certification-requested': certificationDetailsData.status == certificationStatus.certificationRequested}">
                                <span>STATUS</span>
                                <div class="icon-style">
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.systemCheckSuccess">check_circle_outline</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.certified">verified_user</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.systemCheckFailed">error_outline</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.reviewRequested">start</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.rejected">gpp_bad</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.reviewInProgress">downloading</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.certificationRequested">rotate_right</mat-icon>
                                    {{ certificationDetailsData.status == certificationStatus.certified ? 'Approved' : certificationDetailsData.status }}
                                    <span matTooltip="Withdrawn by {{certificationDetailsData.requestWithdrawerName}} on {{ certificationDetailsData.requestWithdrawnOn | date:'d MMMM, y h:mm \'(UTC)\'' }}">
                                        <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.systemCheckSuccess && (certificationDetailsData.requestWithdrawnBy && certificationDetailsData.requestWithdrawnOn ? true:false) ">transit_enterexit</mat-icon>
                                    </span>
                                </div>                                            
                            </div>
                        </li>
                        <li>
                            <div class="package-info"><span>{{certificationEnum.certification_Details_Field_requestorDetails}}</span> {{ certificationDetailsData.requestedOn | date: "dd MMMM, yyyy 'at' HH:mm a '(UTC)'" | dateFormat  }} </div>
                        </li>
                        <li>
                            <div class="last-updated-date package-info"><span>{{certificationEnum.certification_Details_Field_lastUpdateDate}}</span> {{ certificationDetailsData.lastModifiedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat }} </div>
                        </li>
                        <li *ngIf="(hasGlobalDataAccess || canViewCertification) && (certificationDetailsData.status == certificationStatus.reviewRequested || certificationDetailsData.status == certificationStatus.certified || certificationDetailsData.status == certificationStatus.rejected || certificationDetailsData.status == certificationStatus.reviewInProgress) && certificationDetailsData?.veracodeScanStatus">
                            <div class="veracode-info"> Vulnerability Scan Status <span *ngIf="certificationDetailsData.veracodeScanStatus == 'In Progress' || certificationDetailsData.veracodeScanStatus == 'InitialState'"> <button class="refresh-button" mat-button (click)="refreshStatus()" title="Refresh Veracode Scan status"><mat-icon>refresh</mat-icon></button></span> <br>
                                <div [ngClass]="{'certified': certificationDetailsData.veracodeScanStatus == 'Successful', 'expired': certificationDetailsData.veracodeScanStatus == 'Failed' ,'expiring-soon': certificationDetailsData.veracodeScanStatus == 'In Progress' || certificationDetailsData.veracodeScanStatus == 'InitialState'}">
                                    <span *ngIf="certificationDetailsData.veracodeScanStatus != 'InitialState' && hasGlobalDataAccess">{{certificationDetailsData.veracodeScanStatus}} </span> 
                                    <span *ngIf="certificationDetailsData.veracodeScanStatus == 'InitialState' || (certificationDetailsData.veracodeScanStatus == 'In Progress' && !hasGlobalDataAccess)">In Progress </span> 
                                    <span *ngIf="certificationDetailsData.veracodeScanStatus == 'Successful' && !hasGlobalDataAccess">Successful </span> 
                                    <span *ngIf="certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore != null && !hasGlobalDataAccess">Failed </span> 
                                    <span *ngIf="certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore == null && !hasGlobalDataAccess" class="expiring-soon">In Progress </span>
                                    <span *ngIf="certificationDetailsData?.veracodeScanScore">(Score: {{certificationDetailsData.veracodeScanScore}}%)</span>
                                </div>
                                <span class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore == null && certificationDetailsData.reTriggerCount == 2">{{certificationEnum.certification_details_veracodeResubmit_msg}} <span class="resubmit-info" *ngIf="certificationDetailsData.reTriggerCount == 2">1{{certificationEnum.certification_details_veracodeResubmit_second_Attempt}}</span></span>
                                <span class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore == null && (certificationDetailsData.reTriggerCount == 1 || certificationDetailsData.reTriggerCount == 0)">{{certificationEnum.certification_details_veracodeResubmit_msg}} 
                                    <span class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.reTriggerCount == 0">3</span>
                                    <span class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.reTriggerCount == 1">2</span>
                                </span>
                            </div>
                        </li>
                          <li *ngIf="certificationDetailsData?.veracodeReportFileName && (hasGlobalDataAccess || canViewCertification) && (certificationDetailsData.status == certificationStatus.reviewRequested || certificationDetailsData.status == certificationStatus.certified || certificationDetailsData.status == certificationStatus.rejected || certificationDetailsData.status == certificationStatus.reviewInProgress)">
                            <div class="veracode-info">VULNERABILITY REPORT</div>
                            <button mat-button class="align-to-label" (click)="downloadSingleFile(certificationDetailsData.veracodeReportFileName, certificationDetailsData.uniqueIdentifier, 'veracodeReportFile')">
                                <div class="idn-grid-link-button">
                                    <div class="pckg-field" matTooltip="{{certificationDetailsData.veracodeReportFileName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ certificationDetailsData.veracodeReportFileName }}</div>
                                    <mat-icon class="download-icon" >download
                                    </mat-icon>
                                </div>
                            </button>
                        </li>
                        <li class="full-width">
                            <div class="package-info"><span>{{certificationEnum.certification_Details_Field_packageDescription}}</span> {{ certificationDetailsData.packageDescription }}</div>
                        </li>
                        <li class="full-width" [class.download-disabled]="extDetailsData.status == 2" *ngIf="certificationDetailsData?.packageFileName">
                            <div class="package-info package-file packg"><span>{{certificationEnum.certification_Details_Field_packageFile}}</span><p (click)="extDetailsData.status == 1 ? downloadSingleFile(certificationDetailsData.packageFileName, certificationDetailsData.uniqueIdentifier, 'SolutionPackage') : ''">{{ certificationDetailsData.packageFileName }} <mat-icon>download</mat-icon></p></div>
                        </li>
                        <li class="full-width" [class.download-disabled]="extDetailsData.status == 2" *ngIf="previousPackage?.fileName && linkageAndQueueForUpgradeFeature">
                            <div class="package-info package-file packg"><span>Previous Package File</span><p (click)="extDetailsData.status == 1 ? downloadSingleFile(previousPackage.fileName, previousPackage.uniqueIdentifier, 'SolutionPackage', true) : ''">{{ previousPackage.fileName }} <mat-icon>download</mat-icon></p></div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="pss-form-button-wrapper package-details-footer">
            <button mat-raised-button color="primary" [disabled]="certificationDetailsData.reTriggerCount == 3" (click)="resubmitForVulnerabilityScan(certificationDetailsData.id)" *ngIf = "(certificationDetailsData?.veracodeScanStatus == 'Failed' && certificationDetailsData?.veracodeScanScore == null) && (hasGlobalDataAccess || canApproveCertification) && certificationDetailsData.reTriggerCount != 3">
                <span>{{certificationEnum.certification_approver_List_Field_Resubmit_vulnerability_scan}}</span>
                </button>
            <button mat-stroked-button class="idn-chips-download" (click)="withdrawRequest(certificationDetailsData)" *ngIf = "(certificationDetailsData.status == certificationStatus.reviewRequested) && ((isRequestorWithdraw) || (hasGlobalDataAccess && isInitiateAutomaticCertificationPermission)) && (!certificationDetailsData.isExceptionalApproval) && (certificationDetailsData.veracodeScanStatus == 'In Progress' || certificationDetailsData.veracodeScanStatus == 'InitialState' || certificationDetailsData.veracodeScanStatus == null)" >
                <mat-icon>transit_enterexit</mat-icon><span>{{certificationEnum.certification_approver_List_Field_withdraw_request}}</span>
            </button>
            <button mat-stroked-button class="idn-chips-download" (click)="extDetailsData.status == 1 ? downloadCertificationFiles(certificationDetailsData) : ''" [disabled]="!(extDetailsData.status == 2 ? false : true)">
                <mat-icon>download</mat-icon><span>{{certificationEnum.certification_Details_Field_downloadFiles}}</span>
            </button>

            <button mat-stroked-button class="idn-button-secondary" *ngIf="certificationDetailsData.status == certificationStatus.systemCheckFailed && isExceptionalApproval" (click)="initiateManualCertification(certificationDetailsData)" [disabled]="(certificationDetailsData.status != certificationStatus.systemCheckSuccess) && (certificationDetailsData.status != certificationStatus.systemCheckFailed)">
              <mat-icon>local_police</mat-icon><span class="exception-approval-btn"> {{certificationEnum.certification_Details_Field_initiateManual_btn}}<br> <ng-container *ngIf="certificationDetailsData.status == certificationStatus.systemCheckFailed && isExceptionalApproval"> (Exception Approval)</ng-container></span>
            </button>

            <button mat-stroked-button class="idn-button-secondary" *ngIf="certificationDetailsData.status == certificationStatus.systemCheckSuccess && isInitiateManualCertification" (click)="initiateManualCertification(certificationDetailsData)" [disabled]="(certificationDetailsData.status != certificationStatus.systemCheckSuccess) && (certificationDetailsData.status != certificationStatus.systemCheckFailed)">
              <mat-icon>local_police</mat-icon><span class="exception-approval-btn"> {{certificationEnum.certification_Details_Field_initiateManual_btn}}</span>
            </button>

            <button mat-stroked-button (click)="onCancel()">{{certificationEnum.certification_Details_Field_cancel_btn}}</button>
        </div>
    </div>

    <div *ngIf="(certificationDetailsData.status == certificationStatus.certified) || (certificationDetailsData.status == certificationStatus.rejected)">
        <div class="certification-details-container">
            <div class="certification-summary-container">
                <div class="package-details">
                    <div class="status-bg" [ngClass]="{'success-bg': certificationDetailsData.status == certificationStatus.certified, 'rejected-bg': certificationDetailsData.status == certificationStatus.rejected}">
                        <ul>
                            <li><p><span>{{certificationEnum.certification_Details_Field_packageName}}</span> {{ certificationDetailsData.packageName }} </p></li>
                            <li><p><span>{{certificationEnum.certification_Details_Field_projectVersion}}</span> {{ certificationDetailsData.packageVersion }}</p></li>
                            <li class="two-colomn">
                                <p><span>{{certificationEnum.certification_Details_Field_extensionType}}</span> {{ certificationDetailsData.extensionType }} </p>
                            </li>
                            <ng-container *ngIf="certificationDetailsData.status == certificationStatus.certified">
                                <li><p class="status-tag"> {{certificationEnum.certification_Details_Field_passed}} </p></li>
                            </ng-container>
                            <ng-container *ngIf="certificationDetailsData.status == certificationStatus.rejected">
                                <li><p class="status-tag"> {{certificationEnum.certification_Details_Field_failed}} </p></li>
                            </ng-container>
                        </ul>
                    </div>
                    <div class="package-status-details">
                        <ul>
                            <li><p><span>{{certificationEnum.certification_Details_createdBy}}</span> {{ certificationDetailsData.requesterName }} <span>on {{ certificationDetailsData.requestedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"  }}</span></p></li>
                            <li [ngClass]="{'certified': certificationDetailsData.status == certificationStatus.certified, 'rejected': certificationDetailsData.status == certificationStatus.rejected}">
                                <p>
                                    <span>{{certificationEnum.certification_Details_Status}}</span>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.rejected">gpp_bad</mat-icon>
                                    <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.certified">verified_user</mat-icon>
                                    {{ certificationDetailsData.status == certificationStatus.certified ? 'Approved' : certificationDetailsData.status }}
                                </p>
                                <p class="exceptional-approval-tag" *ngIf="certificationDetailsData.isExceptionalApproval == true">
                                    Exception Approval
                                </p>
                            </li>
                            <li>
                                <p><span>on {{ certificationDetailsData.reviewerApprovedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"  }}</span></p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="certification-summary">
                    <div class="certification-summary-content">
                        <p><span>{{certificationEnum.certification_Details_approverDetailssummary}}</span>
                            <ng-container *ngIf="certificationDetailsData.status == certificationStatus.certified || certificationDetailsData.status == certificationStatus.rejected">
                                <quill-view-html  class="ql-editor" [content]="certificationDetailsData.reviewerComment"></quill-view-html>
                            </ng-container>
                        </p>
                    </div>

                    <button mat-stroked-button class="idn-button-secondary" (click)="extDetailsData.status == 1 ? downloadCertificationFiles(certificationDetailsData) : ''" [disabled]="!(extDetailsData.status == 2 ? false : true)">
                        <mat-icon>download</mat-icon><span>{{certificationEnum.certification_Details_Field_downloadFiles}}</span>
                    </button>
                    
                </div>
            </div>
            
        </div>
       
           
       
        <div class="pss-form-button-wrapper package-details-footer">
            <button mat-stroked-button (click)="onCancel()">{{certificationEnum.certification_Details_Field_cancel_btn}}</button>
        </div>
    </div>
</div>
<ng-template #noContent>
    <p class="no-content-found">No certification details found</p>
</ng-template>
<app-download-files *ngIf="downloadFilesActive"></app-download-files>
<app-intiate-certification *ngIf="intiateCertificationActive" [certDetailsData]="certDetails" ></app-intiate-certification>
