<div class="pss-dialog-header">
    <div mat-dialog-title>{{data.title}}</div>
    <button mat-icon-button [mat-dialog-close]="false" aria-label="close Button">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content tabindex="0">
    <div class="dialog-content">
      <div class="lbl-rejectreason" *ngIf="data?.message && data.module != 'jsonView' && data.module != 'approve-deployment' && data?.module != 'upgradeActivity' && data.module != 'withdraw-certification' && data.module != 'accessDebuggingUtilities'">
        <mat-icon>info</mat-icon><span>{{data.message}}</span> 
      </div>
      <div *ngIf="data.module == 'withdraw-certification'" >
        <p [innerHtml]="data.message" class="withdraw-certification">
        </p>     
      </div>      
      <div *ngIf="data.module == 'jsonView'">
        <div class="serch-json" *ngIf="data.module == 'jsonView'">
          <mat-form-field class="pss-block-form searchjson">
            <mat-label class="mat-label-text">Enter the search text</mat-label>
            <input type="text" [(ngModel)]="searchText" name="searchJsonText" (keyup.enter)="onInputChange()" matInput maxlength="500" />
            <span class="idn-custom-search" matSuffix>
              <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event)">
                <mat-icon>close</mat-icon>
              </button>
            </span>
            </mat-form-field>
            <button mat-flat-button class="search-json-button" color="primary" (click)="onInputChange()" >Search</button>
         </div>
         <div class="json-viewer-div" *ngIf="!noDataFound; else noContentTemplate" tabindex="0">
          <ngx-json-viewer [json]="dataJson" [expanded]='false' *ngIf="allowCopy"></ngx-json-viewer>
          <ngx-json-viewer [json]="dataJson" [expanded]='false' *ngIf="!allowCopy"  oncopy= 'return false'></ngx-json-viewer>
         </div>
      </div>
      <form class="deployment" *ngIf="data.module == 'request-deployment' || data.module == 'approve-deployment'">
        <div class="request-note" *ngIf="data.module == 'request-deployment'">{{sharedEnum.deployment_request_note}}</div>
        <div class="request-note" *ngIf="data.module == 'approve-deployment'">{{sharedEnum.deployment_approval_note}}</div>
        <mat-form-field class="pss-block-form">
          <mat-label>{{sharedEnum.deployment_date_time_label}}</mat-label>
          <input matInput [ngxMatDatetimePicker]="picker" placeholder="Choose date & time"  [formControl]="dateControl"
          [min]="minDate" required readonly>
          <mat-datepicker-toggle matSuffix [for]="$any(picker)" (click)="onToggle()" >
          </mat-datepicker-toggle>
          <ngx-mat-datetime-picker #picker [showSpinners]="showSpinners" [stepHour]="stepHour" [showSeconds]="showSeconds"
              [stepMinute]="stepMinute" [touchUi]="touchUi" [enableMeridian]="true" >
          </ngx-mat-datetime-picker>
          <mat-error>{{sharedEnum.deployment_date_time_error}}</mat-error>
      </mat-form-field>
      </form>
      <form  *ngIf="data.module == '' || data.module == 'approve-deployment' || data.module == 'withdraw-certification'">
      <mat-form-field class="pss-block-form">
        <mat-label class="mat-label-text">{{data.module == 'withdraw-certification' ? 'Comments / Notes' : 'Comments/Feedback' }}</mat-label>
          <textarea class="idn-min-textarea-height" type="text" maxlength="500" width="50" [(ngModel)]="comments" name="comment" matInput required ></textarea>
        </mat-form-field>
        </form>
        <form *ngIf="canStartLaActivity && data.module == 'upgradeActivity'">
          <div class="laActivity-info">
            <mat-icon>info</mat-icon><span>{{sharedEnum.laActivity_info_message}}</span> 
           </div>
          <mat-form-field class="pss-block-form">
            <mat-label class="mat-label-text">{{laActivityEnum.la_activity_input_label}}</mat-label>
              <input type="text" [(ngModel)]="targetIciVersion" name="targetIciVersion" matInput maxlength="15" required />
            </mat-form-field>
            <mat-error *ngIf="errorMsg">{{laActivityEnum.la_activity_validation_msg}}</mat-error>
        </form>
    </div>
    <div  class="dialog-content" *ngIf="data.module == 'accessDebuggingUtilities'">
      <div class="dialog-info">
       <div><mat-icon class="info-icon">info</mat-icon></div>
       <div>
        <span *ngIf="data.confirmText == 'Approve'">{{this.sharedEnum.approval_popup_instruction}}<b>{{this.sharedEnum.approve_btn_label}}</b>.</span>
        <span *ngIf="data.confirmText == 'Reject'"> {{this.sharedEnum.rejection_popup_instruction}}<b>{{this.sharedEnum.reject_btn_label}}</b>.</span>
      </div> 
      </div>
      <div class="approve-request">
      <div class="select-hrs">
        <form class="config-form">
          <mat-form-field class="pss-block-form" *ngIf=" data?.module == 'accessDebuggingUtilities' && data?.confirmText == 'Approve'">
            <mat-label class="mat-label-text">Select Hours</mat-label>
              <mat-select [(ngModel)]="selectedHours" required name="duration">
                <mat-option *ngFor="let duration of Duration" [value]="duration.displayName" >
                  {{duration.displayName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="pss-block-form">
              <mat-label *ngIf="data.confirmText == 'Approve'" class="mat-label-text">{{this.sharedEnum.approval_reason_label}}</mat-label>
              <mat-label  *ngIf="data.confirmText == 'Reject'"class="mat-label-text">{{this.sharedEnum.rejection_reason_label}}</mat-label>
              <textarea type="text" [(ngModel)]="comments" name="comment" matInput required ></textarea>
            </mat-form-field>
        </form>
      </div>
      </div>
    </div>
    <div class="dialog-content" *ngIf="data.module == 'accessToHangfireDashboard'">
      <div>
        <div class="hangfire-info-text">
          <mat-icon class="info-icon">info</mat-icon>
          <span *ngIf="data.confirmText == 'Approve'">{{this.sharedEnum.approval_popup_instruction}}<b>{{this.sharedEnum.approve_btn_label}}</b>.</span>
          <span *ngIf="data.confirmText == 'Reject'"> {{this.sharedEnum.rejection_popup_instruction}}<b>{{this.sharedEnum.reject_btn_label}}</b>.</span> 
        </div>
        <mat-form-field class="pss-block-form">
          <textarea type="text" [(ngModel)]="comments" name="comments" matInput required ></textarea>
        </mat-form-field>
       </div>
    </div>
  </div>
  <div mat-dialog-actions [align]="'center'">
    <button mat-flat-button cdkFocusInitial *ngIf="data?.module == ''" [mat-dialog-close]="comments" class="pss-dialog-focused-button min-width-button c-dark" cdkFocusInitial color="accent" [disabled]="validateComments()" >Confirm</button>
    <button mat-flat-button cdkFocusInitial *ngIf="data.module == 'request-deployment' || data.module == 'withdraw-certification'" [mat-dialog-close]="data.module == 'withdraw-certification' ? comments :dateControl.value" class="pss-dialog-focused-button min-width-button c-dark" cdkFocusInitial color="accent" [disabled]="validateComments()">Confirm</button>
    <button mat-flat-button cdkFocusInitial *ngIf="data.module == 'approve-deployment' || (data?.module == 'accessDebuggingUtilities' && data?.confirmText == 'Approve') || (data?.module == 'accessToHangfireDashboard' && data?.confirmText == 'Approve')" [mat-dialog-close]="data.module == 'approve-deployment'? approveRejectDeployment('Approve'): approveOrRejectRequest()" class="pss-dialog-focused-button min-width-button c-dark" cdkFocusInitial color="accent" [disabled]="validateComments()">{{this.sharedEnum.approve_btn_label}}</button>
    <button mat-stroked-button cdkFocusInitial *ngIf="data.module == 'approve-deployment' || (data?.module == 'accessDebuggingUtilities' && data?.confirmText == 'Reject') || (data?.module == 'accessToHangfireDashboard' && data?.confirmText == 'Reject')" [mat-dialog-close]="data.module == 'approve-deployment'?approveRejectDeployment('Reject'): approveOrRejectRequest()" class="pss-dialog-focused-button min-width-button c-dark" cdkFocusInitial class="pss-btn-danger" [disabled]="validateRejectDeployment()">{{this.sharedEnum.reject_btn_label}}</button>
    <button mat-flat-button cdkFocusInitial *ngIf="data?.module == 'upgradeActivity'" [mat-dialog-close]="param" class="pss-dialog-focused-button min-width-button c-dark" cdkFocusInitial color="accent" [disabled]="validateComments()" >{{laActivityEnum.la_activity_btn_label}}</button>
    <button mat-stroked-button [mat-dialog-close]="false" class="min-width-button" *ngIf="data.module != 'approve-deployment'">Cancel</button>
  </div>
  

  <ng-template #noContentTemplate>
    <div class="idn-no-result">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
        <defs>
          <clipPath id="clip-path">
            <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
          </clipPath>
        </defs>
        <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
          <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
          <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
          <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
          <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
          <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
          <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
            <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
            <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
          </g>
          <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
            <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
          </g>
        </g>
      </svg>
      <h4>No Data Found!</h4>
    </div>
  </ng-template>