<app-pageheader [pageTitle]="'Extension Management > Create'" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
<div class="page-container">
    <div class="form-wrapper">
        <mat-stepper linear #stepper [@.disabled]="isAnimationDisable" [orientation]="this.orientation" aria-busy="true">
            <mat-step [stepControl]="frmCreateExtension"  completed=false>
                <div class="custom-info"  *ngIf="RLExtensionName || selectedCustomerName">
                    <mat-icon class="info">info </mat-icon>
                    <div class="extension-limit">
                        <span class="header">Extension Limit Reached</span>
                        <ul class="list-message">
                            <li *ngIf="RLExtensionName">“Reusable Solution” extension type isn't available because "{{RLExtensionName}}" already exists for this partner.</li>
                            <li *ngIf="selectedCustomerName">"Custom Solution" extension type isn't available because "{{selectedCustomerName}}_CustomSolutions" already exists for this customer.</li>
                        </ul>                      
                    </div>
                </div>
                <form class="pss-dashboard-form" [formGroup]="frmCreateExtension">
                    <br>
                    <ng-template matStepLabel>{{extensionLabel}} <br>
                        <h6>
                            <mat-hint class="info">{{extensionEnum.extension_create_hint_message_provide}}
                            </mat-hint>
                        </h6>
                    </ng-template>
                    <div></div>
                    <div class="pss-left-panel">
                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_customerName}}</mat-label>
                            <mat-select formControlName="customerId" [disabled]="extInEditMode && isCustomerUser" disableOptionCentering #customername required (selectionChange)="bindExtensionDropDown()">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel="Search Customers"
                                        noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrlCo">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let type of CustomerNameList" [value]="type.id">
                                    {{type.customerName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="frmCreateExtension.controls.customerId.invalid">{{ extensionEnum.extension_error_field_customer_name_required }}</mat-error>

                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_type}}</mat-label>
                            <mat-select formControlName="typeId" disableOptionCentering
                                (selectionChange)="extensionTypeSelect($event)"  [disabled]="extInEditMode" required>
                                <mat-option *ngFor="let type of ExType" [value]="type.id">
                                    {{type.name}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="frmCreateExtension.controls.typeId.invalid">{{ extensionEnum.extension_error_field_type_required }}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_name}}</mat-label>
                            <input type="text" maxlength="100" [disabled]="extInEditMode" formControlName="name" matInput
                                placeholder="Extension Name" required>
                            <mat-error *ngIf="frmCreateExtension.controls.name.invalid">{{ extensionEnum.extension_package_create_error_message_name }}</mat-error>

                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_extensionDescription}}</mat-label>
                            <textarea class="idn-min-textarea-height" type="text" maxlength="500" width="50" formControlName="description" matInput
                                placeholder="Description" height="30" [(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)"  required></textarea>
                                <mat-error *ngIf="frmCreateExtension.controls.description.invalid">{{errorDescriptionName}} </mat-error>
                        </mat-form-field>

                        <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_tag}}</mat-label>
                            <mat-chip-list #chipList formControlName="tags" role="list" [disabled]="extInEditMode" aria-label="Extension Tags" aria-hidden="true">
                              <mat-chip *ngFor="let item of frmCreateExtension.value.tags" [disabled]="extInEditMode" (removed)="remove(item)" aria-label="Extension Tags">
                                {{item}}
                                <button matChipRemove>
                                  <mat-icon>cancel</mat-icon>
                                </button>
                              </mat-chip>
                              <input [disabled]="extInEditMode" placeholder="+Tag"
                              [matChipInputFor]="chipList"
                              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                              [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                            </mat-chip-list>
                            <mat-error *ngIf="frmCreateExtension.controls.tags.invalid">{{ extensionEnum.extension_error_field_tag }}</mat-error>
                            <mat-hint class="idn-mat-hint">
                              <span class="material-icons">info</span>
                              <span class="message">{{extensionEnum.extension_create_hint_message_provideTags}}</span>
                            </mat-hint>

                        </mat-form-field>
                    </div>

                    <div class="pss-right-panel">
                        <div class="idn-field-add">
                          <mat-form-field class="pss-block-form">
                            <mat-label>{{extensionEnum.extension_Create_Field_referenceDocument}}</mat-label>
                            <mat-toolbar class="idn-upload-toolbar">
                                <input matInput id="browse-button" class="input-files" [(ngModel)]="fileAttr"
                                    [ngModelOptions]="{standalone: true}" readonly name="name"  />
                                <button mat-flat-button color="primary" class="idn-file-upload" [disabled]="extInEditMode" (click)="file.click()">Browse</button>
                            </mat-toolbar>
                            <label for="uploadFile" class="idn-off-focus-label">{{extensionEnum.extension_Create_Field_referenceDocument}}</label>
                            <input type="file" #fileInput id="uploadFile" name="uploadFile"
                                accept=".pdf,.doc,.docx,.txt" [disabled]="extInEditMode" (change)="uploadFileEvt($event)" aria-label="uploadFile"/>
                                <mat-hint class="idn-mat-hint">
                                  <span class="material-icons">info</span>
                                  <span class="message">{{extensionEnum.extension_create_hint_message_provideFile}}</span>
                                </mat-hint>
                          </mat-form-field>
                          <button mat-icon-button aria-label="Add Files" type="button" class="mat-plus-icon" [disabled]="extInEditMode" (click)="fileAdd()" #addbutton>
                            <mat-icon color="white">add_box</mat-icon>
                          </button>
                        </div>

                        <div class="pss-Bottom-right">
                          <div class="idn-chips-wrapper">
                            <mat-chip-list #chipList formControlName="tags" role="list" aria-label="Supported Files" aria-hidden="true">
                              <mat-chip *ngFor="let item of listofUploadedFiles" [disabled]="extInEditMode" (removed)="removeFileTag(item)" role="button">
                                  {{item}}
                                  <span matChipRemove>
                                      <mat-icon>cancel</mat-icon>
                                      <span class="sr-only">Cancel</span>
                                  </span>
                              </mat-chip>
                          </mat-chip-list>
                          </div>
                        </div>

                    </div>

                </form>
                <div class="pss-form-button-wrapper idn-button-wrapper-mobile">
                    <button *ngIf="extInEditMode" mat-raised-button color="primary" #saveAndClosebtn [disabled]="!frmCreateExtension.valid || !extInEditMode"
                        (click)="updateAndCLose('close')">{{extensionEnum.extension_Create_updateAndClose_btn}}</button>
                    <button mat-raised-button color="primary" *ngIf="!extInEditMode" #saveAndClosebtn [disabled]="!frmCreateExtension.valid" (click)="saveAndClose()">{{extensionEnum.extension_Create_saveAndClose_btn}}</button>
                    <button *ngIf="isCreateSolutionPackage" type="submit" mat-stroked-button (click)="saveOnNext()">{{extensionEnum.extension_Create_next_btn}}</button>
                    <button mat-stroked-button (click)="cancel()">{{extensionEnum.extension_View_cancel_btn}}</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="frmAddPackage" completed=false>
                <form [formGroup]="frmAddPackage" class="package-form">
                    <ng-template matStepLabel>{{packageLabel}} <br>
                        <h6>
                            <mat-hint class="info">{{extensionEnum.package_create_hint_message_provide}}
                            </mat-hint>
                        </h6>
                    </ng-template>
                    <div *ngIf="isaddPackage" class="form-wrapper">
                        <form [formGroup]="frmAddPackage">
                            <br>
                            <div class="pss-dashboard-form">
                                <div class="pss-left-panel">
                                <mat-form-field class="pss-block-form" appearance="fill">
                                    <mat-label>{{extensionEnum.package_Create_Field_packageName}}</mat-label>
                                    <input type="text" #packageNameinp maxlength="100" formControlName="name"
                                        [value]="extensionName" matInput placeholder="Extension Name" required>
                                    <mat-error *ngIf="frmAddPackage.controls['name'].invalid">{{extensionEnum.extension_package_create_error_message_name}}</mat-error>
                                    <mat-hint class="idn-mat-hint">
                                        <span class="material-icons">info</span>
                                        <span class="message">{{extensionEnum.package_create_hint_message_packageProvide}}</span>
                                    </mat-hint>   
                                </mat-form-field>
                                <br>
    
                                <mat-form-field class="pss-block-form" *ngIf="linkageAndQueueForUpgradeFeature && !binaryPackage">
                                    <mat-label>Linked Package</mat-label>
                                    <mat-select [(ngModel)]="linkedPackage" [ngModelOptions]="{standalone: true}" [disabled]="disableLinkedPackage" disableOptionCentering>
                                        <mat-option>
                                            <ngx-mat-select-search placeholderLabel="Search Package"
                                                noEntriesFoundLabel="No data found" [formControl]="packageNameFilterCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let list of packageList" [value]="list.id">
                                            {{list.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
    
                                <div *ngIf="binaryPackageUpload">
                                    <mat-slide-toggle color="primary" aria-label="binary package" (click)="$event.stopPropagation();"
                            (change)="toggleBinaryPackage($event)" >{{binaryPackage? extensionEnum.package_lable_binary_package : extensionEnum.package_lable_solution_package}}</mat-slide-toggle>
                                    <br><br>
                                    <div *ngIf="binaryPackage">
                                        <h2>ICI Version</h2>
                                        <p>{{extensionEnum.package_label_create_iciversion}}{{minimumICIVersionForBinaryPackage}}.</p>
                                        <div class="idn-version" *ngIf="binaryPackage">
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{extensionEnum.package_Create_Field_major}}</mat-label>
                                                <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                    formControlName="icimajor" required>
                                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                    (click)="increaseVersion('icimajor')">
                                                    <mat-icon class="idn-version-button">add</mat-icon>
                                                </button>
                                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                    (click)="decreaseVersion('icimajor')">
                                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{extensionEnum.package_Create_Field_minor}}</mat-label>
                                                <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                    formControlName="iciminor" required>
                                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                    (click)="increaseVersion('iciminor')">
                                                    <mat-icon class="idn-version-button">add</mat-icon>
                                                </button>
                                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                    (click)="decreaseVersion('iciminor')">
                                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{extensionEnum.package_Create_Field_patch}}</mat-label>
                                                <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                    formControlName="icipatch" required>
                                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                    (click)="increaseVersion('icipatch')">
                                                    <mat-icon class="idn-version-button">add</mat-icon>
                                                </button>
                                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                    (click)="decreaseVersion('icipatch')">
                                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                                </button>
                                            </mat-form-field>
                                            <mat-form-field class="example-full-width">
                                                <mat-label>{{extensionEnum.package_Create_Field_build}}</mat-label>
                                                <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                    formControlName="icibuild" required>
                                                <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                    (click)="increaseVersion('icibuild')">
                                                    <mat-icon class="idn-version-button">add</mat-icon>
                                                </button>
                                                <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                    (click)="decreaseVersion('icibuild')">
                                                    <mat-icon class="idn-version-button">remove</mat-icon>
                                                </button>
                                            </mat-form-field>
                                        </div>
                                        <mat-error *ngIf="validateMinimumICIVersionForBinaryPackage()">{{extensionEnum.iciversion_error_message_binary_package_p1}} {{minimumICIVersionForBinaryPackage}} {{extensionEnum.iciversion_error_message_binary_package_p2}}</mat-error>
                                    </div>
                                </div>
                                    <h2>{{extensionEnum.package_Create_Field_packageVersion}}</h2>
                                    <p>{{extensionEnum.package_label_create}}
                                    </p>
                                    <div class="idn-version">
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_major}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="major" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                (click)="increaseValue('major')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                (click)="decreaseValue('major')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_minor}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="minor" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                (click)="increaseValue('minor')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                (click)="decreaseValue('minor')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_patch}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="patch" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                (click)="increaseValue('patch')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                (click)="decreaseValue('patch')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="example-full-width">
                                            <mat-label>{{extensionEnum.package_Create_Field_build}}</mat-label>
                                            <input matInput placeholder=">=0" min="0"  type="number" step="any"
                                                formControlName="build" required>
                                            <button type="button" matSuffix mat-icon-button aria-label="Incerement"
                                                (click)="increaseValue('build')">
                                                <mat-icon class="idn-version-button">add</mat-icon>
                                            </button>
                                            <button type="button" matSuffix mat-icon-button aria-label="Decrement"
                                                (click)="decreaseValue('build')">
                                                <mat-icon class="idn-version-button">remove</mat-icon>
                                            </button>
                                        </mat-form-field>
                                    </div>
    
                                    <mat-form-field class="pss-block-form">
                                        <mat-label>{{extensionEnum.package_Create_Field_description}}</mat-label>
                                        <textarea type="text" maxlength="500" formControlName="description" matInput
                                            placeholder="Description" height="30"  [(ngModel)]="myInputValue" (ngModelChange)="valueChange($event)"required></textarea>
                                        <mat-error *ngIf="frmAddPackage.controls.description.invalid && frmAddPackage.controls.description.touched">{{ errorDescriptionName }}</mat-error>
                                    </mat-form-field>
    
                                    <mat-form-field class="pss-block-form">
                                        <mat-label>{{extensionEnum.package_Create_Field_supportedFiles}}</mat-label>
                                        <mat-toolbar class="idn-upload-toolbar">
                                            <input matInput [(ngModel)]="packageFileAttr"
                                                [ngModelOptions]="{standalone: true}" readonly name="name" required />
                                            <button mat-flat-button color="primary" class="idn-file-upload">{{extensionEnum.package_Create_browse_btn}}</button>
                                        </mat-toolbar>
                                        <input type="file" id="uploadFile1" #fileInput accept=".zip" name="uploadFile1" (change)="uploadPackageEvt($event)" aria-label="uploadFile1"/>
                                            <mat-hint class="idn-mat-hint">
                                              <span class="material-icons">info</span>
                                              <span class="message">{{extensionEnum.package_create_hint_message_filelimit}}</span>
                                            </mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="pss-right-panel">
                                    <mat-form-field class="pss-block-form">
                                        <mat-label>Package Version</mat-label>
                                        <input type="text" maxlength="25" [(ngModel)]="versionSuffix" [ngModelOptions]="{standalone: true}"
                                            [value]="versionSuffix" matInput placeholder="Package Version" readonly [disabled]="true">
                                    </mat-form-field>
                                </div>
                            </div>
                            
                        </form>
                    </div>
                    <div class="idn-no-result" *ngIf="!isaddPackage">
                        <mat-icon class="icon">inventory_2</mat-icon>
                        <h4>{{extensionEnum.package_create_label_message_noPackageUpload}}</h4>
                        <button mat-stroked-button color="accent" class="idn-primary-button" (click)="addPackage()">
                            <mat-icon>add_circle_outline</mat-icon>
                            {{extensionEnum.package_Create_addNewPackage_btn}}
                        </button>
                    </div>
                </form>
                <div class="pss-form-button-wrapper">
                    <button mat-stroked-button color="primary" [disabled]="isPrevDisable" (click)="previousStepper()">{{extensionEnum.package_Create_previous_btn}}</button>
                    <button mat-raised-button color="primary" [disabled]="!isaddPackage || !frmAddPackage.valid || validateMinimumICIVersionForBinaryPackage()" type="submit"
                        (click)="savePackageAndClose()">{{extensionEnum.package_Create_saveAndClose_btn}}</button>
                    <button *ngIf="false"  mat-stroked-button color="primary" type="submit"
                        (click)="savePackageAndNext()">{{extensionEnum.package_Create_saveAndNext_btn}}</button>
                    <button mat-stroked-button (click)="cancelPackage()">{{extensionEnum.package_Create_cancel_btn}}</button>
                </div>
            </mat-step>
            <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable">
                <form [formGroup]="thirdFormGroup">
                    <ng-template matStepLabel>{{certificationLabel}}</ng-template>
                    <mat-form-field>
                        <mat-label>Address</mat-label>
                        <input matInput formControlName="thirdCtrl" placeholder="Ex. 1 Main St, New York, NY" required>
                    </mat-form-field>
                    <div>
                        <button mat-button matStepperPrevious>Back</button>
                        <button mat-button matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>
    </div>
</div>
