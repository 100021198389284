import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'idn-app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit{
  
  @Input()custData!:any;
  @Output() cardClicked: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit(): void {  }
  filterCustomer(event:any){
    this.cardClicked.emit(event);
  }
}
