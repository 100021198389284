import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { Router } from '@angular/router';
import { Subscription, take } from 'rxjs';
import { CertificationsService } from 'src/app/core/services/certifications-management/certifications.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { ExtensionManagement } from 'src/app/shared/enum/extension-management';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
    <defs>
      <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
        <feOffset dy="8" input="SourceAlpha"/>
        <feGaussianBlur stdDeviation="5" result="blur"/>
        <feFlood flood-opacity="0"/>
        <feComposite operator="in" in2="blur"/>
        <feComposite in="SourceGraphic"/>
      </filter>
    </defs>
    <g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
      <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
        <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
      </g>
      <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
    </g>
    </svg>`
@Component({
  selector: 'app-view-extension',
  templateUrl: './view-extension.component.html',
  styleUrls: ['./view-extension.component.scss']
})
export class ViewExtensionComponent implements OnInit {
  showTab: any;
  editIndex = 0;
  extDetails: any;
  displayCertificationDetails: Boolean = false;
  @Output()
  selectedIndexChange!: EventEmitter<number>;
  extensionTabIndex = 0;
  redirectToCertificationsListSubscription!: Subscription;

  isViewExtension:any;
  isLockExtensionPermission:any;
  isUnLockExtensionPermission:any;
  isUpdateExtensionPermission:any;
  isCreateExtensionPermission:any;
  isViewSolutionPackagePermission:any;
  isCreateSolutionPackage:any;
  isEditSOlutionPackagePermission:any;
  isLockSOlutionPackagePermission:any;
  isUnLockSOlutionPackagePermission:any;
  isViewCertificationPermission:any;
  showEditButton = false;
  public extensionEnum = ExtensionManagement;

  breadCrumbItems: any = [
    { label: "Home", path: "resources" },
    { label: "Development and Utilities", path: "developmentandutilities/extension-management/" },
    { label: "Extension Management", path: "developmentandutilities/extension-management/" },
    { label: 'Extension Details', path: "developmentandutilities/extension-management/view" },
    { label: "View", path: "developmentandutilities/extension-management/view" },
  ];
  showAddPackageButton = false;
  redirectFromCustomerView: boolean =false;
  constructor(
    private extensionservice: ExtensionService,
    private certificationsService: CertificationsService,
    private notificationService: NotificationService,
    private router: Router,
    private cd: ChangeDetectorRef,
    private dialogService: DialogService,    
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
    private permissionService: PermissionsService) {
      iconRegistry.addSvgIconLiteral(
        'HELP_ICON',
        sanitizer.bypassSecurityTrustHtml(HELP_ICON)
      );
    this.showTab = 'false';
  }

  // selectedTabOnLoad: any;

  ngOnInit(): void {
    this.isViewExtension = this.permissionService.getPermission("ViewExtension");
    this.isLockExtensionPermission = this.permissionService.getPermission("LockExtension");
    this.isUpdateExtensionPermission = this.permissionService.getPermission("UpdateExtension");
    this.isUnLockExtensionPermission = this.permissionService.getPermission("UnLockExtension");
    this.isCreateExtensionPermission = this.permissionService.getPermission("CreateExtension");
    this.isViewSolutionPackagePermission = this.permissionService.getPermission("ViewSolutionPackage");
    this.isCreateSolutionPackage = this.permissionService.getPermission("CreateSolutionPackage");
    this.isEditSOlutionPackagePermission = this.permissionService.getPermission("EditSolutionPackage");
    this.isLockSOlutionPackagePermission = this.permissionService.getPermission("LockSolutionPackage");
    this.isUnLockSOlutionPackagePermission = this.permissionService.getPermission("UnlockSolutionPackage");
    this.isViewCertificationPermission = this.permissionService.getPermission("ViewCertification");
    this.extensionservice.onExtDetails<any>().pipe(take(1)).subscribe(
      data => {
        if (data != null && data != "") {
          localStorage.setItem("extDetails", JSON.stringify(data));
          localStorage.setItem("extId", data.id);
          this.extensionservice.getExtensionById(data.id).subscribe(res => {
            this.extDetails = res;
            if(data.hasOwnProperty('fromCustomerPcakageDeployedSummary') && data?.fromCustomerPcakageDeployedSummary){
              localStorage.setItem("extDetails", JSON.stringify(this.extDetails));
              let tabDetails: any[] = [1,"Packages"];
              localStorage.setItem("extension-tab-index", JSON.stringify(tabDetails));
              let packageData={
                id: data?.solutionPackageId
              }
              this.redirectFromCustomerView = true
              localStorage.setItem('fromViewPackage', JSON.stringify(packageData));
              this.extensionTabIndex = 1;
              this.showTab = 'true';
              this.showAddPackageButton = false;
              this.showEditButton = (this.showEditButton || this.editIndex == 0) && (this.extDetails.status == 2 ? false : true) && (this.isUpdateExtensionPermission == true ? true : false);
            }
          });
          if (data.hasOwnProperty('fromCreatePackage') && data.fromCreatePackage) {
            this.editIndex = 1;
            this.extensionTabIndex = 1;
            this.showAddPackageButton = true;
          }
        } else if (data == "") {
          let localData: any = localStorage.getItem("extDetails");
          data = JSON.parse(localData);
          this.extensionservice.getExtensionById(data.id).subscribe(res => {
            this.extDetails = res;
          });
          if (data.hasOwnProperty('fromCreatePackage') && data.fromCreatePackage) {
            this.editIndex = 1;
            this.extensionTabIndex = 1;
            this.showAddPackageButton = true;
          }
        }
      }
    )
    this.redirectToCertificationsListSubscription = this.certificationsService._certificationList.subscribe(data => {
      if (data == "redirectToCertificationList") {
        setTimeout(() => this.extensionTabIndex = 2, 1000);
      } else if(data == "redirectToCertificationListFromDetails") {
        setTimeout(() => this.extensionTabIndex = 2, 1000);
        this.displayCertificationDetails = false;
      }
    });
    this.selectedTabIndex = JSON.parse(localStorage.getItem('extension-tab-index')!);
    if(this.selectedTabIndex) {
      if(this.selectedTabIndex[0] == 0){
        this.extensionTabIndex = 0;
        this.breadCrumbItems[2].label = this.selectedTabIndex[1];
        this.showAddPackageButton = false;
      } else if(this.selectedTabIndex[0] == 1) {
        this.extensionTabIndex = 1;
        this.breadCrumbItems[2].label = this.selectedTabIndex[1];
        this.showAddPackageButton = true;
      } else if(this.selectedTabIndex[0] == 2) {
        this.extensionTabIndex = 2;
        this.breadCrumbItems[2].label = this.selectedTabIndex[1];
        this.showAddPackageButton = false;
      } else {
        this.extensionTabIndex = 0;
        this.breadCrumbItems[2].label = this.selectedTabIndex[1];
        this.showAddPackageButton = false;
      }
    }
  }

  ParentComponent(data: any) {
    this.showTab = data.showTab.toString();
    this.showAddPackageButton = data.showPackageButton;
    this.showEditButton = data.showEditButton;
    this.cd.detectChanges();
  }

  certificationDetails(event: any) {
    this.displayCertificationDetails = event;
  }

  myTabAnimationDone() {
    this.showTab = this.redirectFromCustomerView? "true":"false";
  }

  addPackage(extDetails: any) {
    if (extDetails.locked == true) {
      this.notificationService.showNotification(this.extensionEnum.package_notify_packageNotAddedExtLocked, 'warning', this.extensionEnum.extension_notify_extAlreadyLocked_heading);
    } else {
      extDetails["fromPackageList"] = true;
      localStorage.setItem("extIdFromPckgList", JSON.stringify(extDetails));
      localStorage.setItem("frmPackageToCreateExt", "true");
      let navLink = 'home/developmentandutilities/extension-management/create'
      this.router.navigate([navLink]);
    }
  }

  selectedTabIndex: any;
  //To capture change tab event
  onTabChanged($event: any) {
    this.breadCrumbItems[3].label = $event.tab.textLabel;
    let clickedIndex = $event.index;
    this.editIndex = $event.index;
    if (clickedIndex == 1) {
      this.showAddPackageButton = true;
    } else {
      this.showAddPackageButton = false;
      this.editIndex = $event.index;
      this.showEditButton = false;
    }
    this.displayCertificationDetails = false;
    this.certificationsService.certificationList('');
    this.selectedTabIndex = clickedIndex;
    let tabDetails: any[] = [this.selectedTabIndex, $event.tab.textLabel];
    localStorage.setItem("extension-tab-index", JSON.stringify(tabDetails));
  }


  ngOnDestroy() {
    localStorage.setItem("extension-tab-index", '0');
    if (this.redirectToCertificationsListSubscription) {
      this.redirectToCertificationsListSubscription.unsubscribe();
    }
  }

  redirectToHelp() {
    this.dialogService
      .confirmDialog({
        title: this.extensionEnum.redirect_dialog_heading,
        module: 'resources',
        message: this.extensionEnum.redirect_dialog_body,
        confirmText: this.extensionEnum.redirect_dialog_primary_btn,
        cancelText: this.extensionEnum.redirect_dialog_secondary_btn,
      })
      .subscribe((res: any) => {
        if (res) {
          let navLink;
          if(this.extensionTabIndex == 0){
            navLink ='https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#0-toc-title'
          } else if(this.extensionTabIndex == 1){
            navLink ='https://developer-help.icertis.com/docs/managing-extensions-and-deploying-packages/#upload-package'
          } else {
            navLink= 'https://developer-help.icertis.com/docs/managing-certifications-and-releasing-packages/#0-toc-title'
          }
          window.open(
            navLink,
            '_blank'
          );
        }
      });
  }

}
