import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';

export interface TableFormat {
  id: number;
  eventTaskInterestName: string;
  eventTaskInterestTopicName: string;
  eventTaskInterestSubscriptionName:string;
  eventTaskInterestParellelWorkers:boolean;
  eventTaskInterestFilter:string;
}

export interface TableFormat1 {
  id: number;
  taskSettingName: string;
  taskSettingIsEncrypted:boolean;
}

@Component({
  selector: 'app-ici-workertask-configuration',
  templateUrl: './ici-workertask-configuration.component.html',
  styleUrls: ['./ici-workertask-configuration.component.scss'],
})
export class IciWorkertaskConfigurationComponent implements OnInit {
  breadCrumbItems: any;
  instanceId: any = 0;
  ViewData: any = [];
  public instanceEnum = InstanceManagement;
  selectedTaskId:any = null;
  selectTask = new FormControl('');
  Tasks: any = [
    { id: 1, name: 'Event Task' },
    { id: 2, name: 'Scheduled Task' },
  ];
  eventTaskId: any = '1';
  scheduledTaskId:  any = '2';
  public regex = InputPattern;
  regexName: any = new RegExp(this.regex.namePattern);
  finalInterestConfiguration:any =[];
  finalSettingConfiguration:any =[];
  interestConfiguration :any = [
    {
      id: 0,
      eventTaskInterestName: '',
      eventTaskInterestTopicName: '',
      eventTaskInterestSubscriptionName: '',
      eventTaskInterestParellelWorkers: false,
      eventTaskInterestFilter: '',
    },
  ];
 settingConfiguration: any = [
    {
      id: 0,
      taskSettingName: '',
      taskSettingIsEncrypted: false
    },
  ];
  isDecrypted:boolean=false;
  dataSource: any = [];
  dataSource1: any = [];
  cronExpression:any;
  displayedColumns: string[] = [
    'Name',
    'Topic Name',
    'Subscription Name',
    'Parallel Workers',
    'Filter',
    'action',
  ];

  displayedColumns1: string[] = [
    'Name',
    'isEncrypted',
    'action',
  ];
  partnerId: any=0;
  customerId: any=0;

  constructor(
    private permissionService: PermissionsService,
    private instanceService: InstanceService,
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'ICI Worker Task Configuration', path: 'ici-workertask-configuration' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    this.dataSource = new MatTableDataSource<TableFormat>(this.interestConfiguration);
    this.dataSource1 = new MatTableDataSource<TableFormat1>(this.settingConfiguration);
  }

  frmTask: FormGroup = new FormGroup({
    id: new FormControl('', [
      Validators.required,
      Validators.pattern(/^[0-9]*$/),
    ]),
    name: new FormControl('', [Validators.pattern(this.regexName)]),
    type: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.pattern(this.regexName)]),
  });

  //Get all the instances
  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.partnerId = result.partnerId;
            this.customerId = result.customerId;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  selectedTask(taskid: any) {
    this.frmTask.reset();
    this.isDecrypted=false;
    this.cronExpression='';
    this.interestConfiguration = [
      {
        id: 0,
        eventTaskInterestName: '',
        eventTaskInterestTopicName: '',
        eventTaskInterestSubscriptionName: '',
        eventTaskInterestParellelWorkers: false,
        eventTaskInterestFilter: '',
      },
    ];
    this.settingConfiguration = [
      {
        id: 0,
        taskSettingName: '',
        taskSettingIsEncrypted: false
      },
    ];
    this.dataSource = new MatTableDataSource<TableFormat>(this.interestConfiguration);
    this.dataSource1 = new MatTableDataSource<TableFormat1>(this.settingConfiguration);
    this.selectedTaskId = taskid;
  }

  deleteRow(row: any) {
    this.interestConfiguration = this.interestConfiguration.filter((data: any) => {
      return data.id != row.id;
    });
    this.dataSource = new MatTableDataSource<TableFormat>(this.interestConfiguration);
  }

  deleteSettingConfigurationRow(row: any) {
    this.settingConfiguration = this.settingConfiguration.filter((data: any) => {
      return data.id != row.id;
    });
    this.dataSource1 = new MatTableDataSource<TableFormat1>(this.settingConfiguration);
  }

  addRow(row: any) {
    let index = row.id;
    let data = {
      id: index + 1,
      eventTaskInterestName: '',
      eventTaskInterestTopicName: '',
      eventTaskInterestSubscriptionName: '',
      eventTaskInterestParellelWorkers: false,
      eventTaskInterestFilter: '',
    };
    this.interestConfiguration.push(data);
    this.dataSource = new MatTableDataSource<TableFormat>(this.interestConfiguration);
  }

  addSettingConfigurationRow(row: any) {
    let index = row.id;
    let data = {
      id: index + 1,
      taskSettingName: '',
      taskSettingIsEncrypted: false
    };
    this.settingConfiguration.push(data);
    this.dataSource1 = new MatTableDataSource<TableFormat1>(this.settingConfiguration);
  }

  onSubmit(){
    let JsonInput:any = {};
    JsonInput.taskID = this.frmTask.controls['id'].value;
    JsonInput.taskName = this.frmTask.controls['name'].value;
    JsonInput.taskType = this.frmTask.controls['type'].value;
    JsonInput.taskDescription = this.frmTask.controls['description'].value;
    JsonInput.taskSetting = this.settingConfiguration.filter((row:any)=>{
      return (row.taskSettingName!='')
    });
    JsonInput.operation = 1;
    JsonInput.taskUseDecryption=this.isDecrypted;
    JsonInput.workerTaskType = this.selectedTaskId;
    if(this.selectedTaskId == 2){
      JsonInput.eventTaskInterest =[];
    }
    else{
      JsonInput.eventTaskInterest = this.interestConfiguration;
    }
    if(this.partnerId == null){
      this.partnerId="";
    }
    JsonInput.taskCronExpression=this.cronExpression;
    this.instanceService.createTask(JsonInput,this.instanceId,this.partnerId,this.customerId).subscribe((data)=>{
      if(data.body.isSuccess){
        this.notificationService.showNotification(this.instanceEnum.workertask_notify_task_created_body,'success',this.instanceEnum.workertask_notify_task_created_heading);
        this.router.navigate(['home/instanceoperations/instance-requests']);
      }else{
        this.notificationService.showNotification(this.instanceEnum.workertask_notify_task_created_failed_body,'error',this.instanceEnum.workertask_notify_task_created_failed_heading);
      }

    });
  }

  onCancel(){
    this.frmTask.reset();
    this.isDecrypted=false;
    this.cronExpression='';
    this.interestConfiguration = [
      {
        id: 0,
        eventTaskInterestName: '',
        eventTaskInterestTopicName: '',
        eventTaskInterestSubscriptionName: '',
        eventTaskInterestParellelWorkers: false,
        eventTaskInterestFilter: '',
      },
    ];
    this.settingConfiguration = [
      {
        id: 0,
        taskSettingName: '',
        taskSettingIsEncrypted: false
      },
    ];
    this.router.navigate(['home/instanceoperations/instance-requests']);
  }

  validateForm(){
    let validateInterest = this.interestConfiguration?.every((row:any)=>{
      return (row.eventTaskInterestName != '' && row.eventTaskInterestTopicName != '' && row.eventTaskInterestSubscriptionName != '')
    })
   if(this.frmTask.valid && (validateInterest || this.cronExpression != '')){
    return false
   }else{
    return true;
   }
  
  }
}
