<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [auditLogData]="'instance'" [mapRole]="true" [applyFilter]="false"
    [clearFilter]="false" [archive]="false" [breadCrumbs]="this.breadCrumbItems"></app-pageheader>
    <div class="page-container">
    <div class="idn-acc-wrapper">
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">{{instanceEnum.instance_card_header_non_idn_user_instance}}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">
    
                    <div class="idn-view-body-items" id= "custName">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_customerName}}
                        </div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.customerName}}">{{ViewData.customerName}}</div>
                    </div>
                    <div class="idn-view-body-items" id= "cName">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instance_List_initiateDeployment_customDrawer_Field_cName}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.cname}}" >{{ViewData.cname}}</div>
                    </div>
    
                    <div class="idn-view-body-items" id= "instType">
                        <div class="idn-view-body-items-label">
                            {{instanceEnum.instance_View_Field_type}}</div>
                        <div class="idn-view-body-items-data" matTooltip="{{ViewData.instanceType}}">{{ViewData.instanceType}}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    
        <mat-card class="idn-acc-list-card-1">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">
                    {{instanceEnum.instance_card_header_non_idn_user_instance_users}}
                </mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="acc-count-chips card-content">
                <span class="count-label">Provisioned Users</span>                
                <span class="count">{{provisionedUsers}}/{{userLimit}}</span>
            </mat-card-content>
        </mat-card>

        <mat-card class="idn-acc-list-card-2">
            <mat-card-content class="idn-view-body-description">
                <mat-icon>info</mat-icon>
                <div class="provision-desc">
                    <span *ngIf="!isInstanceUserProvision">To provision any of the below listed users on this ICI instance, raise a Service Now ticket. To add new users to the list, go to <b>User Management > Create</b> and add them. In order to appear in the list, users need to be mapped to the correct customer. To verify if a user is already provisioned or to check their status after raising the support ticket, see the <b>Provisioning Status</b> column.</span>
                    <span *ngIf="isInstanceUserProvision">To provision any of the below listed users on this ICI instance, click <b>Provision</b>. To add new users to the list, go to <b>User Management > Create</b> and add them. In order to appear in the list, users need to be mapped to the correct customer. To verify if a user is already provisioned, see the <b>Provisioning Status</b> column.</span>
                    <span class="note-desc"><b>Note:</b> You can provision up to {{userLimit}} users on one ICI instance.</span>
                </div>
                
            </mat-card-content>
        </mat-card>
    </div>
    <span class="table-heading">
        {{instanceEnum.instance_card_header_non_idn_user_instance_users}}
    </span>
    <div class="idn-filter">
        <form class="idn-grid-filter">
          <mat-form-field class="idn-grid-filter-search mat-form-field-prefix-custom">
    
            <mat-label>{{instanceEnum.provision_user_Filter_Search_label_searchInstance}}</mat-label>
            <input type="text" (keydown.enter)="doFilter()" #search_input matInput
              placeholder="Search by firstname, lastname and email address...">
            <span class="idn-custom-search" matSuffix>
              <mat-icon (click)="doFilter()">search</mat-icon>
              <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{instanceEnum.instance_Filter_label_status}}</mat-label>
            <mat-select (selectionChange)="doFilter()" #provisioningStatus>
              <mat-option [value]="selectAll"> All</mat-option>
              <mat-option value="true">Provisioned</mat-option>
              <mat-option value="false">Not Provisioned</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="icertisRole">
            <mat-label>{{instanceEnum.instance_Filter_label_user_category}}</mat-label>
            <mat-select (selectionChange)="doFilter()" #userCategory>
              <mat-option [value]="selectAll"> All</mat-option>
              <mat-option value="true">Icertis</mat-option>
              <mat-option value="false">Partner</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
        <button mat-stroked-button color="primary" (click)="clearFilter()">
          <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
          <span>Clear Filter</span>
        </button>
      </div>
    <div class="idn-table">
      
        <div class="table-scroll">
            <table mat-table style="width: 100%" [dataSource]="dataSource" class="mat-elevation-z8"  matSort #empTbSort="matSort"
            (matSortChange)="announceSortChange($event)" *ngIf="!noContent; else noContentTemplate">
                <!-- First name Column -->
                <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef  mat-sort-header sortActionDescription="Sort by firstName">
                        First Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.firstName}}">{{element.firstName}} </td>
                </ng-container>
                <!-- Last name Column -->
                <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by lastName">
                        Last Name
                    </th>
                    <td mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.lastName}}">{{element.lastName}} </td>
                </ng-container>
                <!-- Email Address Column -->
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by email" id ="row-width">
                        Email Address
                    </th>
                    <td  mat-cell *matCellDef="let element" class="overflow-data" matTooltip="{{element.email}}">{{element.email}} </td>
                </ng-container>
                <!-- Provisioning status Column -->
                <ng-container matColumnDef="isProvisionStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by provisionStatus">
                        Provisioning Status
                    </th>
                    <td mat-cell *matCellDef="let element" [ngClass]="{'provisioned': element.isProvisioned == true,'nonProvisioned': element.isProvisioned == false}">{{element.isProvisioned? "Provisioned": "Not Provisioned"}} </td>
                </ng-container>
                <ng-container matColumnDef="isCLMAdmin">
                  <th mat-header-cell *matHeaderCellDef id="row-width-clmAdmin">
                      CLM Admin
                  </th>
                  <td mat-cell *matCellDef="let element" [ngClass]="{'provisioned': element.isCLMAdmin == true}" matTooltip="{{element.isCLMAdmin?'Yes':'No'}}">{{element.isCLMAdmin? "Yes": "No"}} </td>
              </ng-container>
                <!--action Column -->
                <ng-container matColumnDef="action">
                  <th mat-header-cell *matHeaderCellDef class="action-col">
                    Action
                  </th>
                  <td mat-cell *matCellDef="let element" class="overflow-data" >
                    <button mat-raised-button color="primary" class="pss-approve-btn" (click)="Provision(element)" [disabled]="element.isProvisioned || !isInstanceUserProvision || (limitProvision && element.userCategoryId==2)">
                              <span class="req-approve-btn">Provision</span>
                          </button>
                          <button [matMenuTriggerFor]="gridMenu" class="more-action" mat-icon-button aria-label="Mark CLM Admin Action" matTooltip="User Actions" [disabled]="element.isCLMAdmin || element.isProvisioned ">
                            <mat-icon>more_horiz</mat-icon>
                          </button>
                          <mat-menu #gridMenu="matMenu" xPosition="after">
                            <button mat-menu-item (click)="makeCLMAdmin(element)" >
                              <mat-icon svgIcon="ClmAdmin_Icon">shield_person</mat-icon> Map as CLM Admin
                            </button>
                          </mat-menu>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
                <tr mat-row *matRowDef="let row; columns:displayedColumns;"></tr>
            </table>
        </div>
        <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="5" class="mat-paginator-sticky"
        (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]" showFirstLastButtons
        aria-label="Select page of user provision" [ngClass]="{'hide-pagination': noContent ? true : false}">
      </mat-paginator>
    </div>
      <div class="pss-form-button-wrapper">
        <button mat-flat-button
            (click)="onCancel()">{{instanceEnum.instance_List_initiateDeployment_customDrawerp1_field_cancel_btn}}</button>
    </div>
    </div>

    <ng-template #noContentTemplate>
        <div class="idn-no-result">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276" height="214.71" viewBox="0 0 203.276 214.71">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39" transform="translate(0 0)" fill="#515165"/>
              </clipPath>
            </defs>
            <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
              <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863" transform="translate(3187.267 7517.8)" fill="#7c7c97"/>
              <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863" transform="translate(3192.911 7517.8)" fill="#515165"/>
              <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774" transform="translate(3178.801 7551.663)" fill="#ababc5"/>
              <path id="Union_2" data-name="Union 2" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(3178.801 7617.272)" fill="#7c7c97"/>
              <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774" transform="translate(3192.911 7551.663)" fill="#7c7c97"/>
              <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
                <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264" transform="translate(0 0)" fill="#cfdae2"/>
                <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798" transform="translate(8.466 8.466)" fill="#f6f2f1"/>
              </g>
              <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)" clip-path="url(#clip-path)">
                <path id="Union_1" data-name="Union 1" d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z" transform="translate(-14.11 0)" fill="#515165"/>
              </g>
            </g>
          </svg>
          <h4>No Data found</h4>
        </div>
      </ng-template>
      