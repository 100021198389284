import { DatePipe } from '@angular/common';
import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { DialogService } from 'src/app/shared/dialog/confirm-dialog/dialog.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { Location } from '@angular/common';
import { MsalService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

export interface iciActionsTool {
  name: string
}

export interface ActionDescriptions {
  title: string,
  description: string
}

const noActionTaken = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="114.015" height="144.175" viewBox="0 0 114.015 144.175">
<defs>
  <linearGradient id="linear-gradient" x1="0.5" x2="0.48" y2="1.203" gradientUnits="objectBoundingBox">
    <stop offset="0" stop-color="#eaecf7"/>
    <stop offset="0.806" stop-color="#a1ace3"/>
    <stop offset="1" stop-color="#aeb7e7"/>
  </linearGradient>
  <linearGradient id="linear-gradient-2" x1="0.5" x2="0.5" y2="1.082" gradientUnits="objectBoundingBox">
    <stop offset="0" stop-color="#dd8657"/>
    <stop offset="1" stop-color="#a25234"/>
  </linearGradient>
</defs>
<g id="Group_684" data-name="Group 684" transform="translate(-694 -353.658)">
  <rect id="Rectangle_228" data-name="Rectangle 228" width="98" height="118" rx="3" transform="translate(694 368)" fill="url(#linear-gradient)"/>
  <path id="Line_80" data-name="Line 80" d="M26.255,4.4H1.449a2.949,2.949,0,0,1,0-5.9H26.255a2.949,2.949,0,0,1,0,5.9Z" transform="translate(711.975 403.318)" fill="#8491c1"/>
  <path id="Line_81" data-name="Line 81" d="M55.742,4.4H1.449a2.949,2.949,0,0,1,0-5.9H55.742a2.949,2.949,0,0,1,0,5.9Z" transform="translate(711.975 419.044)" fill="#8491c1"/>
  <path id="Line_82" data-name="Line 82" d="M33.686,4.4H1.449a2.949,2.949,0,1,1,0-5.9H33.686a2.949,2.949,0,0,1,0,5.9Z" transform="translate(711.975 434.77)" fill="#8491c1"/>
  <path id="Path_493" data-name="Path 493" d="M29.487,0A29.487,29.487,0,1,1,0,29.487,29.487,29.487,0,0,1,29.487,0Z" transform="translate(749.042 438.86)" fill="#ff6464"/>
  <path id="Icon_material-close" data-name="Icon material-close" d="M31.8,9.947,29.352,7.5l-9.7,9.7-9.7-9.7L7.5,9.947l9.7,9.7-9.7,9.7L9.947,31.8l9.7-9.7,9.7,9.7L31.8,29.352l-9.7-9.7Z" transform="translate(758.878 448.696)" fill="#fff"/>
  <path id="Union_2" data-name="Union 2" d="M1,21.624a1,1,0,0,1-1-1V8.864a1,1,0,0,1,1-1H15.794C16.392,3.441,20.975,0,26.54,0s10.148,3.44,10.746,7.863H52.078a1,1,0,0,1,1,1v11.76a1,1,0,0,1-1,1Z" transform="translate(716.604 353.657)" fill="url(#linear-gradient-2)"/>
</g>
</svg>`;

const HELP_ICON = `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="49" height="48" viewBox="0 0 49 48" >
<defs>
  <filter id="Ellipse_40" x="0" y="0" width="79" height="78" filterUnits="userSpaceOnUse">
    <feOffset dy="8" input="SourceAlpha"/>
    <feGaussianBlur stdDeviation="5" result="blur"/>
    <feFlood flood-opacity="0"/>
    <feComposite operator="in" in2="blur"/>
    <feComposite in="SourceGraphic"/>
  </filter>
</defs>
<g id="Component_308_1" data-name="Component 308 - 1" transform="translate(0 0)">
  <g transform="matrix(1, 0, 0, 1, -15, -7)" filter="url(#Ellipse_40)">
    <ellipse id="Ellipse_40-2" data-name="Ellipse 40" cx="24.5" cy="24" rx="24.5" ry="24" transform="translate(15 7)" />
  </g>
  <path id="help_FILL0_wght400_GRAD0_opsz48" d="M92.576,195.705a1.169,1.169,0,1,0-.84-.342A1.143,1.143,0,0,0,92.576,195.705Zm-1.09-4.545h1.837a5.1,5.1,0,0,1,.2-1.479,3.665,3.665,0,0,1,1.261-1.541,5.447,5.447,0,0,0,1.37-1.588,3.655,3.655,0,0,0,.4-1.712,3.437,3.437,0,0,0-1.074-2.646,4.022,4.022,0,0,0-2.848-1,4.812,4.812,0,0,0-2.693.763,4.356,4.356,0,0,0-1.7,2.1l1.65.623a2.9,2.9,0,0,1,1.027-1.354,2.738,2.738,0,0,1,1.619-.482,2.5,2.5,0,0,1,1.712.576,1.881,1.881,0,0,1,.654,1.479,2.289,2.289,0,0,1-.4,1.292,6.2,6.2,0,0,1-1.183,1.261,6.331,6.331,0,0,0-1.385,1.6A4.238,4.238,0,0,0,91.487,191.16Zm.965,9.744a12.048,12.048,0,0,1-4.825-.981,12.545,12.545,0,0,1-6.646-6.646,12.438,12.438,0,0,1,0-9.681,12.444,12.444,0,0,1,2.677-3.953,12.764,12.764,0,0,1,3.969-2.662,12.438,12.438,0,0,1,9.681,0,12.432,12.432,0,0,1,6.615,6.615,12.438,12.438,0,0,1,0,9.681,12.764,12.764,0,0,1-2.662,3.969,12.444,12.444,0,0,1-3.953,2.677A12.125,12.125,0,0,1,92.452,200.9Zm0-1.868a10.575,10.575,0,1,0-7.487-3.1A10.185,10.185,0,0,0,92.452,199.036ZM92.452,188.452Z" transform="translate(-66.869 -164.53)" fill="#fff"/>
</g>
</svg>`

const REQUEST_PENDING = `<svg xmlns="http://www.w3.org/2000/svg" width="170.927" height="153.658" viewBox="0 0 170.927 153.658">
  <g id="Group_866" data-name="Group 866" transform="translate(-645 -385.671)">
    <g id="Group_865" data-name="Group 865" transform="translate(644.999 385.671)">
      <path id="Path_560" data-name="Path 560" d="M521.491,470.339l-37.26.655a4.473,4.473,0,1,1-.157-8.945l37.26-.655a2.1,2.1,0,0,1,2.131,2.058l.084,4.756a2.1,2.1,0,0,1-1.918,2.124Q521.561,470.337,521.491,470.339Z" transform="translate(-434.059 -380.792)" fill="#6332a4"/>
      <path id="Path_561" data-name="Path 561" d="M517.393,469.305l-30.568,1.4a2.1,2.1,0,0,1-2.188-2l-.323-7.087a2.1,2.1,0,0,1,2-2.188l30.568-1.4a2.1,2.1,0,0,1,2.188,2l.323,7.087a2.1,2.1,0,0,1-1.918,2.183Z" transform="translate(-437.156 -378.543)" fill="#404041"/>
      <path id="Path_562" data-name="Path 562" d="M428.108,591.38V551.736a2.1,2.1,0,0,1,2.095-2.095h4.757a2.1,2.1,0,0,1,2.095,2.095V591.38a2.1,2.1,0,0,1-2.095,2.095H430.2A2.1,2.1,0,0,1,428.108,591.38Z" transform="translate(-399.565 -439.817)" fill="#6332a4"/>
      <path id="Path_563" data-name="Path 563" d="M465.8,591.38V551.736a2.1,2.1,0,0,1,2.095-2.095h4.757a2.1,2.1,0,0,1,2.095,2.095V591.38a2.1,2.1,0,0,1-2.095,2.095H467.9a2.1,2.1,0,0,1-2.095-2.095Z" transform="translate(-424.777 -439.817)" fill="#6332a4"/>
      <circle id="Ellipse_32" data-name="Ellipse 32" cx="16.436" cy="16.436" r="16.436" transform="translate(25.832 35.581)" fill="#6332a4"/>
      <path id="Path_564" data-name="Path 564" d="M492.469,370.38a3.9,3.9,0,0,0,1.423-.836,2.621,2.621,0,0,0,.766-2.075,1.763,1.763,0,0,0-.784-1.357,2.28,2.28,0,0,0-2.174-.025l.03-6.416-.645,0-.036,7.543.5-.312a1.952,1.952,0,0,1,1.979-.243,1.132,1.132,0,0,1,.49.876,1.981,1.981,0,0,1-.57,1.547,5.328,5.328,0,0,1-2.934,1.082l.1.636A10.621,10.621,0,0,0,492.469,370.38Z" transform="translate(-441.305 -312.752)" fill="#fff"/>
      <rect id="Rectangle_331" data-name="Rectangle 331" width="3.471" height="0.644" transform="translate(53.628 47.048) rotate(-7.523)" fill="#fff"/>
      <rect id="Rectangle_332" data-name="Rectangle 332" width="3.471" height="0.644" transform="translate(42.766 48.482) rotate(-7.523)" fill="#fff"/>
      <path id="Path_565" data-name="Path 565" d="M349.312,369.6l23.6,28.842a4.473,4.473,0,0,1-6.924,5.665l-23.6-28.842a2.1,2.1,0,0,1,.295-2.948l3.681-3.012A2.1,2.1,0,0,1,349.312,369.6Z" transform="translate(-341.914 -318.881)" fill="#6332a4"/>
      <path id="Path_566" data-name="Path 566" d="M367.816,389.039l19.377,23.683a2.1,2.1,0,0,1-.295,2.948l-6.288,5.145a2.1,2.1,0,0,1-2.948-.295l-19.377-23.683a2.1,2.1,0,0,1,.295-2.948l6.288-5.145a2.1,2.1,0,0,1,2.948.295Z" transform="translate(-352.548 -331.883)" fill="#404041"/>
      <path id="Path_336" data-name="Path 336" d="M666.62,299.787a3.122,3.122,0,0,0-2.855-1.91H606.784a3.09,3.09,0,0,0-3.089,3.089v72.086a3.09,3.09,0,0,0,3.089,3.089h56.983a3.093,3.093,0,0,0,3.089-3.089V300.966a3.034,3.034,0,0,0-.234-1.179Zm-.452,73.265a2.406,2.406,0,0,1-2.4,2.4H606.784a2.4,2.4,0,0,1-2.4-2.4V300.966a2.406,2.406,0,0,1,2.4-2.4h56.983a2.415,2.415,0,0,1,2.23,1.512,2.66,2.66,0,0,1,.09.271,2.381,2.381,0,0,1,.082.62Z" transform="translate(-517.007 -271.423)" fill="#3f3d56"/>
      <path id="Path_337" data-name="Path 337" d="M720.366,343.725H703.2a1.373,1.373,0,0,1,0-2.746h17.164a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-582.645 -300.252)" fill="#3f3d56"/>
      <path id="Path_338" data-name="Path 338" d="M720.366,365.494H703.2a1.373,1.373,0,0,1,0-2.746h17.164a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-582.645 -314.812)" fill="#3f3d56"/>
      <path id="Path_339" data-name="Path 339" d="M650.928,347.3H638.385a1.546,1.546,0,0,1-1.545-1.545v-14.8a1.546,1.546,0,0,1,1.545-1.545h12.543a1.546,1.546,0,0,1,1.545,1.545v14.8A1.546,1.546,0,0,1,650.928,347.3Z" transform="translate(-539.176 -292.513)" fill="#404041"/>
      <path id="Path_340" data-name="Path 340" d="M676.326,410.069H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-538.724 -344.626)" fill="#ccc"/>
      <path id="Path_341" data-name="Path 341" d="M676.326,431.838H637.537a1.373,1.373,0,1,1,0-2.746h38.789a1.373,1.373,0,1,1,0,2.746Z" transform="translate(-538.724 -359.187)" fill="#ccc"/>
      <path id="Path_342" data-name="Path 342" d="M676.326,453.606H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,1,1,0,2.746Z" transform="translate(-538.724 -373.746)" fill="#ccc"/>
      <path id="Path_343" data-name="Path 343" d="M676.326,475.375H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-538.724 -388.307)" fill="#ccc"/>
      <path id="Path_344" data-name="Path 344" d="M676.326,497.144H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-538.724 -402.867)" fill="#ccc"/>
      <path id="Ellipse_44" data-name="Ellipse 44" d="M19.657,0A19.657,19.657,0,1,1,0,19.657,19.657,19.657,0,0,1,19.657,0Z" transform="translate(131.613 0)" fill="#404041"/>
      <path id="Path_568" data-name="Path 568" d="M404.568,299.157c2.943-1.935,6.641-1.064,9.912-.811,2.955.228,6.458,0,8.537-2.411a7.418,7.418,0,0,0,.191-8.9,11.265,11.265,0,0,0-7.782-4.7,12.485,12.485,0,0,0-9.7,2.679l1.052.436a14.66,14.66,0,0,0-10.7-12.594,14.282,14.282,0,0,0-15.509,6.242c-3.076,5.141-2.712,12.314,1.716,16.586a9.019,9.019,0,0,0,7.954,2.539,6.853,6.853,0,0,0,5.619-5.275c.47-2.583-.556-5.518-3.069-6.646a5.01,5.01,0,0,0-4.379.07,4.257,4.257,0,0,0-2.159,3.405,3.763,3.763,0,0,0,.4,1.966c.349.712,1.412.088,1.064-.622a2.857,2.857,0,0,1,.59-3.136,3.468,3.468,0,0,1,3.569-.723c2.221.714,3.2,3.262,2.783,5.423a5.709,5.709,0,0,1-4.889,4.375,7.825,7.825,0,0,1-6.831-2.465c-3.721-3.806-4.043-9.937-1.533-14.48a12.965,12.965,0,0,1,13.15-6.31,13.321,13.321,0,0,1,10.641,9.749,12.487,12.487,0,0,1,.351,1.895.626.626,0,0,0,1.052.436c4.371-3.63,11.641-3.241,15.225,1.326,1.758,2.24,2.276,5.718.223,7.967-1.954,2.142-5.2,2.144-7.869,1.91-3.415-.3-7.169-1.006-10.227,1.005-.66.434-.043,1.5.622,1.064Z" transform="translate(-366.42 -254.412)" fill="#2f2e41"/>
      <path id="Path_569" data-name="Path 569" d="M443.621,573.655H418.977a1.851,1.851,0,0,1-1.848-1.848v-8.215a1.85,1.85,0,0,1,1.848-1.848h24.645a1.85,1.85,0,0,1,1.848,1.848v8.215A1.85,1.85,0,0,1,443.621,573.655Z" transform="translate(-392.221 -447.911)" fill="#404041"/>
      <path id="Path_570" data-name="Path 570" d="M439.436,449.775a11.22,11.22,0,0,0-7.658-8.356c1.4-.452,2.3-1.121,2.3-1.87,0-1.361-2.942-2.465-6.572-2.465s-6.572,1.1-6.572,2.465c0,.759.917,1.438,2.358,1.89C417.327,443.218,413,448.407,413,454.519c0,0-1.836,11.767-3.277,21.174-.922,6.018,4.116,11.4,10.672,11.4h23.059a2.648,2.648,0,0,0,2.739-2.54S441.943,462.594,439.436,449.775Z" transform="translate(-387.194 -364.533)" fill="#404041"/>
    </g>
    <path id="hourglass_top_24dp_FILL0_wght400_GRAD0_opsz24" d="M164.815-858.332h9.63v-3.611a4.636,4.636,0,0,0-1.414-3.4,4.637,4.637,0,0,0-3.4-1.414,4.637,4.637,0,0,0-3.4,1.414,4.637,4.637,0,0,0-1.414,3.4ZM160-855.925v-2.407h2.408v-3.611a7.22,7.22,0,0,1,.858-3.446,6.708,6.708,0,0,1,2.392-2.573,6.708,6.708,0,0,1-2.392-2.573,7.22,7.22,0,0,1-.858-3.446v-3.611H160V-880h19.26v2.408h-2.408v3.611a7.22,7.22,0,0,1-.858,3.446,6.708,6.708,0,0,1-2.392,2.573,6.708,6.708,0,0,1,2.392,2.573,7.22,7.22,0,0,1,.858,3.446v3.611h2.408v2.407Z" transform="translate(626 1273.118)" fill="#fff"/>
    <path id="Polygon_1" data-name="Polygon 1" d="M2.5,0,5,5H0Z" transform="translate(798.059 411) rotate(180)" fill="#fff"/>
  </g>
</svg>
`

@Component({
  selector: 'app-instance-tools',
  templateUrl: './instance-tools.component.html',
  styleUrls: ['./instance-tools.component.scss']
})
export class InstanceToolsComponent implements OnInit {
  breadCrumbItems: any;
  ViewData: any = [];
  instanceIciVersion:any;
  compareVersion :any = '8.4.0.0';
  compareIciversion:number=0;
  instanceId:any;
  selectedValue: string = "";
  iciActions: iciActionsTool[] = [];
  Description: ActionDescriptions[] = [];
  isViewICIToolLogActions: any;
  isViewICIToolWriteActions: any;
  selectdbtable: any = [];
  selectdbvalue: string = "";
  instanceEsignAccounts:any =[];
  appStartDate: any;
  appEndDate: any;
  getStartValueappLog: any;
  workerTaskLogDate:any;
  getEndValueappLog: any;
  getStartValuedbLog: any;
  getEndValuedbLog: any;
  start: any;
  end: any;
  getdate = new Date();
  OnInitendDate = new Date();
  getCurrentdate: any;
  currselIcon: any;
  minAppStartDate: any;
  maxAppStartDate: any;
  maxAppEndDate: any;
  minAppEndDate: any;
  workertaskMaxDate: any;
  currentMonth: any;
  currAction: any;
  statusId: any;
  handleError: any;
  confirmActionBtn: any;
  getStartTimeAppLog: any;
  responseType: any;
  startDate = new Date();
  endDate = new Date();
  setApplicationMinEndDate = new Date();
  setDatabaseMinEndDate = new Date();
  getEndTimeAppLog: any;
  paramreqdata: any = {};
  getSearchValueappLog: string = "";
  validateConfirmAction: boolean = true;
  validateSelectAllAddIn: boolean = true;
  addInRole: boolean = false;
  blob: any;
  startDateError: boolean = false;
  startDateErrorMsg: string = "";
  endDateError: boolean = false;
  endDateErrorMsg: string = "";
  result: any;
  minWorkerTaskDate: any = new Date();
  dbResult: any;
  isViewLogAction: boolean = false;
  responseMessage_roleRestart:string="";
  responseMessage_taskRestart:string="";
  responseMessage_cacheFlush:string="";
  validWorkerTaskLogDate: boolean = false;
  validDateMessage:any;
  showError:boolean =false;
  showEsignError:boolean =false;
  actionName:string = "";
  public instanceEnum = InstanceManagement;
  selectdbtablenames:any = [];
  $subs!: Subscription;
  columnFilterCtrldbtable =new FormControl('');
  hideDateTime:boolean=false;
  backup: any = {
    name: 'Select All',
    completed: false,
    color: 'primary',
    subtasks: [
      {name: 'Database Backup', completed: false, color: 'primary'},
      {name: 'Document Storage Backup', completed: false, color: 'primary'},
      {name: 'Elasticsearch Backup', completed: false, color: 'primary'},
    ],
  };
  allComplete: boolean = false;
  instanceBackupData = {
    instanceType: "MT",
    email: "",
    AllBackup: false,
    Database: false,
    Store: false,
    ES: false
  }
  instanceEsignData:any={
    title: "Instance Details",
    records:[]
  }
  AddInRoles: any = {
    name: 'All',
    completed: false,
    color: 'primary',
    addIns: [
      {name: 'Icertis Experience for Microsoft Outlook', completed: false, color: 'primary'},
      {name: 'Icertis Experience for Microsoft Word', completed: false, color: 'primary'},
      {name: 'Hangfire', completed: false, color: 'primary'},
    ],
  };
  allAddInRoles: boolean = false;
  selectedAddInValues: any = [];
  selectedEsignAccount: any;
  comments:any='';
  isRequestAccess:boolean = true;
  isRequestPending:boolean = false;
  currentUser:any = '';
  approvalStatus: any ={
    Pending: 1,
    Approved: 2,
    Rejected: 3,
  };
  requestStatus:any;
  isRequestRejected:boolean = false;
  activeAccount: any;
  featureCategoryId:any;
  hangfireDashBoardRequestDetails: any;
  constructor
  (
    private notificationService: NotificationService,
    private instanceService: InstanceService,
    private dialogService: DialogService,
    iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private datePipe: DatePipe,
    private permissionService: PermissionsService,
    private router: Router,
    private location: Location,
    private msalService: MsalService,
  ){
    iconRegistry.addSvgIconLiteral('no_actionTaken_icon', sanitizer.bypassSecurityTrustHtml(noActionTaken));
    iconRegistry.addSvgIconLiteral('HELP_ICON', sanitizer.bypassSecurityTrustHtml(HELP_ICON));
    iconRegistry.addSvgIconLiteral(
      'request_pending',
      sanitizer.bypassSecurityTrustHtml(REQUEST_PENDING)
    );
  } 
  

  ngOnInit(): void {
    this.currentUser = this.permissionService.getUniqueUserid();
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
      { label: 'Instance Tools', path: 'instance-tools' },
    ];
     this.instanceId = localStorage.getItem('instanceID');
     this.getInstanceByID();
     document.cookie = 'HangFireCookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;'
     this.$subs = this.instanceService.receiveData().subscribe(data => {
      if(data){
        localStorage.setItem('action', data);
        let tool = JSON.parse(data);
        if(tool.id != undefined && tool.id != null && tool.path == 'landingPage' ){
          this.selectedValue = tool.name;
          this.onActionChange(tool);
        } 
       
      } 
      else{
       let action:any =  localStorage.getItem('action');
       action = JSON.parse(action);
       this.selectedValue = action.name;
       this.onActionChange(action);
      }
    });
    this.selectdbtable = ['Generic Log Information', 'Publish Exception Log', 'Task History','Generic Integration Audit Log','Generic Integration FileBased Staging Log','Generic Integration Messaging Staging Log','Instance Lock', 'ESign Document Information', 'Integration Inbox','Worker Task','Task Event','Interest','Setting','Action Request','ICM Elastic Search','Contract Type Publish Activity','Contract Type Publish Summary','Entity Event Audit','Task','Rule Set Info', 'Client App Error Mapping','ICM Integration Event Log','Legacy Global Settings','Legacy Errors Log','SCIM Request Log', 'Business API Errors Log', 'Recurring Execution','Inbox Status','Rule Result', 'ICM Bulk Create Log'];
    this.selectdbtablenames = this.selectdbtable?.sort((a:any, b:any) =>{ return a.localeCompare(b);});
    this.columnFilterCtrldbtable.valueChanges.subscribe((value:any) => {
      this.columnFilterCtrldbtablename(value);
    });
    this.isViewICIToolLogActions = this.permissionService.getPermission("LoggingUtilities");
    this.isViewICIToolWriteActions = this.permissionService.getPermission("WriteUtilities");
    //datetime Validation
    this.getdate.setDate(this.getdate.getDate() - 1);
    this.startDate.setDate(this.startDate.getDate() - 30);
    this.minAppStartDate = this.startDate.toISOString().substring(0,16);
    this.maxAppStartDate = this.endDate.toISOString().substring(0,16);    
    this.maxAppEndDate = this.transformDate(this.endDate);
    this.setApplicationMinEndDate = this.minAppStartDate;
    this.setDatabaseMinEndDate = this.minAppStartDate;
    this.minWorkerTaskDate = this.minAppStartDate.slice(0,10);
    this.getStartValueappLog = this.getdate.toISOString().substring(0, 16);
    this.getStartValuedbLog = this.getdate.toISOString().substring(0, 16);
    this.getEndValueappLog = this.OnInitendDate.toISOString().substring(0, 16);
    this.getEndValuedbLog = this.OnInitendDate.toISOString().substring(0, 16);
    this.workertaskMaxDate = this.maxAppEndDate.slice(0, 10);
    this.workerTaskLogDate = this.workertaskMaxDate;

    this.isViewLogAction = false;
    this.instanceEsignData.records = [{
      label: this.instanceEnum.instance_tools_card_field_instance_name,
      data: '',
      id:"instName"
    },
    {
      label: this.instanceEnum.instance_tools_card_field_version,
      data: '',
      id:"version"
    },
    {
      label: this.instanceEnum.instance_tools_card_field_multi_esign_configuration_enabled,
      data: '',
      id:"config",
      info: this.instanceEnum.instance_tools_card_field_multi_esign_configuration_enabled_info
    },
    {
      label: this.instanceEnum.instance_tools_card_field_send_as_signature,
      data: '',
      id:"signUsed",
      info: this.instanceEnum.instance_tools_card_field_send_as_signature_info
    },
    {
      label: this.instanceEnum.instance_tools_card_field_logging_verbosity,
      data: '',
      id:"verbosity",
      info: this.instanceEnum.instance_tools_card_field_logging_verbosity_info
    }]
  }

  updateAllComplete() {
    this.allComplete = this.backup.subtasks != null && this.backup.subtasks.every((task:any) => task.completed);
    this.validateConfirmForBackup();
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.backup.subtasks == null) {
      return;
    }
    this.backup.subtasks.forEach((task:any) => (task.completed = completed));
    this.validateConfirmForBackup();
  }

  roleRestartSelectionChange(roleRestart: any)
  {
    if(roleRestart == 'AddInsRoleRestart')
    {
      this.addInRole = true;
    }
    else
    {
      this.addInRole = false;
    }
  }

  selectAllAddIn(selectAll: boolean) {
    this.allAddInRoles = selectAll;
    if (this.AddInRoles.addIns == null) {
      return;
    }
    this.AddInRoles.addIns.forEach((role:any) => (role.completed = selectAll));
    this.validateSelectAllAddIns();
  }

  updateAllAddInRoles() {
    this.allAddInRoles = this.AddInRoles.addIns != null && this.AddInRoles.addIns.every((role:any) => role.completed);
    this.validateSelectAllAddIns();
  }

  validateConfirmForBackup(){
    this.validateConfirmAction = !this.allComplete && !(this.backup.subtasks.some((task:any) => task.completed));
  }

  validateSelectAllAddIns(){
    this.validateConfirmAction = !this.allAddInRoles && !(this.AddInRoles.addIns.some((role:any) => role.completed));
  }

  //datetime Validation
  transformDate(selectedDate: any) {
    return this.datePipe.transform((selectedDate.toISOString()), 'YYYY-MM-ddThh:mm','UTC');

  }

  columnFilterCtrldbtablename(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.selectdbtablenames = this.selectdbtable.filter((x: any) =>
      x.toLowerCase().includes(valueLowerCase),
    );
  }

  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.hangfireDashBoardRequestDetails =   this.ViewData?.featureAccessRequests
            .filter((request:any) => request.featureCategory === 'Hangfire Dashboard')
            .map((request:any) => ({
              featureId: request.featureId,
              requestStatus: request.status,
              requestedDuration: request.requestedDuration,
              RequestedByUserId:request.requesterUserId,
              RequestApprovedTime: request.approvedAt,
              featureCategoryId: request.featureCategoryId,
              statusId:request.statusId,
              approverComment: request.approverComment,
              approvedDuration:request.approvedDuration,
              requestedAt : request.requestedAt
            }));
            this.requestStatus =
            this.hangfireDashBoardRequestDetails[0].statusId;
            this.featureCategoryId =  this.hangfireDashBoardRequestDetails[0]?.featureCategoryId;
            if(this.requestStatus == this.approvalStatus.Pending && this.selectedValue == 'Hangfire Dashboard'){
              this.isRequestPending = true;
              this.isRequestAccess = false;
              this.validateConfirmAction = true;
            }
            else if (this.requestStatus == this.approvalStatus.Rejected && this.selectedValue == 'Hangfire Dashboard'){
              this.isRequestRejected = true;
              this.isRequestAccess = false;
            }
            else if(this.requestStatus == this.approvalStatus.Approved && this.selectedValue == 'Hangfire Dashboard'){
              this.isRequestAccess = false;
              this.openHangfiredashboard(this.instanceId);
            }
            this.instanceIciVersion = (this.ViewData.iciVersion)?.localeCompare(this.compareVersion, undefined, { numeric: true, sensitivity: 'base' });
            if(this.selectedValue == this.instanceEnum.instance_view_esign_troubleshooter_header){
              this.getInstanceandProviderDetail(this.ViewData.cname, this.ViewData.iciVersion);
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  getInstanceandProviderDetail(cname:any, version:any){
    try{
      this.instanceService
        .getInstanceandProviderDetail(cname, version)
        .subscribe((resp: any) => {
          let result = resp.body;
          if(result.isSuccessful){
            let content = JSON.parse(result.content);
            this.instanceEsignAccounts = content?.ProviderDetails?.EsignProviders;
            this.instanceEsignData.records = [{
              label: this.instanceEnum.instance_tools_card_field_instance_name,
              data: content?.InstanceName,
              id:"instName"
            },
            {
              label: this.instanceEnum.instance_tools_card_field_version,
              data: content?.ICIVersion,
              id:"version"
            },
            {
              label: this.instanceEnum.instance_tools_card_field_multi_esign_configuration_enabled,
              data: content?.IsMultiEsignKeyUsed,
              id:"config",
              info: this.instanceEnum.instance_tools_card_field_multi_esign_configuration_enabled_info
            },
            {
              label: this.instanceEnum.instance_tools_card_field_send_as_signature,
              data: content?.SendForSignature,
              id:"signUsed",
              info: this.instanceEnum.instance_tools_card_field_send_as_signature_info
            },
            {
              label: this.instanceEnum.instance_tools_card_field_logging_verbosity,
              data: content?.LoggingVerbosity,
              id:"verbosity",
              info: this.instanceEnum.instance_tools_card_field_logging_verbosity_info
            }]
          } else {
            this.notificationService.showNotification(result.message,'warning', this.instanceEnum.instance_view_esign_troubleshooter_header);
            this.location.back();
          }
        })
    } catch(error){

    }
  }
  
  onActionChange(event: any) {
    let currSel = this.iciActions.filter((ele: any) => ele.name == event.name);
    this.currAction = currSel[0]?.name;
    this.statusId = this.ViewData.statusId;
    this.currentMonth = new Date().getDate();
    this.minAppEndDate = this.getStartValueappLog;
    this.getCurrentdate = new Date();
    this.getStartValueappLog = this.getdate.toISOString().substring(0, 16);
    this.getStartValuedbLog = this.getdate.toISOString().substring(0, 16);
    this.getEndValueappLog = this.getCurrentdate.toISOString().substring(0, 16);
    this.getEndValuedbLog = this.getCurrentdate.toISOString().substring(0, 16);
    this.isViewLogAction = true;
    this.selectdbvalue = '';
    this.selectedEsignAccount = '';
    this.showError= false;
    this.getSearchValueappLog = ''
    
    if (event.name == "Role Restart" || event.name == "Task Restart" || event.name == "Cache Flush" || this.selectdbtable != '') {
      this.validateConfirmAction = false;
      this.isViewLogAction = false;
      this.actionName = event.name;
    }
    else if(event.name == "Worker Task Logs"){      
      if(this.workerTaskLogDate != ""){
        this.validateConfirmAction = false;
      }
      else{
        this.validateConfirmAction = true;
      }
    }else if(event.name == "Instance Backup"){
      this.validateConfirmForBackup();
      this.checkIfInstanceBackupInProgress()
    }else if(event.name == 'Hangfire'){
      this.validateComments();
    }
    else if(event.name == "Esign Troubleshooting"){      
      if(this.selectedEsignAccount != ""){
        this.validateConfirmAction = false;
      }
      else{
        this.validateConfirmAction = true;
      }
    }
    else {
      if (event.name == "Application Logs" && this.getStartValueappLog != '' && this.getEndValueappLog != '') {
        this.validateConfirmAction = false;
      }
      else {
        this.validateConfirmAction = true;
      }
    }
    this.startDateError = false;
    this.startDateErrorMsg = "";
    this.endDateError = false;
    this.endDateErrorMsg = "";
  }  

  validateComments(){
    if(this.comments?.replace(/\s/g, '').trim().length != 0){
      this.validateConfirmAction = false;
  }
  else{
    this.validateConfirmAction = true;
  }
  }

  requestAccessForHangfire(){
    let param = {
      featureCategoryId: 3,
      featureId: 10,
      instanceId: Number(this.instanceId),
      statusId: 1,
      requesterUserId: this.currentUser,
      requesterComment: this.comments,
      requestedDuration:0,
      approverUserId: null,
      approverComment: null,
      approvedDuration: null,
    };
    this.instanceService.FeatureAccessRequest(param).subscribe((res: any) => {
      if (res?.body.isSuccessful) {
        this.isRequestAccess= false;
        this.isRequestPending = true;
        this.getInstanceByID();
      }else{
        this.notificationService.showNotification('Failed to request access for Hangfire Dashboard.','error','Request Failed');
      }
    });
  }
  requestAccess(event:any){
      this.isRequestAccess = true;
  }

  openHangfiredashboard(instanceId:any){
    this.instanceService
    .getHangfireDashboard(instanceId)
    .subscribe((res: any) => {
      if(res.HangfireDashboardUrl){
          let token:any =''
          let url:any = this.sanitizer.sanitize(SecurityContext.URL, res.HangfireDashboardUrl);
          this.activeAccount = this.msalService.instance.getAllAccounts()[0];
          const expiryDate = new Date();
          expiryDate.setMinutes(expiryDate.getMinutes() + 30);
          token =localStorage.getItem(`${this.activeAccount.homeAccountId}-${this.activeAccount.environment}-accesstoken-${this.activeAccount.idTokenClaims?.aud}-${this.activeAccount.tenantId}-api://${environment.audienceId}/idnapiallow--`)
            if(token){
              const tokenValue = JSON.parse(token).secret.trim();
              document.cookie = `HangFireCookie=${tokenValue}; Path=/; secure=true; SameSite=Strict; Expires= ${expiryDate.toUTCString()};`;
              window.open(url, '_self')
            }        
      } else {
        this.notificationService.showNotification(
          "Hangfire Dashboard data is not available at this point of time, please try later.",
          'warning',          
          "HangFire Dashboard"
        );
      }

    });
  }

  getCookieValue(name:any) 
    {
      const regex = new RegExp(`(^| )${name}=([^;]+)`)
      const match = document.cookie.match(regex)
      if (match) {
        return match[2]
      } else {
        return;
      }
   }




  applicationStartDateValueChange(event: any) {
    this.setApplicationMinEndDate = this.getStartValueappLog;
    this.getEndValueappLog = '';
  }


  databaseStartDateValueChange(event: any) {
    this.setDatabaseMinEndDate = this.getStartValuedbLog;
    this.getEndValuedbLog = '';
  
  }

  databaseTableChange(event: any) {
      if(this.selectdbvalue == "Task Event" || this.selectdbvalue == "Interest" || this.selectdbvalue == "Setting" || this.selectdbvalue == "Client App Error Mapping" || this.selectdbvalue == "Legacy Global Settings" || this.selectdbvalue == 'Inbox Status'){
       this.hideDateTime = true
      }
      else{
        this.hideDateTime = false
      }
    
    if (this.selectdbvalue != "" && this.startDateError == false && this.endDateError == false && this.getStartValuedbLog != "" && this.getEndValuedbLog != "")
      this.validateConfirmAction = false;
    else
      this.validateConfirmAction = true;
  }

  disableDate() {
    return false;
  }

  action(selectedValue: any): void {
    if (selectedValue == "Application Logs") {
      this.applicationLog();
    }
    else if (selectedValue == "Database Logs") {
      this.databaseLog();
    }
    else if (selectedValue == "Role Restart") {
      this.roleRestart();
    }
    else if (selectedValue == "Task Restart") {
      this.taskRestart();
    }
    else if (selectedValue == "Cache Flush") {
      this.redisFlush();
    }
    else if (selectedValue == "Worker Task Logs"){
      this.workerTaskLog();
    }
    else if (selectedValue == "Instance Backup"){
      this.instanceBackup();
    }
    else if (selectedValue == "Esign Troubleshooting"){
      this.esignRequest();
    }
    else if(selectedValue == 'Hangfire Dashboard'){
      this.requestAccessForHangfire();
    }
  }


  applicationLog() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_application_log_requested,
      module: 'Application_Log',
      message: this.instanceEnum.instance_view_application_log_popuptext +'<b> Confirm. </b>'+ this.instanceEnum.instance_view_application_log_popuptext1,
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.paramreqdata = {
          "PageIndex": 1,
          "PageSize": 50,
          "startDateTime": this.getStartValueappLog,
          "endDateTime": this.getEndValueappLog,
          "filterParameters": [
            {
              "field": "SearchString",
              "value": this.getSearchValueappLog,
            }
          ],

        };
        this.instanceService.downloadApplicationLog(this.instanceId, this.paramreqdata).
          subscribe((data) => {
            this.navigateToHistory();
          },(error =>{
            this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_application_log_header)
          })
          );
      }
    })
  }
  workerTaskLog() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_worker_task_log_requested,
      module: 'WorkerTask_Log',
      message: this.instanceEnum.instance_view_worker_task_log_popuptext +'<b> Confirm. </b>' + this.instanceEnum.instance_view_worker_task_log_popuptext1 ,
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.paramreqdata = {"instanceId" : this.instanceId,"logStartDate":this.workerTaskLogDate}
        this.instanceService.workerTaskLogs(this.instanceId, this.paramreqdata).
          subscribe((data) => {
            this.navigateToHistory();
          },(error:any)=>{
            this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_workertask_log_header)
          }
          );
      }
      
    })
  }

  databaseLog() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_database_log_requested,
      module: 'Database_Log',
      message: this.instanceEnum.instance_view_database_log_popuptext +'<b> Confirm. </b>'+ this.instanceEnum.instance_view_database_log_popuptext1 ,
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        if(this.selectdbvalue == "Task Event" || this.selectdbvalue == "Interest" || this.selectdbvalue == "Setting" || this.selectdbvalue == "Client App Error Mapping"){
          this.paramreqdata = {
            "startDateTime": null,
            "endDateTime": null,
            "tableName": this.selectdbvalue
          };
        }
        else{
          this.paramreqdata = {
            "startDateTime": this.getStartValuedbLog,
            "endDateTime": this.getEndValuedbLog,
            "tableName": this.selectdbvalue
          };
        }
       
        this.instanceService.downloadDatabaseLog(this.instanceId, this.paramreqdata).
          subscribe((resp)=>{
            this.navigateToHistory();
          },(error:any)=>{
            this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_database_log_header)
          });
      }
    })
  }

  roleRestart() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_confirm_role_restart,
      module: 'Restart_Role',
      message: '<b>Proceed with role restart?</b>' +
        '<p>To trigger role restart on ' + this.ViewData.cname + ', click <b>Confirm.</b> We recommend restarting the role during non-business hours. <br>The process might take a few minutes. To track the progress, go to the Instance Tools History page.</br></p>',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      let json: any;
      if (res) {
        let JSON = {          
          "selectedAddInValues": "",
          }  
          this.selectedAddInValues = [];           
          this.AddInRoles?.addIns.forEach((role:any)=>{
            if(role.completed && role.name != 'All')
            {            
             this.selectedAddInValues.push(role.name);
            }
          })  
           JSON = { "selectedAddInValues": this.selectedAddInValues, }
       
        this.instanceService.PerformRoleRestart(this.instanceId, JSON).subscribe((resp) => {
            this.navigateToHistory();
       
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_role_restart_header)
        }
        );
      }
    })
  }

  taskRestart() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_confirm_task_restart,
      module: 'Task_Restart',
      message: '<h2>Are you sure you want trigger task restart?</h2>' +
        '<p> To trigger task restart on ' + this.ViewData.cname + ', click <b>Confirm.</b> IDN recommends performing task restart in nonbusiness hours. The process might take a few minutes. <br>To track the progress, go to the Instance Tools History page.</br></p>',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.instanceService.PerformTaskRestart(this.instanceId).subscribe((resp) => {
            this.navigateToHistory();
         
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_task_restart_header)
        })
      }
    });

  }
  redisFlush() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_confirm_cache_flush,
      module: 'Redis_Flush',
      message: '<h2>Are you sure you want to flush cache?</h2>' +
        '<p> To trigger cache flush on ' + this.ViewData.cname + ', click <b>Confirm. </b> IDN recommends performing cache flush in nonbusiness hours. The process might take a few minutes.<br>To track the progress, go to the Instance Tools History page.</br></p>',
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.instanceService.PerformRedisFlush(this.instanceId).subscribe((resp) => {
            this.navigateToHistory();
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_cache_flush_header)
        });
      }
    })
  }

  instanceBackup() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_instance_backup_requested,
      module: 'instance_backup',
      message: this.instanceEnum.instance_view_instance_backup_log_popuptext +'<b> Confirm. </b>' + this.instanceEnum.instance_view_instance_backup_log_popuptext1 ,
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        
        if(this.allComplete){
          this.instanceBackupData = {
            instanceType: this.ViewData.typeOfInstanceName == "Multi-Tenant"? "MT" : "ST",
            email: this.permissionService.getuserEmail(),
            AllBackup: true,
            Database: true,
            Store: true,
            ES: true
          }
        } else {
          this.instanceBackupData.instanceType= this.ViewData.typeOfInstanceName == "Multi-Tenant"? "MT" : "ST",
          this.instanceBackupData.email = this.permissionService.getuserEmail(),
          this.backup?.subtasks.forEach((task:any)=>{
            if(task.name == "Database Backup" && task.completed){
              this.instanceBackupData.Database = true;
            } else if(task.name == "Document Storage Backup" && task.completed){
              this.instanceBackupData.Store = true;
            } else if(task.name == "Elasticsearch Backup" && task.completed){
              this.instanceBackupData.ES = true;
            }
          })
        }
        this.paramreqdata = this.instanceBackupData;
        this.instanceService.InstanceBackup(this.instanceId, this.paramreqdata).
          subscribe((data) => {
            this.navigateToHistory();
          },(error:any)=>{
            this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_instance_backup_header)
          }
          );
      }
      
    })
  }

  esignRequest() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.instance_view_esign_troubleshooting_requested,
      module: 'esign_troubleshooting',
      message: this.instanceEnum.instance_view_esign_troubleshooting_popuptext + '<b> Confirm. </b>' + this.instanceEnum.instance_view_esign_troubleshooting_popuptext1 ,
      confirmText: 'Confirm',
      cancelText: 'Cancel'
    }).subscribe((res: any) => {
      if (res) {
        this.instanceService.eSignProviderICIToolHistoryLog(this.instanceId,this.selectedEsignAccount).subscribe((resp) => {
            this.navigateToHistory();
        },(error:any)=>{
          this.notificationService.showNotification(error,'warning',this.instanceEnum.instance_view_esign_troubleshooter_header)
        });
      }
    })
  }


  validateApplicationLogDates(elem: any) {
    this.startDateError = false;
    this.startDateErrorMsg = "";
    this.endDateError = false;
    this.endDateErrorMsg = "";

    if (elem.value == "") {
      this.validateConfirmAction = true;
      return;
    }

    var startDate = this.getStartValueappLog;
    var endDate = this.getEndValueappLog;

    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);
    let minStartDate = minDate.toISOString().substring(0, 16);
    let minEndDate = minDate.toISOString().substring(0, 16);

    let maxStartDate = new Date().toISOString().substring(0, 16);
    let maxEndDate = new Date().toISOString().substring(0, 16);


    if (elem.id == "appStartDate" && elem.value != "") {
      if (isNaN(new Date(startDate).getTime())) {
        this.startDateError = true;
        this.startDateErrorMsg = this.instanceEnum.instance_error_InvalidStartDate;
        this.validateConfirmAction = true;
        return;
      }

      if (new Date(startDate).getTime() > new Date(maxStartDate).getTime()) {
        this.startDateError = true;
        this.startDateErrorMsg = this.instanceEnum.instance_error_startdate_less + " " + new Date(maxStartDate).toLocaleString('en-US', { hour12: true }) + " (UTC).";
        this.validateConfirmAction = true;
        return;
      }

      if(this.getStartValueappLog < this.minAppStartDate || this.getStartValueappLog > this.maxAppStartDate ){
        this.startDateError = true; 
        this.startDateErrorMsg= this.instanceEnum.instance_date_error_msg +this.datePipe.transform(this.minAppStartDate,'short')+ ' and ' +this.datePipe.transform(this.maxAppStartDate,'short') + ' (UTC).';
        this.validateConfirmAction = true;
        return;
      }
    }

    if (elem.id == "appEndDate" && elem.value != "") {
      if (isNaN(new Date(endDate).getTime())) {
        this.endDateError = true;
        this.endDateErrorMsg = this.instanceEnum.instance_error_InvalidEndDate;
        this.validateConfirmAction = true;
        return;
      }

      if (new Date(endDate).getTime() <= new Date(startDate).getTime()) {
        this.endDateError = true;
        this.endDateErrorMsg = this.instanceEnum.instance_error_enddate;
        this.validateConfirmAction = true;
        return;
      }
      var daysBetweenDates = this.getdaysDifference(startDate, endDate);

      if (daysBetweenDates > 1) {
        this.endDateError = true;
        this.endDateErrorMsg = this.instanceEnum.instance_error_enddate_range;
        this.validateConfirmAction = true;
        return;
      }
    }

    if (this.getStartValueappLog != "" && this.getEndValueappLog != "") {
      if (this.startDateError == false && this.endDateError == false) this.validateConfirmAction = false;
    }
    else { this.validateConfirmAction = true; }

  }

  validateWorkerTaskLogDates(elem: any) {  
    if (elem.value == "" || this.workerTaskLogDate > this.workertaskMaxDate || this.workerTaskLogDate < this.minWorkerTaskDate) {
      this.validWorkerTaskLogDate = true;
      this.validateConfirmAction = true;
      this.validDateMessage = this.instanceEnum.instance_error_workerTask_msg +this.minWorkerTaskDate+ ' and ' +this.workertaskMaxDate+ ' only';
      return;
    }
    else{
      this.workerTaskLogDate = elem.value;
      this.validateConfirmAction = false;
      this.validWorkerTaskLogDate = false;
    }
  }

  validateDatabaseLogDates(elem: any) {
    this.startDateError = false;
    this.startDateErrorMsg = "";
    this.endDateError = false;
    this.endDateErrorMsg = "";


    if (elem.value == "") {
      this.validateConfirmAction = true;
      return;
    }

    var startDate = this.getStartValuedbLog;
    var endDate = this.getEndValuedbLog;

    let minDate = new Date();
    minDate.setDate(minDate.getDate() - 1);
    let minStartDate = minDate.toISOString().substring(0, 16);
    let minEndDate = minDate.toISOString().substring(0, 16);

    let maxStartDate = new Date().toISOString().substring(0, 16);
    let maxEndDate = new Date().toISOString().substring(0, 16);

    if (elem.id == "dbStartDate" && elem.value != "") {
      if (isNaN(new Date(startDate).getTime())) {
        this.startDateError = true;
        this.startDateErrorMsg = this.instanceEnum.instance_error_InvalidStartDate;
        this.validateConfirmAction = true;
        return;
      }
      if (new Date(startDate).getTime() > new Date(maxStartDate).getTime()) {
        this.startDateError = true;
        this.startDateErrorMsg = this.instanceEnum.instance_error_startdate_less + " " + new Date(maxStartDate).toLocaleString('en-US', { hour12: true }) + " (UTC)";
        this.validateConfirmAction = true;
        return;
      }

      if(this.getStartValuedbLog < this.minAppStartDate || this.getStartValuedbLog > this.maxAppStartDate ){
        this.startDateError = true; 
        this.startDateErrorMsg= this.instanceEnum.instance_date_error_msg + this.datePipe.transform(this.minAppStartDate,'short')+ ' and '+this.datePipe.transform(this.maxAppStartDate,'short')+ ' (UTC).';
        this.validateConfirmAction = true;
        return;
      }
    }

    if (elem.id == "dbEndDate" && elem.value != "") {
      if (isNaN(new Date(endDate).getTime())) {
        this.endDateError = true;
        this.endDateErrorMsg = this.instanceEnum.instance_error_InvalidEndDate;
        this.validateConfirmAction = true;
        return;
      }

      if (new Date(endDate).getTime() <= new Date(startDate).getTime()) {
        this.endDateError = true;
        this.endDateErrorMsg = this.instanceEnum.instance_error_enddate;
        this.validateConfirmAction = true;
        return;
      }

      var daysBetweenDates = this.getdaysDifference(startDate, endDate);

      if (daysBetweenDates > 1) {
        this.endDateError = true;
        this.endDateErrorMsg = this.instanceEnum.instance_error_enddate_range;
        this.validateConfirmAction = true;
        return;
      }
    }

    if (this.getStartValuedbLog != "" && this.getEndValuedbLog != "") {
      if (this.startDateError == false && this.endDateError == false && this.selectdbvalue != "") this.validateConfirmAction = false;
    }
    else { this.validateConfirmAction = true; }

  }

  getdaysDifference(startDate: any, endDate: any) {
    let timeInMilisec: number = new Date(endDate).getTime() - new Date(startDate).getTime();
    let daysBetweenDates: number = timeInMilisec / (1000 * 60 * 60 * 24);
    return daysBetweenDates;
  }

  redirectToHelp() {
    this.dialogService.confirmDialog({
      title: this.instanceEnum.redirect_dialog_heading,
      module: 'resources',
      message: this.instanceEnum.redirect_dialog_body,
      confirmText: this.instanceEnum.redirect_dialog_primary_btn,
      cancelText: this.instanceEnum.redirect_dialog_secondary_btn
    }).subscribe((res: any) => {
      if(res){
        window.open('https://developer-help.icertis.com/docs/idn-debugging-tools/', '_blank');
      }
    });
 }

 onCancel(){
  this.router.navigate(['home/instanceoperations/instance-requests/instance-tools-landing']);
}

  navigateToHistory(){
    let json={
      path:"toolsPage",
      id:'',
      name:''
    }
    this.instanceService.sendData(JSON.stringify(json));
    this.router.navigate(['home/instanceoperations/instance-requests/history']);
  }

  checkIfInstanceBackupInProgress() {
    const pageNumber = 1;
    const pageSize = 1;
    const requestType = 7;
    const status = '';
    this.instanceService
      .ICIToolList(this.instanceId, pageNumber, pageSize, requestType, status)
      .subscribe((resp: any) => {
        if (resp.body.matchingCount) {
          let status = resp.body.records[0]['status'];
          if (status == 'In Progress') {
            this.showError = true;         
          }
        }
      });
  }

  checkIfEsignTroubleShootingInProgress() {
    const pageNumber = 1;
    const pageSize = 100;
    const requestType = 8;
    const status = 1;
    this.instanceService
      .ICIToolList(this.instanceId, pageNumber, pageSize, requestType, status)
      .subscribe((resp: any) => {
        if (resp.body.matchingCount) {
          let data = resp.body.records;
          data?.forEach((row:any)=>{
            if(row.searchText==this.selectedEsignAccount){
              this.showError = true;
            } else {
              this.showError = false;
            }
          })
        } else {
          this.showError = false;
        }
      });
  }

  esignProviderChange(){
    if(this.selectedEsignAccount){
      this.validateConfirmAction = false;
      this.checkIfEsignTroubleShootingInProgress()
    }
  }
}

