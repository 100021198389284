<div class="table-scroll" tabindex="-1">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="!noContent; else noContentTemplate" matSort (matSortChange)="handleSort($event)">
       
        <div *ngFor="let columns of displayedColumns;index as i" >
            <ng-container matColumnDef={{columns}} *ngIf="data?.reference !='packageParentTable' && data?.reference !='packageChildTable'">
                <th mat-header-cell *matHeaderCellDef [mat-sort-header]="data?.reference =='tableSQLFailed' || data?.reference == 'tableFailedESign' || data?.reference =='exportLogsTable' || data?.reference == 'ApprovalListTable' ? columns : null " id="{{columns}}" matTooltip="{{dataSourceValues[columns].label  | uppercase}}" [disabled]="data?.reference =='tableSQLFailed' || data?.reference == 'tableFailedESign' || data?.reference == 'exportLogsTable' || data?.reference == 'ApprovalListTable' ? ((columns== 'action' || columns== 'status'  || columns == 'Error_Message') && data?.reference == 'exportLogsTable') || (data?.reference == 'ApprovalListTable' && columns== 'action')? true: false : true " [matTooltipDisabled]="isDisabled(valueToolTip)" matTooltipPosition="above" #valueToolTip> {{dataSourceValues[columns].label}}<button aria-label="Refresh Status Button" mat-button *ngIf="data?.reference =='exportLogsTable' && dataSourceValues[columns].data == 'exportedFileStatus'" (click)="GetExportedFileHistory()"><mat-icon>refresh</mat-icon></button> </th>
                <td class="row-width" mat-cell *matCellDef="let element" >
                    <div class="approval-list-cls" *ngIf="data?.reference =='ApprovalListTable'">
                        <div class="status-col" *ngIf="dataSourceValues[columns].label == 'Status'">
                            <span class="icon-cls" *ngIf="dataSourceValues[columns].data == 'status' && (element.statusId == 1)"><mat-icon class="pending">hourglass_empty</mat-icon></span>
                            <span class="icon-cls" *ngIf="dataSourceValues[columns].data == 'status' && (element.statusId == 2)"><mat-icon class="approved">check_circle_outline</mat-icon></span>
                            <span  class="icon-cls" *ngIf="dataSourceValues[columns].data == 'status' && (element.statusId == 3)"><mat-icon class="rejected">cancel</mat-icon></span>
                            <span>{{element.status}}</span>
                        </div>
                        <div *ngIf="dataSourceValues[columns].data == 'featureCategory'">{{element.featureCategory}}</div> 
                        <div *ngIf="dataSourceValues[columns].label != 'Status' && dataSourceValues[columns].data != 'featureCategory' && dataSourceValues[columns].label != 'Requested By'" matTooltip="{{element[dataSourceValues[columns].data]}}" matTooltipClass="table-tooltip">{{element[dataSourceValues[columns].data]}}</div>                      
                            <button mat-icon-button aria-label="View request Details Button" *ngIf="dataSourceValues[columns].label == 'Action'" matTooltip="View Request Details" (click)="viewRequest(element)"><mat-icon >remove_red_eye</mat-icon></button>

                        <div *ngIf="dataSourceValues[columns].label == 'Requested By'">
                            <div class="idn-cell-date"  matTooltip="{{element.requestedBy}}" class="overflow-data complete-data" id="createdBy-container">{{ element.requestedBy }}</div>
                            <div class="overflow-data requested-date complete-data" id="createdBy-container"> on {{ element.requestedAt | date:"dd MMMM, yyyy 'at' HH:mm '(UTC)' "}}</div>
                        </div>
                    </div>
                    <div class="dwnld-icon" *ngIf="data?.reference =='exportLogsTable'">
                        <div matTooltip="{{element[dataSourceValues[columns].data]}}" matTooltipClass="table-tooltip" *ngIf="!(dataSourceValues[columns].data == 'createdAt')">{{element[dataSourceValues[columns].data]}}</div>
                        <div matTooltip="{{element[dataSourceValues[columns].data]}}" matTooltipClass="table-tooltip" *ngIf="dataSourceValues[columns].data == 'createdAt'">{{element[dataSourceValues[columns].data] | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat }}</div>
                        <div *ngIf="dataSourceValues[columns].data == 'fileName' && (element.fileName != '' && element.fileName != null)" (click)="Download(element)"><mat-icon >download</mat-icon></div>
                    </div>
                    <span  *ngIf="data?.reference !='exportLogsTable' && data?.reference !='ApprovalListTable' " matTooltip="{{element[dataSourceValues[columns].data]}}" matTooltipClass="table-tooltip">{{element[dataSourceValues[columns].data]}}</span>
                    
                </td>
            </ng-container>

            <ng-container matColumnDef={{columns}} *ngIf="data?.reference =='packageParentTable' || data?.reference =='packageChildTable'">
                <th mat-header-cell *matHeaderCellDef  id="{{columns}}_package" #valueToolTip> {{dataSourceValues[columns].label}} </th>
                <td class="{{columns}}_row-width" mat-cell *matCellDef="let element"  > 
                    <!--  package name -->
                    <button mat-button class="idn-grid-link-button idn-cell-btn-align" [disabled]="element.isActive == false" *ngIf="dataSourceValues[columns].label == 'Name'">
                        <div class="pckgname" matTooltip="{{element.name}}" matTooltipClass="table-tooltip">{{element.name}}</div>
                        <mat-icon class="download-icon" [mat-dialog-close]="{action:'download', data:element}"
                            *ngIf="(!element.locked && element.extensionStatus == 1) && element.fileName!='' && element.fileName!=undefined &&((element.status !=='Upload failed' && element.status !=='Package uploaded' && element.status !=='Virus scan in progress' && element.status !=='Virus scan failed' && element.status !=='Upload in progress' ))">download
                        </mat-icon>
                    </button>
                    <!--  Created by name --> 
                    <div *ngIf="dataSourceValues[columns].label == 'Created By'">
                        <div class="idn-cell-date"  matTooltip="{{element.createdByDisplayName}}" class="overflow-data complete-data" id="createdBy-container">{{ element.createdByDisplayName }}</div>
                        <div class="overflow-data requested-date complete-data" id="createdBy-container"> on {{ element.createdAt | date:"dd MMMM, yyyy " }}{{ element.createdAt | date:"'at' HH:mm '(UTC)'" }}</div>
                    </div>

                    <button mat-icon-button aria-label="View" *ngIf="dataSourceValues[columns].label == 'Action'" matTooltip="View Package" [mat-dialog-close]="{action:'view', data:element}">
                        <mat-icon>remove_red_eye</mat-icon>
                    </button>
                    <div class="overflow-data complete-data" matTooltip="{{element[dataSourceValues[columns].data]}}" *ngIf="dataSourceValues[columns].label != 'Name' && dataSourceValues[columns].label != 'Created By' && dataSourceValues[columns].label != 'Action'"
                            matTooltipClass="table-tooltip">{{element[dataSourceValues[columns].data]}}</div>
                </td>
            </ng-container>
        </div>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngStyle]="{'background-color': (row?.Result && row?.Result != 'Success')? '#FFCECE': 'white'}"></tr>
    </table>
</div>
<mat-paginator *ngIf="paginationRequired" #paginator [pageIndex]="currentPage" [pageSize]="pageSize" class="mat-paginator-sticky"
(page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
showFirstLastButtons [ngClass]="{'hide-pagination': noContent ? true : false}">
</mat-paginator>

<ng-template #noContentTemplate>
    <div class="idn-no-result">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276"
            height="214.71" viewBox="0 0 203.276 214.71">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39"
                        transform="translate(0 0)" fill="#515165" />
                </clipPath>
            </defs>
            <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
                <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
                    transform="translate(3187.267 7517.8)" fill="#7c7c97" />
                <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
                    transform="translate(3192.911 7517.8)" fill="#515165" />
                <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
                    transform="translate(3178.801 7551.663)" fill="#ababc5" />
                <path id="Union_2" data-name="Union 2"
                    d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                    transform="translate(3178.801 7617.272)" fill="#7c7c97" />
                <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
                    transform="translate(3192.911 7551.663)" fill="#7c7c97" />
                <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
                    <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264"
                        transform="translate(0 0)" fill="#cfdae2" />
                    <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
                        transform="translate(8.466 8.466)" fill="#f6f2f1" />
                </g>
                <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
                    clip-path="url(#clip-path)">
                    <path id="Union_1" data-name="Union 1"
                        d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                        transform="translate(-14.11 0)" fill="#515165" />
                </g>
            </g>
        </svg>
        <h4>No Data found</h4>
    </div>
</ng-template>
