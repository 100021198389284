import { MsalService } from '@azure/msal-angular';
import { DatePipe, formatDate, JsonPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { Router } from '@angular/router';
import { ExtensionService } from 'src/app/core/services/extension-management/extension.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { NotificationService } from 'src/app/shared/notification/notification.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { AzureBlobStorageService } from 'src/app/core/services/azure-blob-storage/azure-blob-storage.service';
import { SdkServiceService } from 'src/app/core/services/sdk-mangement/sdk-service.service';
import { catchError, lastValueFrom, map } from 'rxjs';
import { EnvService } from 'src/app/core/services/env.service';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { MessageEnum } from 'src/app/shared/enum/MessageEnum';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { InputPattern } from 'src/app/shared/enum/inputPattern';
import { USER_CATEGORY_ID } from 'src/app/shared/constants/idn-constants';
import { UserService } from 'src/app/core/services/user-management/user.service';

const userCategoryID: { [key: string]: number } = {
  Icertis: 1,
  Partner: 2,
  Customer: 3
};

@Component({
  selector: 'app-edit-instance',
  templateUrl: './edit-instance.component.html',
  styleUrls: ['./edit-instance.component.scss'],
})
export class EditInstanceComponent implements OnInit {
  @ViewChild('fileInput')
  fileInput!: ElementRef;
  instanceData: any;
  instanceId: any;
  customers: any;
  partners:any;
  hasGlobalDataAccess: any;
  columnFilterCtrlCo = new FormControl('');
  instanceType: any;
  deploymentRegions: any;
  @ViewChild('customername')
  mySelect!: MatSelect;
  fileAttr = 'Choose File';
  listofUploadedFiles: any[] = [];
  fileArray: any[] = [];
  file: any;
  fileSizeLimit = this.env.fileSizeLimitInstance;
  eventObject: any = {};
  sas: any;
  uniqueIdentifier: any;
  IsExtenTypeSelected: boolean = false;
  azUrl: any;
  today: any = new Date();
  minDate: any = new Date();
  todaysDate: any;
  extensionArray: any = ['pdf', 'doc', 'docx', 'txt', 'xlsx', 'xls'];
  cnameSuffix = '.icertis.com';
  breadCrumbItems: any = [
    { label: 'Home', path: 'resources' },
    { label: 'Instance Operations', path: 'instanceoperations/instance-requests' },
    { label: 'Instance Requests', path: 'instanceoperations/instance-requests' },
    { label: 'Edit', path: 'edit' },
  ];
  CustomerDisplayList: any[] = [];
  dateInput: any = this.datePipe.transform(new Date(), 'yyyy-MM-ddTHH:mm:ss');
  todayDate: Date = new Date(this.dateInput);
  oldCname: any;
  dateError: any = false;
  users: any[] = [];
  globalRegions: any = [];
  partnerId: any;  
  inputCnamevalue = '';
  public regex = InputPattern;
  regexCname: any = new RegExp(this.regex.cnamePattern);
  firstCharSmallErrorMessage:any = this.regex.firstCharSmallErrorMessage;
  errorDescriptionCname:any;
  public StateEnum = MessageEnum;
  public instanceEnum = InstanceManagement;
  instanceVersions:any =
  [{name: this.instanceEnum.instance_view_approval_current_podVersion, value: this.instanceEnum.instance_approval_current_podVersion},
   {name: this.instanceEnum.instance_view_approval_previous_podVersion,value: this.instanceEnum.instance_approval_previous_podVersion}];
  typeOfInstance:any;
  userCategory:any;
  customerUserCustomerId:number=0;

  frmEditInstance: FormGroup = new FormGroup({
    id: new FormControl(0),
    instanceTypeId: new FormControl('', Validators.required),
    typeOfInstanceId: new FormControl('', Validators.required),
    customerId: new FormControl('', Validators.required),
    cname: new FormControl('', [
      Validators.required,
      Validators.pattern(this.regexCname),
    ]),
    userId: new FormControl('', Validators.required),
    deploymentRegionId: new FormControl([], Validators.required),
    requiredBy: new FormControl(''),
    InstanceVersion:new FormControl('', Validators.required),
    statusId: new FormControl(0),
    uniqueIdentifier: new FormControl(null),
    timestamp: new FormControl(''),
    files: new FormControl([]),
    partnerId: new FormControl([]),
  });
  columnFilterRegion = new FormControl('');
  selectRegion: any = [];
  constructor(
    private extensionService: ExtensionService,
    private instanceService: InstanceService,
    private notificationService: NotificationService,
    private userService: UserService,
    private router: Router,
    private datePipe: DatePipe,
    private msalService: MsalService,
    private blobService: AzureBlobStorageService,
    private sdkService: SdkServiceService,
    private env: EnvService,
    private permissionService: PermissionsService
  ) {}

  ngOnInit(): void {
    this.minDate.setDate(this.today.getDate());
    this.hasGlobalDataAccess = this.permissionService.hasGlobalDataAccess();
    this.partnerId = this.permissionService.getPartnerDetails().id;
    this.instanceId = localStorage.getItem('instanceID');
    this.userCategory=this.permissionService.getUsercategoryId();
    
    this.getReferenceData();
    this.getInstanceByID();
    this.columnFilterCtrlCo.valueChanges.subscribe((value) => {
      this.filterColumnControl(value);
    });
    this.columnFilterRegion.valueChanges.subscribe((value:any) => {
      this.columnFilterCtrlRegion(value);
    });
    this.GetCustomersWithoutPartner();
  }

  columnFilterCtrlRegion(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.selectRegion = this.deploymentRegions?.filter((x: any) =>
      x.displayName.toLowerCase().includes(valueLowerCase),
    );
  }
  valueChange(event: any) {
    if (event != undefined && event != null && !this.regexCname.test(event[0])) {
      this.errorDescriptionCname = this.firstCharSmallErrorMessage;
    }
    else {
      this.errorDescriptionCname = this.instanceEnum.instance_error_message_allowed_character;
    }
  }

  getInstanceByID() {
    this.instanceService
      .getInstanceByID(this.instanceId)
      .subscribe((res: any) => {
        let instanceRow = res.body;
        this.instanceData = res.body;
        if (instanceRow != null && instanceRow != undefined) {
          this.frmEditInstance.patchValue({
            id: instanceRow.id,
            instanceTypeId: instanceRow.instanceTypeId,
            typeOfInstanceId: instanceRow.typeOfInstanceId,
            customerId: instanceRow.customerId,
            cname: instanceRow.cname.replace(this.cnameSuffix, ''),
            deploymentRegionId: instanceRow.deploymentRegionId,
            requiredBy: instanceRow.requiredBy,
            statusId: instanceRow.statusId,
            uniqueIdentifier: instanceRow.uniqueIdentifier,
            timestamp: instanceRow.timestamp,
            icmVersionId: instanceRow.icmVersionId,
            files: instanceRow.files,
            userId: instanceRow.instanceDefaultUserId,
            partnerId:instanceRow.partnerId,
            InstanceVersion: instanceRow.instanceVersion,
          });
          this.listofUploadedFiles = instanceRow.files;
          this.uniqueIdentifier = instanceRow.uniqueIdentifier;
          this.oldCname = instanceRow.cname;
          this.frmEditInstance.controls.typeOfInstanceId.disable();
          this.checkDate(instanceRow.requiredBy);
          if(this.userCategory != USER_CATEGORY_ID.Customer && instanceRow.partnerId){
          this.GetCustomersByPartnerId(instanceRow.partnerId);
          }
          this.getUsersByCustomerId(instanceRow.customerId);
          this.GetInstanceTypeByCustomerId(instanceRow.customerId);
        }
      });
  }

  filterColumnControl(value: any) {
    const valueLowerCase = value.toLowerCase();
    this.customers = this.CustomerDisplayList.filter((x: any) =>
      x.name.toLowerCase().includes(valueLowerCase)
    );
  }

  getReferenceData() {
    let inputParam='Create';
    try {
      this.instanceService.getReferenceData(inputParam).subscribe((resp: any) => {
        if (resp != null) {
          this.deploymentRegions = resp.deploymentRegions;
          this.selectRegion = this.deploymentRegions;
          this.globalRegions = resp.deploymentRegions;
          this.typeOfInstance = resp.typeOfInstances;
          if(this.hasGlobalDataAccess){
            this.partners = resp.partners;
          }

          

          if(this.userCategory ==userCategoryID.Customer){
            this.customers =resp.customers[0];
            this.customerUserCustomerId=this.customers.id 
           this.frmEditInstance.controls.customerId.setValue(this.customers.id);
           this.frmEditInstance.controls.customerId.disable();
            this.GetInstanceTypeByCustomerId(this.customerUserCustomerId);
            this.getUsersByCustomerId(this.customerUserCustomerId);

          }
          if(!this.hasGlobalDataAccess){
           
            this.customers = resp.customers;
            this.CustomerDisplayList = this.customers;
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  getSelectedType(event: any) {
    this.deploymentRegions = [];
    if (event.value == 6) {
      this.deploymentRegions = this.globalRegions.filter((obj: any) => {
        return obj.id == 14;
      });
      this.selectRegion = this.deploymentRegions;
    } else {
      this.deploymentRegions = this.globalRegions;
      this.selectRegion = this.deploymentRegions;
    }
  }

  GetCustomersWithoutPartner(){    
    try {
      this.userService
        .GetCustomerListBasedOnPartner()
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            this.customers = resp.body;
            this.CustomerDisplayList = this.customers;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetCustomersByPartnerId(partnerId: number){
    this.partnerId=partnerId;
    try {
      this.instanceService
        .GetCustomersByPartnerId(partnerId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            this.customers = resp.body;
            this.CustomerDisplayList = this.customers;
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  resetFormFields(){
    if(this.frmEditInstance){
      this.users=[];
      this.instanceType=[];
      this.frmEditInstance.get("userId")?.reset()
      this.frmEditInstance.get("instanceTypeId")?.reset()
    }
  }

  getUsersByCustomerId(customerId: any) {
    try {
      this.instanceService
        .GetUsersByCustomerId(customerId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            let checkIfUserAvailable = (resp.body).some((data:any)=> data.id == this.instanceData?.instanceDefaultUserId);
            if(checkIfUserAvailable){
              this.users = resp.body;
            } else {
              this.frmEditInstance.controls.userId.reset();
              this.users = resp.body;
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  GetInstanceTypeByCustomerId(customerId: number) {
    try {
      this.instanceService
        .GetInstanceTypeByCustomerId(customerId)
        .subscribe((resp: any) => {
          if (resp.body != null && resp.body != undefined) {
            let checkIfInatanceAvailable = (resp.body).some((data:any)=> data.id == this.instanceData?.instanceTypeId);
            if(checkIfInatanceAvailable){
              this.instanceType = resp?.body;
            } else {
              this.frmEditInstance.controls.instanceTypeId.reset();
              this.instanceType = resp?.body;
            }
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  //File upload event
  uploadFileEvt(File: any) {
    let fileExtension = File.target.files[0].name.split('.').pop();
    if (this.extensionArray.indexOf(fileExtension) <= -1) {
      this.notificationService.showNotification(
        'Please upload the file with correct extension',
        'error',
        'File extension is not matching'
      );
      return;
    }
    if (File.target.files && File.target.files[0]) {
      this.file = File.target.files[0];
      var filesize = Number((this.file.size / 1024 / 1024).toFixed(4));
      if (this.file.size == 0) {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_emptyFile_body,
          'error',
          this.instanceEnum.instance_notify_emptyFile_heading
        );
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return;
      }
      if (filesize > this.fileSizeLimit) {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_fileSizeExceed_body,
          'error',
          this.instanceEnum.instance_notify_fileSizeExceed_heading
        );
        this.file = null;
        this.fileInput.nativeElement.value = "";
        this.fileAttr = 'Choose File';
        return;
      }
      this.fileAttr = '';
      this.fileAttr = File.target.files[0].name;
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  //To remove added file tags.
  removeFileTag(item: any) {
    const index = this.listofUploadedFiles.indexOf(item);
    if (index >= 0) {
      this.listofUploadedFiles.splice(index, 1);
      this.fileArray.splice(index, 1);
    }
  }

  //To add file on click plus sign.
  fileAdd() {
    if (
      this.fileAttr != null &&
      this.fileAttr != '' &&
      this.fileAttr != undefined &&
      this.fileAttr != 'Choose File'
    ) {
      let isDuplicate = this.checkDuplicateFile(this.fileAttr);
      if (isDuplicate) {
        this.listofUploadedFiles.push(this.fileAttr);
        this.fileArray.push({
          fileContent: this.file,
          fileName: this.file.name,
          isUploaded: false,
        });
        this.fileAttr = 'Choose File';
        this.file = '';
      } else {
        this.fileAttr = 'Choose File';
      }
    } else if (this.fileAttr == 'Choose File') {
      this.notificationService.showNotification(
        this.instanceEnum.instance_notify_noFileChoosen_body,
        'warning',
        this.instanceEnum.instance_notify_noFileChoosen_heading
      );
    }
  }

  //To check duplicate file.
  checkDuplicateFile(filename: string): boolean {
    if (this.listofUploadedFiles == null) {
      this.listofUploadedFiles = [];
    }
    if (this.listofUploadedFiles.includes(filename)) {
      this.notificationService.showNotification(
        this.instanceEnum.instance_notify_fileAlreadyAdded_body,
        'warning',
        this.instanceEnum.instance_notify_duplicateFile_heading
      );
      return false;
    } else {
      return true;
    }
  }

  //To Create and Save Instance
  async updateInstance() {
    try {
      if (!this.frmEditInstance.valid) {
        this.notificationService.showNotification(
          this.instanceEnum.instance_notify_notValid_body,
          'warning',
          this.instanceEnum.instance_notify_notValid_heading
        );
        return;
      }
      let isexist: any;
      let cname = this.frmEditInstance.controls['cname'].value;
      if (cname !== this.oldCname) {
        isexist = await this.checkIfInstanceExsists(cname);
        if (isexist) {
          this.notificationService.showNotification(
            this.instanceEnum.instance_notify_cNameExist_body,
            'error',
            this.instanceEnum.instance_notify_cNameExist_heading
          );
          return;
        }
      }
      this.eventObject['showProgressBar'] = 'true';
      this.eventObject['cname'] =
        this.frmEditInstance.controls['cname'].value + this.cnameSuffix;
      this.sdkService.emit<any>(this.eventObject);
      this.router.navigate(['home/instanceoperations/instance-requests']);

      if (this.fileArray.length > 0) {
        if (this.listofUploadedFiles.length > 0) {
          let resToken = await this.setTokenForIdentifier(
            this.frmEditInstance.controls['id'].value
          );
          if (resToken != null && resToken != undefined) {
            this.sas = resToken.sasUrl;
            this.uniqueIdentifier = resToken.container;
            this.frmEditInstance.controls['uniqueIdentifier'].setValue(
              this.uniqueIdentifier
            );
          }
        }
        await this.uploadFileAsynchronously().then(async (result: any) => {
          this.updateFileName(this.fileArray);
        });
      } else {
        this.updateFileName(this.fileArray);
        // if (x.status == 204) {
        //   if (x) {
        //     this.router.navigate(["home/instance-requests"]);
        //     this.notificationService.showNotification('Instance request updated successfully', 'success', 'Instance request updated');
        //     this.eventObject['showProgressBar'] = 'false';
        //   }
        // }
        // if(x.status == 400){
        //   this.sdkService.emit<any>(this.eventObject);
        //     this.notificationService.showNotification('Instance request is failed to update', 'success', 'updated failed')
        // }
        // if(x.status == 404){
        //   this.sdkService.emit<any>(this.eventObject);
        //     this.notificationService.showNotification('Instance request is failed to update', 'success', 'updated failed')
        // }
      }
    } catch (error) {
      console.log('final', error);
    }
  }

  async checkIfInstanceExsists(cname: any) {
    return await lastValueFrom(
      this.instanceService.checkDuplicateInstance(cname)
    );
  }

  //To Set SAS Token
  async setTokenForIdentifier(insId: any): Promise<any> {
    try {
      return this.instanceService.getBlobUploadConnection(insId).toPromise();
    } catch (execption) {
      console.log(execption);
    }
  }
  // To Upload Multiple Files Async
  uploadFileAsynchronously() {
    return new Promise((resolve) => {
      try {
        let counter = 0;
        this.fileArray.forEach((x: any) => {
          this.blobService.uploadFiles(
            this.sas,
            this.uniqueIdentifier,
            x.fileContent,
            x.fileName,
            (response: any) => {
              counter += 1;
              if (response._response != undefined) {
                this.azUrl = response._response.request.url;
                x['azUrl'] = this.azUrl;
                x.isUploaded = true;
              }
              if (counter == this.fileArray.length) {
                this.eventObject['showProgressBar'] = 'false';
                resolve('Test');
                this.extensionService.emit<any>(this.eventObject);
              }
            }
          );
        });
      } catch (execption) {
        console.log(execption);
      }
    });
  }

  //To Update File name which is successfully uploaded
  updateFileName(array: any[]) {
    try {
      this.eventObject['showProgressBar'] = 'false';
      let fileNames: any[] = [];
      array.filter((x) => {
        if (x.isUploaded == true) {
          fileNames.push(x.fileName);
        }
      });
      if (
        this.listofUploadedFiles != null &&
        this.listofUploadedFiles.length > 0
      ) {
        this.listofUploadedFiles.forEach((x) => {
          if (!fileNames.includes(x)) {
            fileNames.push(x);
          }
        });
      }
      this.frmEditInstance.patchValue({
        files: fileNames,
        uniqueIdentifier: this.uniqueIdentifier,
      });
      let JSON = this.frmEditInstance.getRawValue();
      JSON.cname = JSON.cname + this.cnameSuffix;
      JSON.requiredBy = this.datePipe.transform(
        JSON.requiredBy,
        'yyyy-MM-ddTHH:mm:ss'
      );
      JSON.status = 'success';
      JSON.customerName = 'string';
      JSON.InstanceDefaultUser = this.frmEditInstance.controls['userId'].value;
      this.instanceService
        .putDataUrl('api/Instance/UpdateInstanceRequest', {}, JSON)
        .subscribe(
          (res: Response) => {
            if (res.status == 204) {
              this.sdkService.emit<any>(this.eventObject);
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_instanceRequestUpdated_body,
                'success',
                this.instanceEnum.instance_notify_instanceRequestUpdated_heading
              );
            }
            if (res.status == 400) {
              this.sdkService.emit<any>(this.eventObject);
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_updationFailed_body,
                'success',
                this.instanceEnum.instance_notify_updationFailed_heading
              );
            }
            if (res.status == 404) {
              this.sdkService.emit<any>(this.eventObject);
              this.notificationService.showNotification(
                this.instanceEnum.instance_notify_updationFailed_body,
                'success',
                this.instanceEnum.instance_notify_updationFailed_heading
              );
            }
          },
          (error: any) => {
            let message = error.toString();
            this.notificationService.showNotification(
              message,
              'warning',
              this.instanceEnum.instance_notify_updationFailed_heading
            );
          }
        );
    } catch (exception) {
      console.log('here', exception);
    }
  }

  cancel() {
    this.frmEditInstance.reset();
    this.router.navigate(['home/instanceoperations/instance-requests']);
  }

  checkDate(instanceDate: any) {
    var instDate: any = this.datePipe.transform(instanceDate, 'MM/dd/yyyy');
    var todaysDate: any = this.datePipe.transform(this.today, 'MM/dd/yyyy');
    var instanceModDate = new Date(instDate);
    var currentModDate = new Date(todaysDate);
    if (instanceModDate.getTime() < currentModDate.getTime()) {
      this.dateError = true;
    } else {
      this.dateError = false;
    }
  }

  handleAdmDateChange(event: any) {
    const date = event.value;
    this.checkDate(date);
  }
}
