<app-pageheader [auditLog]="false" [breadCrumbs]="this.breadCrumbItems" [isApprover]="true"></app-pageheader>
<div *ngIf="certificationDetailsData">
    <div class="certification-approver-container">
        <div class="certification-details-header">
            <div class="certification-details-label">
                <h2>{{certificationEnum.certification_approver_heading}}</h2>
            </div>
            <div class="certification-details-body">
                <div>
                    <h2><span>{{ certificationDetailsData.extensionName }}</span></h2>
                    <mat-chip-list aria-label="Extension Type">
                        <mat-chip color="accent" selected> {{ certificationDetailsData.extensionType }} </mat-chip>
                    </mat-chip-list>
                </div>
                <p> Created on: {{ certificationDetailsData.requestedOn | date:"d MMMM, y h:mm '(UTC)'" }} by {{ certificationDetailsData.requesterName }} </p>
            </div>
        </div>
        <div class="certification-details-details">
            <ul>
                <li>
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_packageName}}</span> <span class="package-name-details" matTooltip="{{certificationDetailsData.packageName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ certificationDetailsData.packageName }}</span></div>
                </li>
                <li>
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_projectVersion}}</span> {{ certificationDetailsData.packageVersion == null ? '' : certificationDetailsData.packageVersion }} </div>
                </li>
                <li>
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_customerName}}</span> {{ certificationDetailsData.customerName }} </div>
                </li>
                <li>
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_status}}</span>
                        <div [ngClass]="{'certified': certificationDetailsData.status == certificationStatus.certified, 'rejected': certificationDetailsData.status == certificationStatus.rejected, 'review-requested': certificationDetailsData.status == certificationStatus.reviewRequested, 'review-in-progress': certificationDetailsData.status == certificationStatus.reviewInProgress, 'system-check-failed': certificationDetailsData.status == certificationStatus.systemCheckFailed, 'system-check-success': certificationDetailsData.status == certificationStatus.systemCheckSuccess, 'certification-requested': certificationDetailsData.status == certificationStatus.certificationRequested}">
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.certified">verified_user</mat-icon>
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.reviewRequested">start</mat-icon>
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.rejected">gpp_bad</mat-icon>
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.reviewInProgress">downloading</mat-icon>
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.systemCheckSuccess">check_circle_outline</mat-icon>                                    
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.systemCheckFailed">error_outline</mat-icon>
                            <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.certificationRequested">rotate_right</mat-icon>
                            {{certificationDetailsData.status}}
                            <span matTooltip="Withdrawn by {{certificationDetailsData.requestWithdrawerName}} on {{ certificationDetailsData.requestWithdrawnOn | date:'d MMMM, y h:mm \'(UTC)\'' }}">
                                <mat-icon *ngIf="certificationDetailsData.status == certificationStatus.systemCheckSuccess && (certificationDetailsData.requestWithdrawerName && certificationDetailsData.requestWithdrawnOn ? true:false )">transit_enterexit</mat-icon>
                            </span> 
                        </div>                       
                    </div>
                </li>
                <li>
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_requestorDetails}}</span> {{ certificationDetailsData.requesterName }} <br> on {{ certificationDetailsData.requestedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat }} </div>
                </li>
                <li>
                    <div class="last-updated-date certification-info"><span>{{certificationEnum.certification_approver_Field_lastUpdateDate}}</span>{{ certificationDetailsData.lastModifiedOn | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat }}</div>
                </li>
            <li *ngIf="(hasGlobalDataAccess || canViewCertification) && (certificationDetailsData.status == certificationStatus.reviewRequested || certificationDetailsData.status == certificationStatus.certified || certificationDetailsData.status == certificationStatus.rejected || certificationDetailsData.status == certificationStatus.reviewInProgress ) && certificationDetailsData?.veracodeScanStatus">
                <div class="veracode-info"> Vulnerability Scan Status <span *ngIf="certificationDetailsData.veracodeScanStatus == 'In Progress' || certificationDetailsData.veracodeScanStatus == 'InitialState'"> <button class="refresh-button" mat-button (click)="refreshStatus()" title="Refresh Veracode Scan status"><mat-icon>refresh</mat-icon></button></span> <br> 
                    <div [ngClass]="{'certified': certificationDetailsData.veracodeScanStatus == 'Successful', 'expired': certificationDetailsData.veracodeScanStatus == 'Failed' ,'expiring-soon': certificationDetailsData.veracodeScanStatus == 'In Progress' || certificationDetailsData.veracodeScanStatus == 'InitialState' }">
                        <span *ngIf="certificationDetailsData.veracodeScanStatus != 'InitialState' && hasGlobalDataAccess">{{certificationDetailsData.veracodeScanStatus}} </span> 
                        <span *ngIf="certificationDetailsData.veracodeScanStatus == 'InitialState' || (certificationDetailsData.veracodeScanStatus == 'In Progress' && !hasGlobalDataAccess)">In Progress </span>
                        <span *ngIf="certificationDetailsData.veracodeScanStatus == 'Successful' && !hasGlobalDataAccess">Successful </span> 
                        <span *ngIf="certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore != null && !hasGlobalDataAccess">Failed </span> 
                        <span *ngIf="certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore == null && !hasGlobalDataAccess" class="expiring-soon">In Progress </span>
                        <span  *ngIf="certificationDetailsData?.veracodeScanScore">(Score: {{certificationDetailsData.veracodeScanScore}}%)</span>
                    </div>
                    <span class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore == null && certificationDetailsData.reTriggerCount == 2">{{certificationEnum.certification_details_veracodeResubmit_msg}} <span class="resubmit-info" *ngIf="certificationDetailsData.reTriggerCount == 2">1{{certificationEnum.certification_details_veracodeResubmit_second_Attempt}}</span></span>
                    <span class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.veracodeScanStatus == 'Failed' && certificationDetailsData.veracodeScanScore == null && (certificationDetailsData.reTriggerCount == 1 || certificationDetailsData.reTriggerCount == 0)">{{certificationEnum.certification_details_veracodeResubmit_msg}} 
                        <span  class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.reTriggerCount == 0">3</span>
                        <span  class="resubmit-info" *ngIf="hasGlobalDataAccess && certificationDetailsData.reTriggerCount == 1">2</span>
                    </span>
                </div>
            </li>
              <li *ngIf="certificationDetailsData?.veracodeReportFileName && (hasGlobalDataAccess || canViewCertification) && (certificationDetailsData.status == certificationStatus.reviewRequested || certificationDetailsData.status == certificationStatus.certified || certificationDetailsData.status == certificationStatus.rejected ||certificationDetailsData.status == certificationStatus.reviewInProgress )">
                <div class="certification-info package-file"><span>VULNERABILITY REPORT</span></div>
                <button mat-button class="align-to-label" (click)="downloadSingleFile(certificationDetailsData.veracodeReportFileName, certificationDetailsData.uniqueIdentifier, 'veracodeReportFile')">
                    <div class="idn-grid-link-button">
                        <div class="pckg-field" matTooltip="{{certificationDetailsData.veracodeReportFileName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ certificationDetailsData.veracodeReportFileName }}</div>
                        <mat-icon class="download-icon" >download
                        </mat-icon>
                    </div>
                </button>
            </li>
                <li class="full-width">
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_packageDescription}}</span> {{ certificationDetailsData.packageDescription }}</div>
                </li>
                <li *ngIf="certificationDetailsData?.packageFileName">
                    <div class="certification-info package-file"><span>{{certificationEnum.certification_approver_Field_packageFile}}</span></div>
                    <button mat-button class="align-to-label">
                        <div class="idn-grid-link-button">
                            <div class="pckg-field" matTooltip="{{certificationDetailsData.packageFileName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ certificationDetailsData.packageFileName }}</div>
                            <mat-icon class="download-icon" (click)="downloadSingleFile(certificationDetailsData.packageFileName, certificationDetailsData.uniqueIdentifier, 'SolutionPackage')">download
                            </mat-icon>
                        </div>
                    </button>
                </li>
                <li *ngIf="(hasGlobalDataAccess || canApproveCertification)">
                    <div class="slastatus-info"><span>{{certificationEnum.certification_approver_Field_slaStatus}}</span>
                        <div class="status-val"
                            [ngClass]="{'certified': certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_onTrack || certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_met, 'expired': certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_expired ||  certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_missed,'expiring-soon': certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_expiringSoon}">
                            {{certificationDetailsData.slaStatus}} <br>
                            <span *ngIf="certificationDetailsData.slaStatus != certificationEnum.certificationSlaStatus_onTrack && certificationDetailsData.slaStatus != certificationEnum.certificationSlaStatus_missed && certificationDetailsData.slaStatus != certificationEnum.certificationSlaStatus_met" class="requsted-date">
                                <span *ngIf="certificationDetailsData?.slaResolutionDeadline">on</span> {{certificationDetailsData?.slaResolutionDeadline | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" | dateFormat}}
                            </span>
                            <span *ngIf="certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_onTrack" class="requsted-date"><span
                                    *ngIf="certificationDetailsData?.slaResolutionDeadline">for</span> {{certificationDetailsData?.slaResolutionDeadline | date: "dd MMMM, yyyy
                                'at' HH:mm '(UTC)'" | dateFormat}}</span>
                            <span *ngIf="certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_missed" class="requsted-date"><span
                                *ngIf="differenceInDaysandHours">by</span> {{daysDifference}} days {{hoursDifference}} hours {{minutesDifference}} mins</span>
                            <span *ngIf="certificationDetailsData.slaStatus == certificationEnum.certificationSlaStatus_met" class="requsted-date"><span
                                    *ngIf="certificationDetailsData?.reviewerApprovedOn">on</span> {{certificationDetailsData?.reviewerApprovedOn | date: "dd MMMM, yyyy
                                'at' HH:mm '(UTC)'" | dateFormat}}</span>
                        </div>
                    </div>
                </li>
                <li *ngIf="previousPackage?.fileName && linkageAndQueueForUpgradeFeature">
                    <div class="certification-info package-file"><span>Previous Package File</span></div>
                    <button mat-button class="align-to-label">
                        <div class="idn-grid-link-button">
                            <div class="pckg-field"  matTooltip="{{previousPackage.fileName}}" matTooltipPosition="below" matTooltipClass="my-tooltip">{{ previousPackage.fileName }}</div>
                            <mat-icon class="download-icon" (click)="downloadSingleFile(previousPackage.fileName, previousPackage.uniqueIdentifier, 'SolutionPackage', true)">download
                            </mat-icon>
                        </div>
                    </button>
                </li>
                <li class="full-width" [ngClass]="{'exceptional-approval-yes': certificationDetailsData.isExceptionalApproval == true, 'exceptional-approval-no': certificationDetailsData.isExceptionalApproval == false}">
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_exceptionApproval}}</span> {{ certificationDetailsData.isExceptionalApproval == true ? "YES" : "NO" }} </div>
                </li>
                <li class="full-width">
                    <div class="certification-info"><span>{{certificationEnum.certification_approver_Field_requestorNote}}</span> {{ certificationDetailsData.commentByRequestor }}</div>
                </li>
            </ul>
        </div>
    </div>

    <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
        <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
    </button>
    <div class="pss-form-button-wrapper certification-details-footer">  
        <button mat-raised-button color="primary" (click)="resubmitForVulnerabilityScan(certificationDetailsData.id)" *ngIf = " (certificationDetailsData?.veracodeScanStatus == 'Failed' && certificationDetailsData?.veracodeScanScore == null) && (hasGlobalDataAccess || canApproveCertification) && certificationDetailsData.reTriggerCount != 3 ">
        <span>{{certificationEnum.certification_approver_List_Field_Resubmit_vulnerability_scan}}</span>
        </button>
        
        <button mat-stroked-button class="idn-chips-download" (click)="withdrawRequest(certificationDetailsData)" *ngIf = "(certificationDetailsData.status == certificationStatus.reviewRequested) && (isRequestorWithdraw || (hasGlobalDataAccess && isInitiateAutomaticCertificationPermission)) && (!certificationDetailsData.isExceptionalApproval) && (certificationDetailsData.veracodeScanStatus == 'In Progress' || certificationDetailsData.veracodeScanStatus == 'InitialState' || certificationDetailsData.veracodeScanStatus ==null)" >
            <mat-icon>transit_enterexit</mat-icon><span>{{certificationEnum.certification_approver_List_Field_withdraw_request}}</span>
        </button>
        <button mat-stroked-button color="primary" class="check-list-btn" *ngIf="certificationDetailsData.status == certificationStatus.certified || certificationDetailsData.status == certificationStatus.rejected" (click)="certificationChecklist(certificationDetailsData)">
            <span>{{certificationEnum.certification_approver_Field_CertificationCheckList_heading}}</span>
        </button>
        <button mat-stroked-button class="idn-chips-download" (click)="downloadCertificationFiles(certificationDetailsData)">
            <mat-icon>download</mat-icon><span>{{certificationEnum.certification_approver_Field_downloadFiles}}</span>
        </button>
        <button mat-stroked-button class="idn-button-secondary" 
            *ngIf="certificationDetailsData.status == certificationStatus.systemCheckFailed && isExceptionalApproval"
            (click)="initiateManualCertification(certificationDetailsData)">
            <mat-icon>start</mat-icon>
            {{certificationEnum.certification_List_Field_initiateManual_btn}}{{certificationEnum.certification_List_Field_ExceptionApproval}}
        </button>
        <button  mat-stroked-button class="idn-button-secondary" 
            *ngIf="certificationDetailsData.status == certificationStatus.systemCheckSuccess && isInitiateManualCertification"
            (click)="initiateManualCertification(certificationDetailsData)">
            <mat-icon>start</mat-icon>
            {{certificationEnum.certification_List_Field_initiateManual_btn}}
        </button>
        <button mat-stroked-button class="idn-button-secondary" *ngIf = "(certificationDetailsData.status == certificationStatus.reviewRequested)&& isInitiateReview && (certificationDetailsData.veracodeScanStatus == null && isExceptionalApproval) || (( certificationDetailsData?.veracodeScanStatus == 'Failed' && certificationDetailsData?.reTriggerCount == 3 && certificationDetailsData.status != certificationStatus.certified && certificationDetailsData.status != certificationStatus.reviewInProgress && certificationDetailsData.status != certificationStatus.rejected && isInitiateReview) || (certificationDetailsData?.veracodeScanStatus == 'Successful' && (certificationDetailsData.status != certificationStatus.reviewInProgress && certificationDetailsData.status != certificationStatus.certified && certificationDetailsData.status != certificationStatus.rejected) ) && (certificationDetailsData?.veracodeScanStatus != 'InitialState' && certificationDetailsData?.veracodeScanStatus != 'In Progress') && isInitiateReview ) || (certificationDetailsData?.veracodeScanStatus == 'Failed' && certificationDetailsData?.veracodeScanScore != null && certificationDetailsData.status != certificationStatus.rejected)" 
            (click)="((isInitiateReview == true) && (certificationDetailsData.status == certificationStatus.reviewRequested)) ? initiateReviewbyReviewer(certificationDetailsData) : ''">
            <mat-icon>rule</mat-icon>
            {{certificationEnum.certification_approver_List_Field_initiateReview_btn}}
        </button>
        <button mat-stroked-button class="idn-button-secondary" *ngIf="(certificationDetailsData.status == certificationStatus.reviewInProgress) && isCompleteReview" (click)="completeReview(certificationDetailsData)">
            <mat-icon>fact_check</mat-icon><span>{{certificationEnum.certification_approver_Field_completeReview_btn}}</span>
        </button>
        <button mat-stroked-button (click)="onCancel()">{{certificationEnum.certification_approver_Field_cancel_btn}}</button>
    </div>
</div>
<app-update-certification *ngIf="isUpdateCertification"></app-update-certification>
<app-download-files *ngIf="downloadFilesActive"></app-download-files>
<app-certification-checklist *ngIf="isCertificationChecklist"></app-certification-checklist>
<app-intiate-certification *ngIf="intiateCertificationExceptionDrawerActive" [certDetailsData]="certDetails" ></app-intiate-certification>
