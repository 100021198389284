<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [instanceTools]="true"
    [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>
<div class="page-container">
    <div class="idn-acc-wrapper" *ngIf="selectedValue != instanceEnum.instance_view_esign_troubleshooter_header">
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">Instance Details</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            CUSTOMER NAME
                        </div>
                        <div class="idn-view-body-items-data">{{ViewData.customerName}}</div>
                    </div>
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            CNAME</div>
                        <div class="idn-view-body-items-data">{{ViewData.cname}}</div>
                    </div>

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            INSTANCE TYPE</div>
                        <div class="idn-view-body-items-data">{{ViewData.instanceType}}</div>
                    </div>
                    
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            ICI VERSION</div>
                        <div class="idn-view-body-items-data">{{ViewData?.iciVersion}}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </div>
    <app-card [ViewData]="instanceEsignData" *ngIf="selectedValue == instanceEnum.instance_view_esign_troubleshooter_header"></app-card>
    <div class="error" *ngIf="showError">
        <mat-icon class="warning-icon">warning </mat-icon>
       <span *ngIf='selectedValue == "Instance Backup"'>{{instanceEnum.instance_backup_show_error_message}}</span>
       <span *ngIf="selectedValue == instanceEnum.instance_view_esign_troubleshooter_header">Your previous Esign troubleshooting request for {{ViewData.cname}} is in progress. Please wait until it is completed.</span>
     </div>
    <div>
        <div class="noaction" *ngIf='selectedValue == ""'>
            <mat-icon class="ici_no_action_icon" svgIcon="no_actionTaken_icon"></mat-icon>
            <h3>{{this.instanceEnum.instance_View_no_action_header}}</h3>
            <p>{{this.instanceEnum.instance_View_no_action_body}}</p>
        </div>

        <div>
            <div class="ici-left-panel" *ngIf='selectedValue == "Application Logs"'>
                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_view_application_log_start_datetime}}</mat-label>
                    <input id="appStartDate" [(ngModel)]="getStartValueappLog" [min]="minAppStartDate"
                        [max]="maxAppStartDate" matInput type="datetime-local"
                        (ngModelChange)="applicationStartDateValueChange($event)" required
                        (change)="validateApplicationLogDates($event.target)">
                </mat-form-field>
                <mat-error *ngIf="startDateError">{{startDateErrorMsg}}</mat-error>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_view_application_log_end_datetime}}</mat-label>
                    <input id="appEndDate" [(ngModel)]="getEndValueappLog" matInput type="datetime-local"
                        [min]="setApplicationMinEndDate" [max]="maxAppEndDate"
                         required
                        (change)="validateApplicationLogDates($event.target)" [disabled]="startDateError">
                </mat-form-field>
                <mat-error *ngIf="endDateError">{{endDateErrorMsg}}</mat-error>

                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_view_application_log_search_string}}</mat-label>
                    <input matInput [(ngModel)]="getSearchValueappLog">
                </mat-form-field>
            </div>
            <div class="ici-left-panel" *ngIf='selectedValue == "Database Logs"'>
                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_select_database_log}}</mat-label>
                    <mat-select [(ngModel)]="selectdbvalue" matNativeControl
                        (selectionChange)="databaseTableChange($event)" required>
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel="Search Database tables"
                                noEntriesFoundLabel="No data found" [formControl]="columnFilterCtrldbtable">
                            </ngx-mat-select-search>
                        </mat-option>
                        <mat-option  *ngFor="let value of selectdbtablenames" [value]="value">
                            <span>{{value}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_view_database_log_start_datetime}}</mat-label>
                    <input id="dbStartDate" [(ngModel)]="getStartValuedbLog" matInput type="datetime-local"
                        [min]="minAppStartDate" [max]="maxAppStartDate"
                        (ngModelChange)="databaseStartDateValueChange($event)"
                        (change)="validateDatabaseLogDates($event.target)" [disabled]="hideDateTime" required>
                </mat-form-field>
                <mat-error *ngIf="startDateError">{{startDateErrorMsg}}</mat-error>

                <mat-form-field class="pss-block-form" >
                    <mat-label>{{instanceEnum.instance_view_database_log_end_datetime}}</mat-label>
                    <input id="dbEndDate" [(ngModel)]="getEndValuedbLog" matInput type="datetime-local"
                        [min]="setDatabaseMinEndDate" [max]="maxAppEndDate"
                        (change)="validateDatabaseLogDates($event.target)" required [disabled]="startDateError || hideDateTime">
                </mat-form-field>
                <mat-error *ngIf="endDateError">{{endDateErrorMsg}}</mat-error>

              
            </div>
            <div class="ici-left-panel" *ngIf='selectedValue == "Worker Task Logs"'>
                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_view_worker_task_log_date}}</mat-label>
                    <input id="workertaskStartDate" [(ngModel)]="workerTaskLogDate"
                        matInput type="date" required
                        [min]="minWorkerTaskDate" [max]="workertaskMaxDate"
                        (change)="validateWorkerTaskLogDates($event.target)">
                </mat-form-field>
                <mat-error *ngIf="validWorkerTaskLogDate">{{validDateMessage}}</mat-error>
            </div>
            <div class="ici-left-panel" *ngIf='selectedValue == "Instance Backup"'>
                <div class="instance-backup-checkbox">
                    <mat-checkbox class="master-check" [checked]="allComplete" [color]="backup.color"
                        (change)="setAll($event.checked)">
                     {{backup.name}}
                    </mat-checkbox>
                    <div class="subtask-check" *ngFor="let subtask of backup.subtasks">
                        <mat-checkbox [(ngModel)]="subtask.completed" [color]="subtask.color" (ngModelChange)="updateAllComplete()">
                          {{subtask.name}}
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="ici-left-panel" *ngIf='selectedValue == "Role Restart"'>              
                <div>
                        <mat-radio-group aria-label="Select an option" (change)="roleRestartSelectionChange($event.value)">
                            <div class="role-restart-radioBtn">
                                <mat-radio-button value="RoleRestart" checked="true" [color]="AddInRoles.color">{{instanceEnum.instance_ici_role_restart_header}}</mat-radio-button>                                
                            </div>
                            <div class="role-restart-radioBtn">
                                <mat-radio-button value="AddInsRoleRestart" [color]="AddInRoles.color">{{instanceEnum.instance_addin_role_restart_header}}</mat-radio-button>                               
                            </div>
                    </mat-radio-group>
                </div> 
                <div class="left-content">
                    <div *ngIf = !addInRole>
                    <b>{{instanceEnum.instance_ici_role_restart_header}}</b><br><br>
                    {{instanceEnum.instance_ici_role_restart_desc}}<br>
                    </div>
                    <div *ngIf = addInRole>
                    <b>{{instanceEnum.instance_addin_role_restart_header}}</b><br><br>
                    {{instanceEnum.instance_addin_role_restart_desc}}
                    </div>
                </div>          
                    <div class="instance-backup-checkbox" *ngIf = addInRole>                        
                        <mat-checkbox class="master-check" [checked]="allAddInRoles" [color]="AddInRoles.color"
                            (change)="selectAllAddIn($event.checked)">
                         {{AddInRoles.name}}
                        </mat-checkbox>
                        <div class="subtask-check" *ngFor="let addIns of AddInRoles.addIns">
                            <mat-checkbox [(ngModel)]="addIns.completed" [color]="addIns.color" (ngModelChange)="updateAllAddInRoles()">
                              {{addIns.name}}
                            </mat-checkbox>
                        </div>
                    </div>                     
            </div>
            <div class="ici-left-panel" *ngIf='selectedValue == instanceEnum.instance_view_esign_troubleshooter_header'>
                <mat-form-field class="pss-block-form">
                    <mat-label>{{instanceEnum.instance_select_esign_account}}</mat-label>
                    <mat-select [(ngModel)]="selectedEsignAccount" matNativeControl aria-hidden="true"
                        (selectionChange)="esignProviderChange()" required>
                        <mat-option  *ngFor="let value of instanceEsignAccounts" [value]="value?.Name">
                            <span>{{value?.Name}}</span>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="hangfire-cls" *ngIf='selectedValue == "Hangfire Dashboard" && isRequestAccess && !(featureCategoryId == 3 && requestStatus == this.approvalStatus.Pending || isRequestPending)'>
                <div class="ici-tools-info1"><mat-icon>info</mat-icon> <div> Access is permanent for non-production instances and time-limited for production instances.</div>
            </div>
                <div>
                    <mat-form-field class="pss-block-form">
                        <mat-label class="mat-label-text">{{instanceEnum.instanceTools_reason_lbl}}</mat-label>
                        <textarea type="text" (keyup)="validateComments()"  [(ngModel)]="comments" name="comment" matInput required></textarea>
                    </mat-form-field>
                </div>
            </div>
            <div *ngIf="(featureCategoryId == 3 && (requestStatus == this.approvalStatus.Pending || isRequestPending)) && !isRequestAccess">
                <div class="req-pending">
                    <div class="icon-cls">
                        <mat-icon class="time-up-icon" svgIcon="request_pending"></mat-icon>
                    </div>
                    <div class="pending-text">
                       {{instanceEnum.instanceTools_RequestAccessPending_msg}} <br> <b>{{instanceEnum.instanceTools_requestAcess_requestedOn}}</b> : {{hangfireDashBoardRequestDetails[0].requestedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'"}}
                    </div>
                </div>
            </div>

            <div *ngIf="selectedValue == 'Hangfire Dashboard' && (featureCategoryId == 3 && requestStatus == this.approvalStatus.Rejected) && !isRequestAccess && !(requestStatus == this.approvalStatus.Pending || isRequestPending)">
                <div class="rejected-heading">Hangfire Dashboard Access Denied.</div>
                <div class="reason-field">
                    <div class="reason-lbl">{{instanceEnum.instanceTools_reason_lbl}}</div>
                    <div>{{hangfireDashBoardRequestDetails[0].approverComment}}</div>
                </div>
                    <div class="request-access">
                       <span class="request-access-text"> {{instanceEnum.instanceTools_requestAccess_msg}} </span>  <a href="#" (click)="requestAccess($event) ;$event.preventDefault()"> {{instanceEnum.instanceTools_RequestAccess_btn}}</a>.
                    </div>
            </div>
           
            <div *ngIf="(selectedValue != '' && (selectedValue != 'Application Logs' && selectedValue != 'Database Logs' && selectedValue != 'Worker Task Logs' && selectedValue != 'Instance Backup'  && selectedValue != 'Role Restart' && selectedValue != instanceEnum.instance_view_esign_troubleshooter_header && selectedValue != 'Hangfire Dashboard'))"  class="action-info-desc">
                <div class="ici-tools-info1"><mat-icon>info</mat-icon>
                    <div> {{instanceEnum.instance_view_info_note}}</div>
                </div>
            </div>
           
            <div class="ici-right-panel" >
                <div *ngIf="(selectedValue != '' && (selectedValue == 'Application Logs' || selectedValue == 'Database Logs' || selectedValue == 'Worker Task Logs' || selectedValue == 'Instance Backup' || selectedValue == 'Role Restart' || selectedValue == instanceEnum.instance_view_esign_troubleshooter_header))" >
                    <div class="ici-tools-info1"><mat-icon>info</mat-icon>
                        <div>{{instanceEnum.instance_view_info_note}}</div>
                    </div>
                </div>
                <mat-card class="action-details" *ngIf='selectedValue == "Application Logs"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_application_log_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_application_log_desc}} <b>History</b> {{instanceEnum.instance_view_history_button_text}}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="action-details" *ngIf='selectedValue == "Database Logs"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_database_log_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_database_log_desc}} <b>History</b> {{instanceEnum.instance_view_history_button_text}}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="action-details" *ngIf='selectedValue == "Worker Task Logs"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_workertask_log_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_workertask_log_desc}} <b>History</b> {{instanceEnum.instance_view_history_button_text}}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="action-details" *ngIf='selectedValue == "Instance Backup"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_instance_backup_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_instance_backup_desc1}} <b> Confirm Action. </b> {{instanceEnum.instance_view_instance_backup_desc2}}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="action-details" *ngIf='selectedValue == "Role Restart"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_role_restart_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_role_restart_desc}} <b>History</b> {{instanceEnum.instance_view_history_button_text}}</div>
                    </mat-card-content>
                </mat-card>
                <mat-card class="action-details" *ngIf='selectedValue == instanceEnum.instance_view_esign_troubleshooter_header'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_esign_troubleshooter_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_esign_troubleshoot_desc}}</div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div>
                <mat-card class="action-details-task" *ngIf='selectedValue == "Cache Flush"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_cache_flush_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_cache_flush_desc}} <b>History</b> {{instanceEnum.instance_view_history_button_text}}</div>
                    </mat-card-content>
                </mat-card>               
                <mat-card class="action-details-task" *ngIf='selectedValue == "Task Restart"'>
                    <mat-card-header class="card-header-actions">
                        <mat-card-title
                            class="card-title-actions">{{instanceEnum.instance_view_task_restart_header}}</mat-card-title>

                    </mat-card-header>
                    <mat-card-content class="action-desc">
                        <div class="action-description">{{instanceEnum.instance_view_task_restart_desc}} <b>History</b> {{instanceEnum.instance_view_history_button_text}}</div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="pss-form-button-wrapper">
        <button mat-flat-button color="primary" [disabled]="validateConfirmAction || showError"
            (click)="action(selectedValue)">Confirm Action</button>
        <button mat-stroked-button
            (click)="onCancel()">{{instanceEnum.instance_tools_cancel_button}}</button>
    </div>
    <div class="help-icon">
        <button mat-button color="primary" class="no-hover-effect" aria-label="Help Button">
            <mat-icon class="help-icon" svgIcon="HELP_ICON" (click)="redirectToHelp();$event.preventDefault();"></mat-icon>
        </button>
    </div>
   
</div>