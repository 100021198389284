import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { NotificationComponent } from './notification-component/notification.component';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackBar: MatSnackBar
  ) { }

  showNotification(message: string, errorClass: string, errorType: string){
    this.snackBar.openFromComponent(NotificationComponent, {
      data: {
        message: message,
        alertType: errorType,
        errorClass: errorClass
      },
      duration: 5000,
      horizontalPosition: 'right',
      verticalPosition: 'bottom',
      panelClass: errorClass
    })
  }
}
