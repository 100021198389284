<app-pageheader [breadCrumbs]="this.breadCrumbItems" [archive]="false" ></app-pageheader>
<div class="page-container">
    <div class="idn-view-wrapper">
      <div class="idn-view-header">
        <div class="data-left">
          <div class="idn-view-header-label">{{this.sharedEnum.view_page_instance_heading}} :</div>
          <div class="idn-view-header-data">
            <h3>{{viewData?.instanceCName}}</h3>
            <em><b>Created on: {{viewData?.requestedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" }} by {{viewData?.requestedBy}}</b></em>
          </div>
        </div>
      </div>
    <div class="pss-steps-container">
        <div class="idn-view-body">
            <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.view_approval_request_heading}}</div>
                        <div class="idn-view-body-items-data"><span *ngIf="viewData?.featureCategoryId">{{viewData?.featureCategory}}</span></div> 
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.view_partnerName_label}}</div>
                        <div class="idn-view-body-items-data">{{viewData?.partnerName}}</div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.view_customerName_label}}</div>
                        <div class="idn-view-body-items-data">{{viewData?.customerName}} </div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.cName_label}}</div>
                        <div class="idn-view-body-items-data">{{viewData?.instanceCName}}</div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.requestedBy_label}}</div>
                        <div class="idn-view-body-items-data">{{viewData?.requestedBy}} <br>on {{viewData?.requestedAt | date: "dd MMMM, yyyy 'at' HH:mm '(UTC)'" }}</div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1" *ngIf="viewData?.featureCategoryId == 1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.duration_label}}</div>
                        <div  *ngIf="viewData?.statusId != approvalStatus.approved" class="idn-view-body-items-data">{{viewData?.requestedDuration}} Hours</div>
                        <div *ngIf="viewData?.statusId == approvalStatus.approved" class="idn-view-body-items-data">{{viewData?.approvedDuration}} Hours</div>
                    </div>
                </mat-grid-tile>
                <mat-grid-tile [colspan]="1">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.status_label}}</div>
                        <div  [ngClass]="viewData?.statusId == approvalStatus.pending ? 'pending-div': 'idn-view-body-items-data'">
                            <span class="pending-cls" *ngIf="viewData?.statusId == approvalStatus.pending "><mat-icon class="pending">hourglass_empty</mat-icon></span>
                            <span class="approved-cls" *ngIf="viewData?.statusId == approvalStatus.approved "><mat-icon class="approved">check_circle_outline</mat-icon></span>
                            <span class="approved-cls"*ngIf="viewData?.statusId == approvalStatus.rejected "><mat-icon class="rejected">cancel</mat-icon></span> 
                         {{viewData?.status}} 
                        </div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
            <mat-grid-list cols="4" rowHeight="80px">
                <mat-grid-tile [colspan]="3">
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">{{this.sharedEnum.requester_comment_label}}</div>
                        <div class="idn-view-body-items-data">{{viewData?.requesterComment}}</div>
                    </div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>
    </div>
    <div class="pss-form-button-wrapper view-request-footer"> 
        <button class="approve-btn" *ngIf="viewData?.statusId == approvalStatus.pending" mat-button (click)="approveRequest()">
            {{this.sharedEnum.approve_btn_label}}
        </button>
        <button class="reject-btn" *ngIf="viewData?.statusId == approvalStatus.pending" mat-button (click)="rejectRequest()">
            {{this.sharedEnum.reject_btn_label}}
        </button>
        <button class="cancel-btn" mat-button (click)="cancelRequest()">
            {{this.sharedEnum.cancel_btn_label}}
        </button>
    </div>