<app-pageheader class="pageheader" [pageTitle]="'Instance Requests'" [breadCrumbs]="this.breadCrumbItems">
</app-pageheader>

<div class="page-container">
    <div class="idn-acc-wrapper">
        <mat-card class="idn-acc-list-card">
            <mat-card-header class="card-header">
                <mat-card-title class="card-title">Instance Details</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="idn-view-body">
                <div class="card-content">

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            CUSTOMER NAME
                        </div>
                        <div class="idn-view-body-items-data">{{ViewData.customerName}}</div>
                    </div>
                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            CNAME</div>
                        <div class="idn-view-body-items-data">{{ViewData.cname}}</div>
                    </div>

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            INSTANCE TYPE</div>
                        <div class="idn-view-body-items-data">{{ViewData.instanceType}}</div>
                    </div>

                    <div class="idn-view-body-items">
                        <div class="idn-view-body-items-label">
                            ICI VERSION</div>
                        <div class="idn-view-body-items-data">{{ViewData?.iciVersion}}</div>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div class="config-tools-history" *ngIf="configData">
        <span>{{instanceEnum.instance_view_modified_config_keys_header}}</span>
        <div class="idn-info">
            <mat-icon>info</mat-icon>
            <h4>
                {{instanceEnum.instance_tools_modified_config_keys_log_short_desc}}
            </h4>
        </div>
    </div>

    <div class="display-list">
        <mat-form-field class="select-input" *ngIf="!configData">
            <mat-label>Filter by Action</mat-label>
            <mat-select disableOptionCentering name="action" [(value)]="requestType"
                (selectionChange)="requestTypeChange($event)">
                <mat-option *ngFor="let action of Actions" [value]="action.actionId">
                    {{action.actionName}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="select-input" *ngIf="!configData">
            <mat-label>Filter by Status</mat-label>
            <mat-select disableOptionCentering name="status" [(value)]="status"
                (selectionChange)="statusChange($event)">
                <mat-option *ngFor="let status of Status" [value]="status.statusId">
                    {{status.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="search-input" *ngIf="configData">
            <mat-label>Search</mat-label>
            <input type="text" (keydown.enter)="doFilter()" #search_input matInput
                placeholder="Search by name, value, valueType etc...">
            <span class="idn-custom-search" matSuffix>
                <mat-icon (click)="doFilter()">search</mat-icon>
                <button type="button" mat-icon-button aria-label="Clear" (click)="ClearInput($event,'search')">
                    <mat-icon>close</mat-icon>
                </button>
            </span>
        </mat-form-field>
        <button class="clear-filter-tools" mat-stroked-button color="primary" (click)="clearFilter()">
            <mat-icon class="icon-btn-spacer">filter_list_off</mat-icon>
            <span>Clear Filter</span>
        </button>
    </div>
    <div class="idn-info" *ngIf="!noContent && !configData">
        <mat-icon>info</mat-icon>
        <h4>
            {{instanceEnum.instance_tools_history_screen_message}}
        </h4>
    </div>
    <div class="table-scroll" tabindex="0" *ngIf="!configData">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" *ngIf="!noContent; else noContentTemplate">

            <ng-container matColumnDef="action">
                <th mat-header-cell *matHeaderCellDef> Action Requested </th>
                <td class="row-width" mat-cell *matCellDef="let element"> {{element.requestType =='AddIns'? 'Role Restart' : element.requestType }} </td>
            </ng-container>


            <ng-container matColumnDef="requesterdetails">
                <th mat-header-cell *matHeaderCellDef> Requester details </th>
                <td class="row-width" mat-cell *matCellDef="let element"> {{element.createdBy}} <br>
                    <span class="date-created"> Requested on: {{element.createdAt | date:
                        'MM/dd/YYYY HH:mm'}} </span>
                </td>
            </ng-container>


            <ng-container matColumnDef="criteria">
                <th mat-header-cell *matHeaderCellDef> Specified criteria </th>
                <td class="criteria-text"
                    matTooltip="{{(element?.requestType == 'Worker Task Logs')? 'Date:' + (element?.requestedFrom | date :'MM/dd/YYYY') : (element?.requestType == 'Application Logs' || element?.requestType == 'Database Logs')? 'From:' +element?.requestedFrom :''}} {{element?.requestedTo?  'To:' + element?.requestedTo : ''}} {{element?.requestType == 'Database Logs'? 'Database table : ' + element?.searchText : (element?.requestType == 'Application Logs' && element?.searchText)? 'Search Text:' + element?.searchText : ''}} "
                    matTooltipClass="my-tooltip" matTooltipPosition="above" mat-cell *matCellDef="let element">
                    <span *ngIf="element?.searchText && element.requestType == 'Instance Backup'"><b>Backup :
                        </b>{{element?.searchText}} </span>
                    <span *ngIf="element.requestType == 'Role Restart'" class="text-wrap"><b>ICI: </b>Role Restart</span>
                    <span *ngIf="element.requestType == 'AddIns' && element.selectedAddIns" class="text-wrap"><b>Add-ins: </b>
                            {{element.selectedAddIns.join(',')}}</span>
                    <span *ngIf="element?.searchText && !element?.searchText.includes('Export') && element.requestType == 'Esign TroubleShooting'"> {{element?.searchText}} </span>
                    <span *ngIf="element?.searchText && element?.searchText.includes('Export') && element.requestType == 'Esign TroubleShooting'"> {{element?.searchText?.split(' ')[0]}}<br> {{element?.searchText?.split(' ')[1]}}</span>
                    <span *ngIf="element?.requestedFrom && element.requestType != 'Worker Task Logs'"><b>From:</b>
                        {{element?.requestedFrom | date: 'MM/dd/YYYY
                        HH:mm'}} </span><span *ngIf="element.requestType == 'Worker Task Logs'"><b>Date:</b>
                        {{element?.requestedFrom | date: 'MM/dd/YYYY'}}</span> <span
                        *ngIf="element?.requestedTo"><b>To:</b> {{element?.requestedTo | date:
                        'MM/dd/YYYY HH:mm' }} </span><br> <span
                        *ngIf="element?.searchText && element.requestType == 'Application Logs'"><b> Search
                            Text : </b>{{element?.searchText}} </span><span
                        *ngIf="element?.searchText && element.requestType == 'Database Logs'"><b> Database
                            table : </b>{{element?.searchText}} </span><span
                        *ngIf="!element?.searchText && !element?.requestedTo && !element?.requestedFrom && !element.selectedAddIns && element.requestType != 'Role Restart'"><b>-</b></span>                       
                </td>
            </ng-container>


            <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef>
                    <div class="action-status">
                        Status
                        <div class="refresh-btn">
                          <mat-icon (click)="refresh($event)" >refresh</mat-icon>
                        </div>
                    </div>
                       </th>
                <td class="row-width" mat-cell *matCellDef="let element">
                    <div class="pss-chips-status">
                        <span
                            [ngClass]="element.status == 'Success'? 'marker marker__active': element.status == 'In Progress' ?'marker marker__in-progress' : 'marker marker__failed' "></span>
                        <span class="label" class="label" tabindex="0" aria-label="Status"
                            matTooltip="{{(element?.requestType == 'Instance Backup')? (element?.status == 'Success')? instanceEnum.instance_backup_status_success_tooltip : (element?.status == 'Failed')? instanceEnum.instance_backup_status_failed_tooltip : ''  : '' }}"
                            matTooltipClass="my-tooltip" matTooltipPosition="above">{{element.status}} </span>
                    </div>
            </ng-container>

            <ng-container matColumnDef="download">
                <th mat-header-cell *matHeaderCellDef id="download-header"> Actions </th>
                <td class="downloadcolumn" mat-cell *matCellDef="let element">
                    <div class="dwnld-action">
                        <div class="view-download-action"
                            *ngIf="element.fileName != null && element.requestType != 'Instance Backup' && element.requestType != 'Esign TroubleShooting'">
                            <button mat-icon-button aria-label="View" class="view-icon"
                                *ngIf="element.requestType == 'Application Logs' || element.requestType == 'Database Logs'" (click)="downloadLogs(element, true)">
                                <mat-icon matTooltip="View logs" matTooltipClass="my-tooltip">remove_red_eye</mat-icon>
                            </button>
                            <button mat-button class="idn-grid-link-button" (click)="downloadLogs(element)" aria-label="download Button"
                                *ngIf="element.requestType != 'Instance Backup'">
                                <div class="button-name">
                                    <div class="idn-view-body-items-button-data">
                                    </div>
                                    <mat-icon class="download-icon" matTooltip="Download Logs" 
                                        matTooltipPosition="below" matTooltipClass="my-tooltip">download </mat-icon>
                                </div>
                            </button>

                        </div>
                        <div class="view-download-action"
                            *ngIf="element.requestType == 'Instance Backup' && element.requestType != 'Esign TroubleShooting' && (element.status == 'Success' || element.status == 'Failed')">
                            <button mat-button class="idn-grid-link-button" aria-label="download Button"
                                (click)="redirectToBackupLink(element.workFlowUrl)">
                                <div class="button-name">
                                    <div class="idn-view-body-items-button-data">
                                        Workflow URL
                                    </div>
                                </div>
                            </button>
                            <button mat-button class="idn-grid-link-button" [cdkCopyToClipboard]="element.workFlowUrl" aria-label="Copy Button">
                                <mat-icon class="idn-grid-link-button button-name download-icon" matTooltip="Copy link"
                                    matTooltipPosition="below" matTooltipClass="my-tooltip">content_copy </mat-icon>
                            </button>
                        </div>
                        <div class="view-download-action"
                            *ngIf="element.requestType == 'Esign TroubleShooting' && element.status == 'Success'">
                            <button mat-icon-button aria-label="View" class="view-icon" (click)="navigateEsignView(element)" *ngIf="!element?.searchText.includes('Export')">
                                <mat-icon matTooltip="View logs" matTooltipClass="my-tooltip">remove_red_eye</mat-icon>
                            </button>
                            <button mat-button class="idn-grid-link-button" (click)="downloadLogs(element)" aria-label="download Button"
                                *ngIf="element.requestType != 'Instance Backup'">
                                <div class="button-name" *ngIf="element.fileName != null && element?.searchText.includes('Export')">
                                    <div class="idn-view-body-items-button-data">
                                    </div>
                                    <mat-icon class="download-icon" matTooltip="Download Logs" matTooltipPosition="below"
                                        matTooltipClass="my-tooltip">download </mat-icon>
                                </div>
                            </button>
                        </div>
                        <span class="req-msg"
                            *ngIf="element.status == 'Success' && element.fileName == null && (element.requestType == 'Worker Task Logs' ||element.requestType == 'Database Logs' ||element.requestType == 'Application Logs' )">{{element.requestMessage}}</span>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div *ngIf="configData">
        <div class="table-scroll config-key" aria-hidden="true" *ngIf="!noConfigKeyContent; else noContentTemplate">
            <table mat-table [dataSource]="configDataSource" class="mat-elevation-z8" matSort #empTbSort="matSort"
                (matSortChange)="announceSortChange($event)">

                <!-- Name Column -->
                <ng-container matColumnDef="configname">
                    <th mat-header-cell id="mat-column-name" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by Name">
                        Name</th>
                    <td mat-cell *matCellDef="let element" matTooltipClass="tooltip-content"
                        matTooltip="{{element.configName}}" matTooltipPosition="above"
                        [matTooltipDisabled]="isDisabled(nameToolTip)" #nameToolTip>
                        {{element.configName}}
                    </td>
                </ng-container>

                <!-- value Column -->
                <ng-container matColumnDef="value">
                    <th mat-header-cell id="mat-column-value" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by Value">
                        Value</th>
                    <td mat-cell *matCellDef="let element" #valueToolTip>
                        <div class="view-data">
                            <div class="large-data" #content>
                                {{element.value}}
                            </div>
                            <button class="show-button" *ngIf="!isDisabled(content)" (click)="viewMoreData(element)">View more</button>
                        </div>
                    </td>
                </ng-container>

                <!-- value Type Column -->
                <ng-container matColumnDef="valuetype">
                    <th mat-header-cell id="mat-column-valueType" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by ValueType">
                        Value Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.valueType}}
                    </td>
                </ng-container>

                <!-- OverrideType Column -->
                <ng-container matColumnDef="overridetype">
                    <th mat-header-cell id="mat-column-overrideType" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by OverrideType">
                        Override Type</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.overrideType}}
                    </td>
                </ng-container>

                <!-- IsEncrypted Column -->
                <ng-container matColumnDef="isencrypted">
                    <th mat-header-cell id="mat-column-isEncrypted" *matHeaderCellDef mat-sort-header
                        sortActionDescription="Sort by IsEncrypted">
                        Is Encrypted</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.isEncrypted}}
                    </td>
                </ng-container>

                <!-- ModifiedBy Column -->
                <ng-container matColumnDef="modifiedat">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by modifiedby">
                        Modified
                    </th>
                    <td mat-cell *matCellDef="let element"> {{element.modifiedAt | date:
                        'dd MMMM, YYYY'}} <br>
                        <span class="date-created">Modified by: {{element.modifiedBy}} </span>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="configDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: configDisplayedColumns;"></tr>
            </table>
        </div>
    </div>

    <mat-paginator #paginator [pageIndex]="currentPage" [pageSize]="10" class="mat-paginator-sticky"
        (page)="pageChanged($event)" [length]="pageLength" [pageSizeOptions]="[5, 10, 15, 20, 25, 50]"
        showFirstLastButtons
        [ngClass]="{'hide-pagination': configData ? noConfigKeyContent : noContent ? true : false}">
    </mat-paginator>
</div>
<div class="pss-form-button-wrapper">
    <button mat-stroked-button (click)="Oncancel()">Cancel</button>
</div>
<ng-template #noContentTemplate>
    <div class="idn-no-result">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="203.276"
            height="214.71" viewBox="0 0 203.276 214.71">
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_158" data-name="Rectangle 158" width="14.11" height="37.39"
                        transform="translate(0 0)" fill="#515165" />
                </clipPath>
            </defs>
            <g id="Group_559" data-name="Group 559" transform="translate(2759.66 -7619.728) rotate(42)">
                <rect id="Rectangle_152" data-name="Rectangle 152" width="11.288" height="33.863"
                    transform="translate(3187.267 7517.8)" fill="#7c7c97" />
                <rect id="Rectangle_153" data-name="Rectangle 153" width="5.644" height="33.863"
                    transform="translate(3192.911 7517.8)" fill="#515165" />
                <rect id="Rectangle_154" data-name="Rectangle 154" width="28.219" height="86.774"
                    transform="translate(3178.801 7551.663)" fill="#ababc5" />
                <path id="Union_2" data-name="Union 2"
                    d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                    transform="translate(3178.801 7617.272)" fill="#7c7c97" />
                <rect id="Rectangle_155" data-name="Rectangle 155" width="14.11" height="86.774"
                    transform="translate(3192.911 7551.663)" fill="#7c7c97" />
                <g id="Group_558" data-name="Group 558" transform="translate(3140 7426.088)">
                    <circle id="Ellipse_42" data-name="Ellipse 42" cx="53.264" cy="53.264" r="53.264"
                        transform="translate(0 0)" fill="#cfdae2" />
                    <circle id="Ellipse_43" data-name="Ellipse 43" cx="44.798" cy="44.798" r="44.798"
                        transform="translate(8.466 8.466)" fill="#f6f2f1" />
                </g>
                <g id="Mask_Group_7" data-name="Mask Group 7" transform="translate(3192.911 7617.272)"
                    clip-path="url(#clip-path)">
                    <path id="Union_1" data-name="Union 1"
                        d="M0,23.284c0-.057,0-.113,0-.17H0V0H28.219V23.114h0c0,.056,0,.113,0,.17A14.11,14.11,0,0,1,0,23.284Z"
                        transform="translate(-14.11 0)" fill="#515165" />
                </g>
            </g>
        </svg>
        <h4>No Data found</h4>
    </div>
</ng-template>