import { Component, ElementRef, numberAttribute, OnInit, ViewChild } from '@angular/core';
import { PermissionsService } from 'src/app/core/services/common/permissions.service';
import { InstanceService } from 'src/app/core/services/instance-management/instance.service';
import { InstanceManagement } from 'src/app/shared/enum/instance-management';
import { iciActionsTool } from '../instance-tools/instance-tools.component';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NotificationService } from 'src/app/shared/notification/notification.service';

const TIME_UP = `<svg id="Group_855" data-name="Group 855" xmlns="http://www.w3.org/2000/svg" width="212.011" height="198.29" viewBox="0 0 212.011 198.29">
  <path id="Path_505" data-name="Path 505" d="M474.176,659.808a.373.373,0,0,1-.374.374H262.538a.373.373,0,1,1,0-.747H473.8a.373.373,0,0,1,.374.373Z" transform="translate(-262.165 -494.574)" fill="#ccc"/>
  <circle id="Ellipse_19" data-name="Ellipse 19" cx="67.463" cy="67.463" r="67.463" transform="translate(20.081 14.391)" fill="#e6e6e6"/>
  <path id="Path_506" data-name="Path 506" d="M472.262,267.941a57.969,57.969,0,0,1-23.43,46.6c-.486.364-.982.719-1.481,1.064a58.059,58.059,0,0,1-66.277,0c-.5-.345-.994-.7-1.481-1.064a58.056,58.056,0,0,1,0-93.2c.487-.364.982-.719,1.481-1.064a58.059,58.059,0,0,1,66.277,0c.5.345.995.7,1.481,1.064A57.969,57.969,0,0,1,472.262,267.941Z" transform="translate(-326.668 -186.088)" fill="#fff"/>
  <path id="Path_537" data-name="Path 537" d="M53.029,0A53.029,53.029,0,1,1,0,53.029,53.029,53.029,0,0,1,53.029,0Z" transform="translate(34.515 28.824)" fill="rgba(238,10,10,0.62)"/>
  <path id="Path_507" data-name="Path 507" d="M531.449,226.365c-.8,0-1.6.021-2.4.056a53.027,53.027,0,0,1-2.246,105.8c1.531.133,3.079.2,4.644.2a53.029,53.029,0,0,0,0-106.058Z" transform="translate(-443.766 -197.392)" opacity="0.2"/>
  <path id="Path_508" data-name="Path 508" d="M537.889,213.892a1.729,1.729,0,0,0-1.726,1.726v7.531a1.726,1.726,0,1,0,3.451,0v-7.531A1.728,1.728,0,0,0,537.889,213.892Z" transform="translate(-450.188 -188.833)" fill="#fff"/>
  <path id="Path_509" data-name="Path 509" d="M634.966,244.048c-.486-.364-.982-.719-1.481-1.064a1.724,1.724,0,0,0-1.537.75l-4.239,6.222a1.726,1.726,0,1,0,2.853,1.942l4.239-6.222A1.713,1.713,0,0,0,634.966,244.048Z" transform="translate(-512.803 -208.794)" fill="#fff"/>
  <path id="Path_510" data-name="Path 510" d="M690.344,334.45a1.724,1.724,0,0,0-2.247-.948l-6.978,2.834a1.726,1.726,0,1,0,1.3,3.2L689.4,336.7A1.73,1.73,0,0,0,690.344,334.45Z" transform="translate(-548.921 -270.825)" fill="#fff"/>
  <path id="Path_511" data-name="Path 511" d="M437.8,249.956l-4.239-6.222a1.726,1.726,0,0,0-1.538-.75c-.5.345-.995.7-1.481,1.064a1.714,1.714,0,0,0,.167,1.629l4.239,6.222a1.725,1.725,0,1,0,2.852-1.942Z" transform="translate(-377.618 -208.794)" fill="#fff"/>
  <path id="Path_512" data-name="Path 512" d="M378.4,336.337l-6.978-2.833a1.726,1.726,0,0,0-1.3,3.2l6.979,2.834a1.726,1.726,0,0,0,1.3-3.2Z" transform="translate(-335.506 -270.826)" fill="#fff"/>
  <path id="Path_513" data-name="Path 513" d="M537.889,540.892a1.729,1.729,0,0,0-1.726,1.726v7.53a1.726,1.726,0,1,0,3.451,0v-7.53A1.728,1.728,0,0,0,537.889,540.892Z" transform="translate(-450.188 -413.227)" fill="#fff"/>
  <path id="Path_514" data-name="Path 514" d="M634.8,522.955l-4.239-6.223a1.725,1.725,0,1,0-2.852,1.943l4.239,6.222a1.725,1.725,0,0,0,1.538.75c.5-.345.995-.7,1.481-1.064A1.713,1.713,0,0,0,634.8,522.955Z" transform="translate(-512.803 -396.13)" fill="#fff"/>
  <path id="Path_515" data-name="Path 515" d="M689.4,439.337l-6.979-2.833a1.725,1.725,0,1,0-1.3,3.2l6.978,2.833a1.726,1.726,0,1,0,1.3-3.2Z" transform="translate(-548.921 -341.506)" fill="#fff"/>
  <path id="Path_516" data-name="Path 516" d="M437.346,516.277a1.726,1.726,0,0,0-2.4.455l-4.239,6.223a1.715,1.715,0,0,0-.167,1.629c.486.364.982.719,1.481,1.063a1.723,1.723,0,0,0,1.537-.75l4.239-6.222A1.725,1.725,0,0,0,437.346,516.277Z" transform="translate(-377.619 -396.131)" fill="#fff"/>
  <path id="Path_517" data-name="Path 517" d="M379.344,437.451a1.723,1.723,0,0,0-2.247-.948l-6.978,2.834a1.726,1.726,0,1,0,1.3,3.2L378.4,439.7A1.73,1.73,0,0,0,379.344,437.451Z" transform="translate(-335.506 -341.506)" fill="#fff"/>
  <path id="Path_518" data-name="Path 518" d="M466.152,385.595a3.613,3.613,0,0,1-2.952,4.163l-30.624,5.209a3.609,3.609,0,0,1-1.21-7.115l30.624-5.209A3.612,3.612,0,0,1,466.152,385.595Z" transform="translate(-376.213 -304.598)" fill="#3f3d56"/>
  <path id="Path_519" data-name="Path 519" d="M570.622,419.992a2.357,2.357,0,0,1-3.329.013L534.327,387.3a2.354,2.354,0,0,1,3.315-3.341l32.967,32.708A2.356,2.356,0,0,1,570.622,419.992Z" transform="translate(-448.45 -305.066)" fill="#3f3d56"/>
  <circle id="Ellipse_21" data-name="Ellipse 21" cx="4.079" cy="4.079" r="4.079" transform="translate(83.465 77.774)" fill="#ccc"/>
  <path id="Path_520" data-name="Path 520" d="M375.792,145.151c-6.261,9.844-15.815,16.377-27.6,20.669a2.215,2.215,0,0,1-3.1-.445h0a19.455,19.455,0,1,1,30.966-23.561l.177.236A2.215,2.215,0,0,1,375.792,145.151Z" transform="translate(-316.345 -134.031)" fill="#e6e6e6"/>
  <path id="Path_521" data-name="Path 521" d="M628.5,141.814a19.455,19.455,0,1,1,30.965,23.561h0a2.215,2.215,0,0,1-3.1.445c-10.937-4.188-19.907-11.434-27.6-20.669a2.215,2.215,0,0,1-.445-3.1Z" transform="translate(-513.125 -134.031)" fill="#e6e6e6"/>
  <path id="Path_522" data-name="Path 522" d="M619.176,580.682a1.729,1.729,0,0,0-.663,2.349l9.959,17.8a1.726,1.726,0,0,0,3.012-1.686l-9.96-17.8A1.729,1.729,0,0,0,619.176,580.682Z" transform="translate(-506.548 -440.382)" fill="#e6e6e6"/>
  <path id="Path_523" data-name="Path 523" d="M431.471,581.342l-9.96,17.8a1.726,1.726,0,1,0,3.012,1.685l9.96-17.8a1.726,1.726,0,0,0-3.012-1.686Z" transform="translate(-371.36 -440.379)" fill="#e6e6e6"/>
  <circle id="Ellipse_22" data-name="Ellipse 22" cx="5.334" cy="5.334" r="5.334" transform="translate(118.608 154.336)" fill="#e6e6e6"/>
  <circle id="Ellipse_23" data-name="Ellipse 23" cx="5.334" cy="5.334" r="5.334" transform="translate(122.373 17.842)" fill="#e6e6e6"/>
  <circle id="Ellipse_24" data-name="Ellipse 24" cx="5.334" cy="5.334" r="5.334" transform="translate(81.896 5.291)" fill="#e6e6e6"/>
  <circle id="Ellipse_25" data-name="Ellipse 25" cx="5.334" cy="5.334" r="5.334" transform="translate(43.301 17.215)" fill="#e6e6e6"/>
  <circle id="Ellipse_26" data-name="Ellipse 26" cx="5.334" cy="5.334" r="5.334" transform="translate(46.753 154.336)" fill="#e6e6e6"/>
  <path id="Path_524" data-name="Path 524" d="M642.883,481.663a2.672,2.672,0,0,0,4.064.526l8.37,4.485-.448-4.914-7.88-3.506a2.687,2.687,0,0,0-4.105,3.409Z" transform="translate(-523.125 -369.737)" fill="#ffb7b7"/>
  <path id="Path_525" data-name="Path 525" d="M663.264,486.3l3.28-4.527,15.8,7.47,19.664-3.69a6.737,6.737,0,0,1,7.979,6.609h0a6.744,6.744,0,0,1-6.725,6.75l-22.534.042Z" transform="translate(-537.407 -372.656)" fill="#5348be"/>
  <path id="Path_526" data-name="Path 526" d="M377.741,577.592,374.422,576l4.559-13.872,4.9,2.344Z" transform="translate(-256.936 -385.748)" fill="#ffb7b7"/>
  <path id="Path_527" data-name="Path 527" d="M699.486,709.666c-.242.151-.483.3-.727.449-.485.3-.975.59-1.465.878-.246.143-.5.287-.742.429v.007l-.014,0,0,0-.375.093-.539-1.424-.249-.656-.351-.928-.643-1.7-3.388-8.946,5.268-1.3.2.806h0l.1.4,1.841,7.464.375,1.526.012.046.227.911.195.791.218.882Z" transform="translate(-556.432 -520.059)" fill="#ffb7b7"/>
  <path id="Path_528" data-name="Path 528" d="M722.339,603.181c-.334.537-.675,1.07-1.019,1.6q-.958,1.477-1.967,2.911s0,0-.005.005a6.918,6.918,0,0,1-1.292.943c-.019.014-.042.024-.061.036a4.733,4.733,0,0,1-1.088.437l-.412.1c-.492.117-.98.217-1.465.3q-1.293.231-2.564.331c-.068.008-.136.012-.2.018a39.658,39.658,0,0,1-10.428-.832c-1.751-.331-3.537-.729-5.388-1.141-.6-.136-1.211-.271-1.826-.4-.046-.01-.1-.022-.144-.031-.878-.193-1.77-.383-2.684-.571-1.15-.237-2.337-.465-3.571-.683l.087,1.738a13.067,13.067,0,0,0,.824,3.908,6.742,6.742,0,0,1,.063,5.3l-.424,1.229a4.2,4.2,0,0,1-.431,3.712l-.086.13,3.537,8.949.229.58H690.21l-2.228,0h-3.776l-5.448-10.931-.26-.522v-.027l.563-21.005c.007-.185.022-.373.047-.558a6.383,6.383,0,0,1,2.43-4.3,6.152,6.152,0,0,1,4.581-1.254l1.864.242,3.81.492,2.684.348.053.007,7.915,1.024.568-2.316.1-.039,3.333-1.221,11.525-4.224.029-.01.758-.278.09.146c.027.044.053.088.078.132a22.621,22.621,0,0,1,1.821,4.227,66.489,66.489,0,0,1,1.913,7.306c.022.107.042.2.058.292l.008.027c.007.041.012.069.014.075A6.813,6.813,0,0,1,722.339,603.181Z" transform="translate(-547.861 -444.991)" fill="#2f2e41"/>
  <path id="Path_529" data-name="Path 529" d="M699.9,588.245c-.036-.156-.083-.314-.131-.468a33.515,33.515,0,0,0-1.351-3.417c-.575-1.268-1.265-2.625-2.086-4.1q-.6-1.067-1.283-2.221-.344-.578-.714-1.177a.427.427,0,0,0-.032-.054l-.07-.117-11.4,1.526-4.246.568-.254,2.116-.061.524s0,0,0,0l-.171,1.428v0l-.227,1.886-.019,0-6.3-.818-6.494-.84-3.489-.452a6.147,6.147,0,0,0-4.58,1.253,6.389,6.389,0,0,0-2.431,4.3c-.022.171-.036.346-.044.52l-3.291,7.8a50.336,50.336,0,0,0-3.72,14.635l-2.413,7.716.344.032,5.114.475,1.351.127,2.974-4.341c.339-.495.661-1,.961-1.526a23.328,23.328,0,0,0,1.313-2.643l6.432-15.309q.747.128,1.478.244,3.382.548,6.494.895a76.585,76.585,0,0,0,17.786.173q2.19-.267,4.234-.7.75-.158,1.48-.339c.115-.026.227-.054.339-.083a6.22,6.22,0,0,0,4.351-4.066A6.34,6.34,0,0,0,699.9,588.245Z" transform="translate(-524.935 -437.796)" fill="#2f2e41"/>
  <path id="Path_530" data-name="Path 530" d="M623.2,733.851l-3.591-1.721-.563-.271-.9,2.208-6.279,1.382a1.49,1.49,0,0,0-.244,2.835l6.136,2.5,1.948-1.752-.478,2.352,2.311.943,2.532-8.062Z" transform="translate(-501.333 -544.273)" fill="#2f2e41"/>
  <path id="Path_531" data-name="Path 531" d="M458.7,593.34l.229.581H458.7Z" transform="translate(-314.771 -407.163)" fill="#2f2e41"/>
  <path id="Path_532" data-name="Path 532" d="M701.209,728.919l-3.763,1.3-.59.2.914,2.2-3.49,5.4a1.49,1.49,0,0,0,1.822,2.186l6.122-2.538.151-2.616,1.315,2.008,2.306-.955-3.872-7.51Z" transform="translate(-558.528 -542.039)" fill="#2f2e41"/>
  <path id="Path_533" data-name="Path 533" d="M770.508,525a1.387,1.387,0,0,1-.641-.159l-19.854-10.375-.229-.939-.432-2.972.006-.028,1.888-8.705c-.034-.075-.107-.2-.188-.336-.843-1.428-3.408-5.775,1.488-11.761l8.6-10.022,7.378-.318.084.106a19.216,19.216,0,0,1,3.267,17.85l-.768,13.874.824,2.8a7.731,7.731,0,0,1-.227,5l.189,4.537A1.385,1.385,0,0,1,770.508,525Z" transform="translate(-596.483 -371.024)" fill="#5448be"/>
  <path id="Path_534" data-name="Path 534" d="M752.995,634.43a2.672,2.672,0,0,0,.668-4.043l4.773-8.209-4.927.278-3.777,7.753a2.687,2.687,0,0,0,3.264,4.221Z" transform="translate(-596.191 -469.007)" fill="#ffb7b7"/>
  <path id="Path_535" data-name="Path 535" d="M758.385,540.3l-4.411-3.436,8.015-15.532-3-19.78a6.737,6.737,0,0,1,6.882-7.745h0a6.745,6.745,0,0,1,6.512,6.955l-.742,22.522Z" transform="translate(-599.655 -380.917)" fill="#5348be"/>
  <path id="a1ca5f83-7fa1-45b1-9d3b-bf096f25c0e5" d="M771.842,429.757v-7.3a9.783,9.783,0,0,1,19.566-.013v.013c1.724,3.593.646,5.3,0,7.3a1.316,1.316,0,0,1-1.313,1.313H773.156A1.316,1.316,0,0,1,771.842,429.757Z" transform="translate(-611.915 -325.236)" fill="#2f2e41"/>
  <circle id="Ellipse_27" data-name="Ellipse 27" cx="7.323" cy="7.323" r="7.323" transform="translate(159.179 90.714)" fill="#ffb7b7"/>
  <path id="Path_536" data-name="Path 536" d="M780.865,428.42c-.066-.01-.132-.024-.2-.035-1.142-.2-2.284-.4-3.424-.608l-.1-2.581-1.38,2.312a11.577,11.577,0,0,1-8.862-.848,25.094,25.094,0,0,1-2.655-1.464,9.7,9.7,0,0,1,4.768-6.867c.1-.06.206-.112.311-.164h0a0,0,0,0,0,0,0c.178-.084.36-.164.542-.238a8.067,8.067,0,0,1,8.467,1.642A9.636,9.636,0,0,1,780.865,428.42Z" transform="translate(-606.7 -328.464)" fill="#2f2e41"/>
</svg>
`
const REQUEST_PENDING = `<svg xmlns="http://www.w3.org/2000/svg" width="170.927" height="153.658" viewBox="0 0 170.927 153.658">
  <g id="Group_866" data-name="Group 866" transform="translate(-645 -385.671)">
    <g id="Group_865" data-name="Group 865" transform="translate(644.999 385.671)">
      <path id="Path_560" data-name="Path 560" d="M521.491,470.339l-37.26.655a4.473,4.473,0,1,1-.157-8.945l37.26-.655a2.1,2.1,0,0,1,2.131,2.058l.084,4.756a2.1,2.1,0,0,1-1.918,2.124Q521.561,470.337,521.491,470.339Z" transform="translate(-434.059 -380.792)" fill="aqua"/>
      <path id="Path_561" data-name="Path 561" d="M517.393,469.305l-30.568,1.4a2.1,2.1,0,0,1-2.188-2l-.323-7.087a2.1,2.1,0,0,1,2-2.188l30.568-1.4a2.1,2.1,0,0,1,2.188,2l.323,7.087a2.1,2.1,0,0,1-1.918,2.183Z" transform="translate(-437.156 -378.543)" fill="#2f2e41"/>
      <path id="Path_562" data-name="Path 562" d="M428.108,591.38V551.736a2.1,2.1,0,0,1,2.095-2.095h4.757a2.1,2.1,0,0,1,2.095,2.095V591.38a2.1,2.1,0,0,1-2.095,2.095H430.2A2.1,2.1,0,0,1,428.108,591.38Z" transform="translate(-399.565 -439.817)" fill="aqua"/>
      <path id="Path_563" data-name="Path 563" d="M465.8,591.38V551.736a2.1,2.1,0,0,1,2.095-2.095h4.757a2.1,2.1,0,0,1,2.095,2.095V591.38a2.1,2.1,0,0,1-2.095,2.095H467.9a2.1,2.1,0,0,1-2.095-2.095Z" transform="translate(-424.777 -439.817)" fill="aqua"/>
      <circle id="Ellipse_32" data-name="Ellipse 32" cx="16.436" cy="16.436" r="16.436" transform="translate(25.832 35.581)" fill="aqua"/>
      <path id="Path_564" data-name="Path 564" d="M492.469,370.38a3.9,3.9,0,0,0,1.423-.836,2.621,2.621,0,0,0,.766-2.075,1.763,1.763,0,0,0-.784-1.357,2.28,2.28,0,0,0-2.174-.025l.03-6.416-.645,0-.036,7.543.5-.312a1.952,1.952,0,0,1,1.979-.243,1.132,1.132,0,0,1,.49.876,1.981,1.981,0,0,1-.57,1.547,5.328,5.328,0,0,1-2.934,1.082l.1.636A10.621,10.621,0,0,0,492.469,370.38Z" transform="translate(-441.305 -312.752)" fill="#2f2e41"/>
      <rect id="Rectangle_331" data-name="Rectangle 331" width="3.471" height="0.644" transform="translate(53.628 47.048) rotate(-7.523)" fill="#2f2e41"/>
      <rect id="Rectangle_332" data-name="Rectangle 332" width="3.471" height="0.644" transform="translate(42.766 48.482) rotate(-7.523)" fill="#2f2e41"/>
      <path id="Path_565" data-name="Path 565" d="M349.312,369.6l23.6,28.842a4.473,4.473,0,0,1-6.924,5.665l-23.6-28.842a2.1,2.1,0,0,1,.295-2.948l3.681-3.012A2.1,2.1,0,0,1,349.312,369.6Z" transform="translate(-341.914 -318.881)" fill="aqua"/>
      <path id="Path_566" data-name="Path 566" d="M367.816,389.039l19.377,23.683a2.1,2.1,0,0,1-.295,2.948l-6.288,5.145a2.1,2.1,0,0,1-2.948-.295l-19.377-23.683a2.1,2.1,0,0,1,.295-2.948l6.288-5.145a2.1,2.1,0,0,1,2.948.295Z" transform="translate(-352.548 -331.883)" fill="#2f2e41"/>
      <path id="Path_336" data-name="Path 336" d="M666.62,299.787a3.122,3.122,0,0,0-2.855-1.91H606.784a3.09,3.09,0,0,0-3.089,3.089v72.086a3.09,3.09,0,0,0,3.089,3.089h56.983a3.093,3.093,0,0,0,3.089-3.089V300.966a3.034,3.034,0,0,0-.234-1.179Zm-.452,73.265a2.406,2.406,0,0,1-2.4,2.4H606.784a2.4,2.4,0,0,1-2.4-2.4V300.966a2.406,2.406,0,0,1,2.4-2.4h56.983a2.415,2.415,0,0,1,2.23,1.512,2.66,2.66,0,0,1,.09.271,2.381,2.381,0,0,1,.082.62Z" transform="translate(-517.007 -271.423)" fill="#3f3d56"/>
      <path id="Path_337" data-name="Path 337" d="M720.366,343.725H703.2a1.373,1.373,0,0,1,0-2.746h17.164a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-582.645 -300.252)" fill="#3f3d56"/>
      <path id="Path_338" data-name="Path 338" d="M720.366,365.494H703.2a1.373,1.373,0,0,1,0-2.746h17.164a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-582.645 -314.812)" fill="#3f3d56"/>
      <path id="Path_339" data-name="Path 339" d="M650.928,347.3H638.385a1.546,1.546,0,0,1-1.545-1.545v-14.8a1.546,1.546,0,0,1,1.545-1.545h12.543a1.546,1.546,0,0,1,1.545,1.545v14.8A1.546,1.546,0,0,1,650.928,347.3Z" transform="translate(-539.176 -292.513)" fill="#fa9600"/>
      <path id="Path_340" data-name="Path 340" d="M676.326,410.069H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-538.724 -344.626)" fill="#ccc"/>
      <path id="Path_341" data-name="Path 341" d="M676.326,431.838H637.537a1.373,1.373,0,1,1,0-2.746h38.789a1.373,1.373,0,1,1,0,2.746Z" transform="translate(-538.724 -359.187)" fill="#ccc"/>
      <path id="Path_342" data-name="Path 342" d="M676.326,453.606H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,1,1,0,2.746Z" transform="translate(-538.724 -373.746)" fill="#ccc"/>
      <path id="Path_343" data-name="Path 343" d="M676.326,475.375H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-538.724 -388.307)" fill="#ccc"/>
      <path id="Path_344" data-name="Path 344" d="M676.326,497.144H637.537a1.373,1.373,0,0,1,0-2.746h38.789a1.373,1.373,0,0,1,0,2.746Z" transform="translate(-538.724 -402.867)" fill="#ccc"/>
      <circle id="Ellipse_44" data-name="Ellipse 44" cx="19.657" cy="19.657" r="19.657" transform="translate(131.613 0)" fill="#fa9600"/>
      <path id="Path_568" data-name="Path 568" d="M404.568,299.157c2.943-1.935,6.641-1.064,9.912-.811,2.955.228,6.458,0,8.537-2.411a7.418,7.418,0,0,0,.191-8.9,11.265,11.265,0,0,0-7.782-4.7,12.485,12.485,0,0,0-9.7,2.679l1.052.436a14.66,14.66,0,0,0-10.7-12.594,14.282,14.282,0,0,0-15.509,6.242c-3.076,5.141-2.712,12.314,1.716,16.586a9.019,9.019,0,0,0,7.954,2.539,6.853,6.853,0,0,0,5.619-5.275c.47-2.583-.556-5.518-3.069-6.646a5.01,5.01,0,0,0-4.379.07,4.257,4.257,0,0,0-2.159,3.405,3.763,3.763,0,0,0,.4,1.966c.349.712,1.412.088,1.064-.622a2.857,2.857,0,0,1,.59-3.136,3.468,3.468,0,0,1,3.569-.723c2.221.714,3.2,3.262,2.783,5.423a5.709,5.709,0,0,1-4.889,4.375,7.825,7.825,0,0,1-6.831-2.465c-3.721-3.806-4.043-9.937-1.533-14.48a12.965,12.965,0,0,1,13.15-6.31,13.321,13.321,0,0,1,10.641,9.749,12.487,12.487,0,0,1,.351,1.895.626.626,0,0,0,1.052.436c4.371-3.63,11.641-3.241,15.225,1.326,1.758,2.24,2.276,5.718.223,7.967-1.954,2.142-5.2,2.144-7.869,1.91-3.415-.3-7.169-1.006-10.227,1.005-.66.434-.043,1.5.622,1.064Z" transform="translate(-366.42 -254.412)" fill="#2f2e41"/>
      <path id="Path_569" data-name="Path 569" d="M443.621,573.655H418.977a1.851,1.851,0,0,1-1.848-1.848v-8.215a1.85,1.85,0,0,1,1.848-1.848h24.645a1.85,1.85,0,0,1,1.848,1.848v8.215A1.85,1.85,0,0,1,443.621,573.655Z" transform="translate(-392.221 -447.911)" fill="#2f2e41"/>
      <path id="Path_570" data-name="Path 570" d="M439.436,449.775a11.22,11.22,0,0,0-7.658-8.356c1.4-.452,2.3-1.121,2.3-1.87,0-1.361-2.942-2.465-6.572-2.465s-6.572,1.1-6.572,2.465c0,.759.917,1.438,2.358,1.89C417.327,443.218,413,448.407,413,454.519c0,0-1.836,11.767-3.277,21.174-.922,6.018,4.116,11.4,10.672,11.4h23.059a2.648,2.648,0,0,0,2.739-2.54S441.943,462.594,439.436,449.775Z" transform="translate(-387.194 -364.533)" fill="#2f2e41"/>
    </g>
    <path id="hourglass_top_24dp_FILL0_wght400_GRAD0_opsz24" d="M164.815-858.332h9.63v-3.611a4.636,4.636,0,0,0-1.414-3.4,4.637,4.637,0,0,0-3.4-1.414,4.637,4.637,0,0,0-3.4,1.414,4.637,4.637,0,0,0-1.414,3.4ZM160-855.925v-2.407h2.408v-3.611a7.22,7.22,0,0,1,.858-3.446,6.708,6.708,0,0,1,2.392-2.573,6.708,6.708,0,0,1-2.392-2.573,7.22,7.22,0,0,1-.858-3.446v-3.611H160V-880h19.26v2.408h-2.408v3.611a7.22,7.22,0,0,1-.858,3.446,6.708,6.708,0,0,1-2.392,2.573,6.708,6.708,0,0,1,2.392,2.573,7.22,7.22,0,0,1,.858,3.446v3.611h2.408v2.407Z" transform="translate(626 1273.118)" fill="#fff"/>
    <path id="Polygon_1" data-name="Polygon 1" d="M2.5,0,5,5H0Z" transform="translate(798.059 411) rotate(180)" fill="#fff"/>
  </g>
</svg>`
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent!: ElementRef<any>;
  @ViewChild('widgetsContentTools', { read: ElementRef })
  public widgetsContentTools!: ElementRef<any>;
  breadCrumbItems: any;
  instanceId: any;
  isViewICIToolLogActions: boolean = false;
  isViewICIToolWriteActions:boolean = false;
  ViewData: any;
  instanceIciVersion: any;
  compareVersion: any = '8.4.0.0';
  compareIciversion: number = 0;
  public instanceEnum = InstanceManagement;
  selectedValue: string = '';
  carousel: any;
  rightArrow: any;
  leftArrow: any;
  debuggingActions: any = [];
  instanceToolsActions: any = [];
  timer: any;
  seconds: number = 0;
  minutes: number = 60;
  hours: number = 2;
  ApprovedDuration: any = 0;
  RequestApprovedTime: any = new Date();
  timeLeft: any;
  isTimeUp: boolean = false;
  requestDebuggingUtilitiesAccess: boolean = true;
  duration: any = [];
  selectedHours: any;
  comments: any = '';
  isRequestPending: boolean = false;
  isCloudOpsUser: boolean = false;
  isViewICIToolLogActionsProd: boolean = false;
  isViewICIToolWriteActionsProd: boolean = false;
  hasApprovalAccessToDebuggingUtilitiesProd: boolean = false;
  requestStatus: any = null;
  currentUTCTime: number = 0;
  targetUTCTime: number = 0;
  countdown: string = '';
  highlightTimer: boolean = false;
  currentUser:any;
  requestedByUserId:any;
  approvalStatus: any ={
    Pending: 1,
    Approved: 2,
    Rejected: 3,
  }
  featureCategoryId:any;
  debuggingUtilitiesRequestDetails: any;
  hangfireDashBoardRequestDetails:any;
  hangfireDashboardData: any;
  constructor(
    private instanceService: InstanceService,
    private permissionService: PermissionsService,
    private router: Router,
    private datePipe: DatePipe,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private notificationService:NotificationService,
  ) {
    iconRegistry.addSvgIconLiteral(
      'time_up',
      sanitizer.bypassSecurityTrustHtml(TIME_UP)
    );
    iconRegistry.addSvgIconLiteral(
      'request-pending',
      sanitizer.bypassSecurityTrustHtml(REQUEST_PENDING)
    );
  }

  ngOnInit(): void {
    this.duration = ['2 Hours', '4 Hours', '8 Hours'];
    this.breadCrumbItems = [
      { label: 'Home', path: 'resources' },
      {
        label: 'Instance Operations',
        path: 'instanceoperations/instance-requests',
      },
      {
        label: 'Instance Requests',
        path: 'instanceoperations/instance-requests',
      },
      { label: 'Instance Tools', path: 'instance-tools-landing' },
    ];
    this.instanceId = localStorage.getItem('instanceID');
    this.getInstanceByID();
    this.isViewICIToolLogActions =
      this.permissionService.getPermission('LoggingUtilities');
    this.isViewICIToolLogActionsProd = this.permissionService.getPermission(
      'LoggingUtilities-Prod'
    );
    this.isViewICIToolWriteActions =
      this.permissionService.getPermission('WriteUtilities');
    this.isViewICIToolWriteActionsProd = this.permissionService.getPermission(
      'WriteUtilities-Prod'
    );
    this.hasApprovalAccessToDebuggingUtilitiesProd =
      this.permissionService.getPermission(
        'ApprovalAccessToDebuggingUtilities-Prod'
      );
      this.currentUser = this.permissionService.getUniqueUserid();
    this.setTimer();
  }

  ngAfterViewInit() {
    if (this.widgetsContent) {
      if ((this.ViewData?.instanceType != this.instanceEnum.instance_constant_production && this.isViewICIToolWriteActions) ||  (this.ViewData?.instanceType == this.instanceEnum.instance_constant_production && (this.hasApprovalAccessToDebuggingUtilitiesProd || (this.isViewICIToolLogActionsProd && this.requestStatus == this.approvalStatus.Approved && !this.isTimeUp && !this.requestDebuggingUtilitiesAccess)))) {
        document.getElementById('left-arrow')!.style.visibility = 'hidden';
        if ((this.ViewData?.instanceType != this.instanceEnum.instance_constant_production && this.isViewICIToolWriteActions) ||  (this.ViewData?.instanceType == this.instanceEnum.instance_constant_production && (this.hasApprovalAccessToDebuggingUtilitiesProd  || this.isViewICIToolWriteActionsProd) && !this.isTimeUp && !this.requestDebuggingUtilitiesAccess && this.requestStatus == this.approvalStatus.approved)) {
          document.getElementById('left-arrow-tools')!.style.visibility =
            'hidden';
        }
      }
    }
  }

  getInstanceToolsFeaturesList() {
    this.instanceService
      .getInstanceToolsFeaturesList()
      .subscribe((res: any) => {
        if (res) {
          this.debuggingActions = res?.body?.content?.filter((x: any) => {
            if (this.instanceIciVersion < this.compareIciversion) {
              return x.categoryId == 1 || x.categoryId == 3;
            } else {
              return (x.categoryId == 1 || x.categoryId == 3) && x.id != 4 ;
            }
          });
          this.debuggingActions = this.debuggingActions?.sort(
            (a: any, b: any) => {
              return a.name.localeCompare(b.name);
            }
          );
          this.instanceToolsActions = res?.body?.content?.filter((x: any) => {
            return x.categoryId == 2;
          });
          this.instanceToolsActions = this.instanceToolsActions?.sort(
            (a: any, b: any) => {
              return a.name.localeCompare(b.name);
            }
          );
          this.getLastRunDetails();
        }
      });
  }

  setTimer() {
    if (this.debuggingUtilitiesRequestDetails[0].approvedAt != 0) {
      this.timer = setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
        } else {
          this.seconds = 59;
          if (this.minutes > 0) {
            this.minutes -= 1;
          } else {
            this.minutes = 59;
            if (this.hours > 0) {
              this.hours -= 1;
            } else {
              clearInterval(this.timer);
              this.timer = null;
              this.isTimeUp = true;
            }
          }
        } 
       const formattedHours = String(this.hours).padStart(2, '0');
       const formattedMinutes = String(this.minutes).padStart(2, '0');
       const formattedSeconds = String(this.seconds).padStart(2, '0');

       this.countdown = `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
        if(this.seconds >= 0 && this.minutes < 1 && this.hours <= 0){
          this.highlightTimer = true;
        }
        else{
          this.highlightTimer = false;
        }
      }, 1000);
      
    }
  }

  calculateTimeLeft() {
    let expiryDate = new Date(this.RequestApprovedTime);
    expiryDate.setHours(
      expiryDate.getHours() +
        this.debuggingUtilitiesRequestDetails[0].approvedDuration 
    );
    this.currentUTCTime = new Date(
      new Date().toISOString().substring(0, 23)
    ).getTime();
    this.targetUTCTime = expiryDate.getTime();
    if (this.targetUTCTime > this.currentUTCTime) {
      const differenceInMilliseconds = this.targetUTCTime - this.currentUTCTime;
      this.seconds = Math.floor((differenceInMilliseconds / 1000) % 60);
      this.minutes = Math.floor((differenceInMilliseconds / (1000 * 60)) % 60);
      this.hours = Math.floor(
        (differenceInMilliseconds / (1000 * 60 * 60)) % 24
      );
      if (this.seconds >= 0 && this.minutes <= 1) {
        this.highlightTimer = true;
      }
    } else {
      this.isTimeUp = true;
    }
  }

  ngAfterViewChecked() {
    if (
      (this.isViewICIToolLogActions && this.ViewData?.instanceType !=
      this.instanceEnum.instance_constant_production) || ( this.ViewData?.instanceType ==
        this.instanceEnum.instance_constant_production && (this.hasApprovalAccessToDebuggingUtilitiesProd || (this.isViewICIToolLogActionsProd &&
      !this.isTimeUp && !this.requestDebuggingUtilitiesAccess && !this.isRequestPending && !(this.requestStatus == this.approvalStatus.Rejected)))) 
    ) {
      this.initialCarouselstateForUtilities();
    }
    if (
      (this.isViewICIToolWriteActions &&
      this.ViewData?.instanceType !=
        this.instanceEnum.instance_constant_production) ||
      (this.ViewData?.instanceType ==
        this.instanceEnum.instance_constant_production && (this.hasApprovalAccessToDebuggingUtilitiesProd || (this.isViewICIToolWriteActionsProd && !this.isTimeUp && !this.requestDebuggingUtilitiesAccess && !this.isRequestPending && !(this.requestStatus == this.approvalStatus.Rejected))))) {
      this.initialCarouselstateForTools();
    }
    if (
      ((this.isViewICIToolLogActions && this.isViewICIToolWriteActions) && this.ViewData?.instanceType !=
      this.instanceEnum.instance_constant_production) || ( this.ViewData?.instanceType ==
        this.instanceEnum.instance_constant_production && (this.hasApprovalAccessToDebuggingUtilitiesProd || (this.isViewICIToolLogActionsProd && this.isViewICIToolWriteActionsProd &&
          !this.isTimeUp && !this.requestDebuggingUtilitiesAccess && !this.isRequestPending && !(this.requestStatus == this.approvalStatus.Rejected))))
    ) {
      this.initialCarouselstateForUtilities();
      this.initialCarouselstateForTools();
     
    }
  }


  public async scrollRight(item: any) {
    let rightArrow = '';
    let leftArrow = '';
    let carousel!: ElementRef<any>;
    if (item == 'utilities') {
      carousel = this.widgetsContent;
      rightArrow = 'right-arrow';
      leftArrow = 'left-arrow';
    } else if (item == 'tools') {
      carousel = this.widgetsContentTools;
      rightArrow = 'right-arrow-tools';
      leftArrow = 'left-arrow-tools';
    }
    await carousel?.nativeElement.scrollTo({
      left: carousel?.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
    setTimeout(() => {
      const scrollLeft = carousel?.nativeElement.scrollLeft;
      const containerWidth = carousel?.nativeElement.scrollWidth;
      const viewportWidth = carousel?.nativeElement.clientWidth;
      const maxScrollPosition = containerWidth - viewportWidth;
      const isLastCardVisible =
        Math.round(scrollLeft) >= Math.round(maxScrollPosition);
      if (isLastCardVisible) {
        document.getElementById(rightArrow)!.style.visibility = 'hidden';
      }
    }, 500);
    document.getElementById(leftArrow)!.style.visibility = 'visible';
  }
  getLastRunDetails() {
    this.instanceService
      .ICIToolRequestHistory(this.instanceId)
      .subscribe((resp: any) => {
        let result = resp.body;
        this.debuggingActions.forEach((action: any) => {
          let actionId = action.id;
          let res = result.map((id: any) => {
            if (id.requestTypeId == actionId) {
              action.lastRun = id.lastRun;
              action.status = id.status;
            }
          });
        });

        this.instanceToolsActions.forEach((action: any) => {
          let actionId = action.id;
          let res = result.map((id: any) => {
            if (id.requestTypeId == actionId) {
              action.lastRun = id.lastRun;
              action.status = id.status;
            }
          });
        });
      });
  }

  initialCarouselstateForUtilities() {
    this.carousel = this.widgetsContent;
    let rightArrow = 'right-arrow';
    const scrollLeft = this.carousel?.nativeElement.scrollLeft;
    const containerWidth = this.carousel?.nativeElement.scrollWidth;
    const viewportWidth = this.carousel?.nativeElement.clientWidth;
    const maxScrollPosition = containerWidth - viewportWidth;
    const isLastCardVisible =
      Math.round(scrollLeft) >= Math.round(maxScrollPosition);
    if (isLastCardVisible) {
      document.getElementById(rightArrow)!.style.visibility = 'hidden';
    } else {
      document.getElementById(rightArrow)!.style.visibility = 'visible';
    }
  }

  initialCarouselstateForTools() {
      this.carousel = this.widgetsContentTools;
      let rightArrow = 'right-arrow-tools';
      const scrollLeft = this.carousel?.nativeElement.scrollLeft;
      const containerWidth = this.carousel?.nativeElement.scrollWidth;
      const viewportWidth = this.carousel?.nativeElement.clientWidth;
      const maxScrollPosition = containerWidth - viewportWidth;
      const isLastCardVisible =
        Math.round(scrollLeft) >= Math.round(maxScrollPosition);
      if (isLastCardVisible) {
        document.getElementById(rightArrow)!.style.visibility = 'hidden';
      } else {
        document.getElementById(rightArrow)!.style.visibility = 'visible';
    }
  }

  public async scrollLeft(item: any) {
    let rightArrow = '';
    let leftArrow = '';
    let carousel!: ElementRef<any>;
    if (item == 'utilities') {
      carousel = this.widgetsContent;
      rightArrow = 'right-arrow';
      leftArrow = 'left-arrow';
    } else if (item == 'tools') {
      carousel = this.widgetsContentTools;
      rightArrow = 'right-arrow-tools';
      leftArrow = 'left-arrow-tools';
    }
    await carousel?.nativeElement.scrollTo({
      left: carousel?.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
    document.getElementById(rightArrow)!.style.visibility = 'visible';
    setTimeout(() => {
      carousel?.nativeElement.scrollLeft <= 0
        ? (document.getElementById(leftArrow)!.style.visibility = 'hidden')
        : (document.getElementById(leftArrow)!.style.visibility = 'visible');
    }, 500);
  }

  getInstanceByID() {
    try {
      this.instanceService
        .getInstanceByID(this.instanceId)
        .subscribe((resp: any) => {
          let result = resp.body;
          if (result != undefined && result != null) {
            this.ViewData = result;
            this.debuggingUtilitiesRequestDetails =  this.ViewData?.featureAccessRequests
            .filter((request:any) => request.featureCategory === 'Debugging Utilities')
            .map((request:any) => ({
              featureId: request.featureId,
              requestStatus: request.status,
              requestedDuration: request.requestedDuration,
              RequestedByUserId:request.requesterUserId,
              approvedAt: request.approvedAt,
              featureCategoryId: request.featureCategoryId,
              statusId:request.statusId,
              approverComment: request.approverComment,
              approvedDuration:request.approvedDuration,
              requestedAt:request.requestedAt
            }));
            if(this.debuggingUtilitiesRequestDetails.length > 0 && this.ViewData?.instanceType == this.instanceEnum.instance_constant_production){              
              this.requestedByUserId = this.debuggingUtilitiesRequestDetails[0]?.requesterUserId;
              this.requestStatus = this.debuggingUtilitiesRequestDetails[0]?.statusId;
              this.featureCategoryId= this.debuggingUtilitiesRequestDetails[0]?.featureCategoryId;
              if(this.requestStatus == this.approvalStatus.Approved || this.requestStatus == this.approvalStatus.Rejected){
                this.requestDebuggingUtilitiesAccess = false;
              }
              this.RequestApprovedTime = this.debuggingUtilitiesRequestDetails[0].approvedAt;
              if (this.RequestApprovedTime) {
                this.calculateTimeLeft();
              }
            } 
            const isHangfireDashboardPresent = this.ViewData?.featureAccessRequests.some(
              (request:any) => request.featureCategory === "Hangfire Dashboard"
          );
          if(isHangfireDashboardPresent){
            this.hangfireDashboardData = true;
          }
          else{
            this.hangfireDashboardData = false;
          }
            this.hangfireDashBoardRequestDetails =   this.ViewData?.featureAccessRequests
            .filter((request:any) => request.featureCategory === 'Hangfire Dashboard')
            .map((request:any) => ({
              featureId: request.featureId,
              requestStatus: request.status,
              requestedDuration: request.requestedDuration,
              RequestedByUserId:request.requesterUserId,
              RequestApprovedTime: request.approvedAt,
              featureCategoryId: request.featureCategoryId,
              statusId:request.statusId,
              approverComment: request.approverComment,
              approvedDuration:request.approvedDuration
            }));
            this.instanceIciVersion = this.ViewData.iciVersion?.localeCompare(
              this.compareVersion,
              undefined,
              { numeric: true, sensitivity: 'base' }
            );
          }
          this.getInstanceToolsFeaturesList();
        });
    } catch (error) {
      console.log(error);
    }
  }

  navigateHistoryPage(tool: any) {
    let json = {
      path: 'landingPage',
      id: tool.id,
      name: tool.name,
    };
    localStorage.setItem('tools-history', tool.id);
    this.instanceService.sendData(JSON.stringify(json));
    this.router.navigate([
      '/home/instanceoperations/instance-requests/history',
    ]);
  }

  navigateToolsPage(tool: any) {
    let json = {
      path: 'landingPage',
      id: tool.id,
      name: tool.name,
    };
    this.instanceService.sendData(JSON.stringify(json));
    this.router.navigate([
      '/home/instanceoperations/instance-requests/instance-tools',
    ]);
  }

  Oncancel() {
    this.router.navigate(['/home/instanceoperations/instance-requests']);
  }
  validateRequest() {
    if (
      this.selectedHours != null &&
      this.selectedHours != '' &&
      this.selectedHours != undefined &&
      this.comments?.replace(/\s/g, '').trim().length != 0
    ) {
      return false;
    } else {
      return true;
    }
  }

  requestAccess(event: any) {
    this.requestDebuggingUtilitiesAccess = true;
    this.isTimeUp = false;
  }

  ConfirmAccessRequest(event: any) {
    let param = {
      featureCategoryId: 1,
      instanceId: Number(this.instanceId),
      statusId: 1,
      requesterUserId: this.currentUser,
      requesterComment: this.comments,
      requestedDuration:
        this.selectedHours == '2 Hours'
          ? 2
          : this.selectedHours == '4 Hours'
          ? 4
          : 8,
      approverUserId: null,
      approverComment: null,
      approvedDuration: null,
    };
    this.instanceService.FeatureAccessRequest(param).subscribe((res: any) => {
      if (res?.body.isSuccessful) {
        this.isRequestPending = true;
        this.isTimeUp = false;
        this.requestDebuggingUtilitiesAccess = false;
        this.getInstanceByID();
      }else{
        this.notificationService.showNotification('Failed to request access for debugging utilities.','error','Request Failed');
      }
    });
  }
}

